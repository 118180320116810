import React from "react";

export const Question3Img = (props) =>{
    return <svg {...props} viewBox="0 0 375 358" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M320.245 72.2107C320.245 72.2107 284.683 68.2734 262.914 29.2314C241.145 -9.81061 124.222 3.01714 124.222 3.01714C124.222 3.01714 124.908 16.6831 142.994 20.2139L130.293 26.3103C130.293 26.3103 134.002 41.9576 167.125 40.5605L158.286 50.3654C158.286 50.3654 172.206 61.7199 207.107 56.7412L200.528 65.1999C200.528 65.1999 216.836 76.5543 244.524 68.5783L241.501 76.3257C241.501 76.3257 262.483 77.8498 274.853 72.4393C274.904 72.4393 298.832 87.1976 320.245 72.2107Z" fill="white"/>
        <path opacity="0.4" d="M320.246 72.2362C298.756 87.1976 274.828 72.4648 274.828 72.4648C262.457 77.8245 241.476 76.3512 241.476 76.3512L244.422 68.4768C216.76 76.4782 200.452 65.0984 200.452 65.0984L207.031 56.6397C172.13 61.72 158.21 50.2385 158.21 50.2385L167.202 40.5859C134.18 41.9322 130.395 26.3103 130.395 26.3103L143.096 20.2394C125.01 16.6832 124.324 3.01721 124.324 3.01721C124.324 3.01721 130.954 14.8289 150.056 14.4479C150.056 14.4479 146.855 22.4239 166.414 23.8972C166.414 23.8972 182.951 30.9334 194.94 25.6753C194.94 25.6753 198.954 33.8546 213.331 32.4575L208.479 38.884C208.479 38.884 209.978 47.3173 227.784 43.1261L225.244 51.407C225.244 51.407 231.087 58.1891 253.999 53.1343C253.999 53.1343 246.835 57.8081 249.198 60.1959C251.56 62.5836 258.774 63.7267 265.023 63.1171C264.921 63.0662 289.383 82.3967 320.246 72.2362Z" fill="#CACACA"/>
        <path d="M320.245 72.2107C320.245 72.2107 284.683 68.2734 262.914 29.2314C241.145 -9.81061 124.222 3.01714 124.222 3.01714C124.222 3.01714 124.908 16.6831 142.994 20.2139L130.293 26.3103C130.293 26.3103 134.002 41.9576 167.125 40.5605L158.286 50.3654C158.286 50.3654 172.206 61.7199 207.107 56.7412L200.528 65.1999C200.528 65.1999 216.836 76.5543 244.524 68.5783L241.501 76.3257C241.501 76.3257 262.483 77.8498 274.853 72.4393C274.904 72.4393 298.832 87.1976 320.245 72.2107Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M168.853 10.4344C157.092 13.4064 149.979 12.4665 141.241 16.9118C141.241 16.9118 148.862 27.3264 168.929 25.9293" fill="white"/>
        <path d="M168.853 10.4344C157.092 13.4064 149.979 12.4665 141.241 16.9118C141.241 16.9118 148.862 27.3264 168.929 25.9293" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M177.794 30.0442C172.733 31.1218 167.784 32.6696 163.01 34.6673C163.01 34.6673 165.906 48.2063 197.683 41.7289" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M210.054 46.1488L200.858 52.1435C211.653 56.5566 223.566 57.4456 234.896 54.6837" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.041 54.9124C244.041 54.9124 234.947 61.3135 235.252 62.2026C235.557 63.0916 253.465 69.823 269.671 61.0087" fill="white"/>
        <path d="M244.041 54.9124C244.041 54.9124 234.947 61.3135 235.252 62.2026C235.557 63.0916 253.465 69.823 269.671 61.0087" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M188.158 14.27C182.595 16.8102 166.11 19.1979 165.017 21.611C165.017 21.611 170.402 36.6995 198.039 28.19" fill="white"/>
        <path d="M188.158 14.27C182.595 16.8102 166.11 19.1979 165.017 21.611C165.017 21.611 170.402 36.6995 198.039 28.19" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M206.675 31.8732C202.472 33.56 198.529 35.8349 194.965 38.63C194.965 38.63 205.685 53.5152 231.721 40.6621" fill="white"/>
        <path d="M206.675 31.8732C202.472 33.56 198.529 35.8349 194.965 38.63C194.965 38.63 205.685 53.5152 231.721 40.6621" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M235.024 40.6367C232.73 41.6651 230.671 43.1507 228.972 45.0027C227.273 46.8546 225.97 49.0338 225.142 51.407C225.142 51.407 238.021 59.7386 260.095 51.0767" fill="white"/>
        <path d="M235.024 40.6367C232.73 41.6651 230.671 43.1507 228.972 45.0027C227.273 46.8546 225.97 49.0338 225.142 51.407C225.142 51.407 238.021 59.7386 260.095 51.0767" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M211.019 19.4265C211.019 19.4265 195.321 22.7794 194.889 25.6752C194.889 25.6752 207.59 36.5216 227.683 31.0603" fill="white"/>
        <path d="M211.019 19.4265C211.019 19.4265 195.321 22.7794 194.889 25.6752C194.889 25.6752 207.59 36.5216 227.683 31.0603" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M230.045 29.6633C227.606 30.8994 225.485 32.6813 223.847 34.8706C223.847 34.8706 229.918 43.8627 245.565 38.9602" fill="white"/>
        <path d="M230.045 29.6633C227.606 30.8994 225.485 32.6813 223.847 34.8706C223.847 34.8706 229.918 43.8627 245.565 38.9602" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M338.027 17.623L325.732 21.865L319.839 9.54535C319.55 8.92167 319.037 8.42937 318.402 8.16594C317.767 7.9025 317.056 7.88713 316.41 8.12287L274.523 22.4239C273.843 22.6749 273.262 23.1399 272.868 23.749C272.474 24.3582 272.288 25.0786 272.339 25.8023L273.431 39.7477L203.602 63.8028C201.975 64.3925 200.625 65.5677 199.817 67.0988C199.009 68.63 198.801 70.4073 199.233 72.0837L227.429 178.389C227.627 179.179 227.987 179.919 228.487 180.563C228.987 181.206 229.616 181.738 230.332 182.125C231.049 182.512 231.839 182.746 232.651 182.811C233.463 182.876 234.28 182.772 235.049 182.504L369.398 136.222C371.023 135.628 372.371 134.453 373.182 132.924C373.994 131.396 374.211 129.621 373.792 127.941L345.647 21.7635C345.449 20.973 345.088 20.2323 344.589 19.5882C344.089 18.9442 343.461 18.4107 342.745 18.0216C342.029 17.6325 341.24 17.3961 340.428 17.3275C339.616 17.2589 338.798 17.3595 338.027 17.623Z" fill="#FECC00"/>
        <path opacity="0.2" d="M338.027 17.623L325.732 21.865L319.839 9.54535C319.55 8.92167 319.037 8.42937 318.402 8.16594C317.767 7.9025 317.056 7.88713 316.41 8.12287L274.523 22.4239C273.843 22.6749 273.262 23.1399 272.868 23.749C272.474 24.3582 272.288 25.0786 272.339 25.8023L273.431 39.7477L203.602 63.8028C201.975 64.3925 200.625 65.5677 199.817 67.0988C199.009 68.63 198.801 70.4073 199.233 72.0837L227.429 178.389C227.627 179.179 227.987 179.919 228.487 180.563C228.987 181.206 229.616 181.738 230.332 182.125C231.049 182.512 231.839 182.746 232.651 182.811C233.463 182.876 234.28 182.772 235.049 182.504L369.398 136.222C371.023 135.628 372.371 134.453 373.182 132.924C373.994 131.396 374.211 129.621 373.792 127.941L345.647 21.7635C345.449 20.973 345.088 20.2323 344.589 19.5882C344.089 18.9442 343.461 18.4107 342.745 18.0216C342.029 17.6325 341.24 17.3961 340.428 17.3275C339.616 17.2589 338.798 17.3595 338.027 17.623Z" fill="black"/>
        <path d="M338.027 17.623L325.732 21.865L319.839 9.54535C319.55 8.92167 319.037 8.42937 318.402 8.16594C317.767 7.9025 317.056 7.88713 316.41 8.12287L274.523 22.4239C273.843 22.6749 273.262 23.1399 272.868 23.749C272.474 24.3582 272.288 25.0786 272.339 25.8023L273.431 39.7477L203.602 63.8028C201.975 64.3925 200.625 65.5677 199.817 67.0988C199.009 68.63 198.801 70.4073 199.233 72.0837L227.429 178.389C227.627 179.179 227.987 179.919 228.487 180.563C228.987 181.206 229.616 181.738 230.332 182.125C231.049 182.512 231.839 182.746 232.651 182.811C233.463 182.876 234.28 182.772 235.049 182.504L369.398 136.222C371.023 135.628 372.371 134.453 373.182 132.924C373.994 131.396 374.211 129.621 373.792 127.941L345.647 21.7635C345.449 20.973 345.088 20.2323 344.589 19.5882C344.089 18.9442 343.461 18.4107 342.745 18.0216C342.029 17.6325 341.24 17.3961 340.428 17.3275C339.616 17.2589 338.798 17.3595 338.027 17.623Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M368.711 136.451L234.363 182.732C232.686 183.258 230.877 183.171 229.258 182.488C227.639 181.804 226.315 180.567 225.523 178.998L224.863 177.652L193.238 112.04L177.565 79.628C177.189 78.9079 176.975 78.1145 176.937 77.3031C176.899 76.4917 177.039 75.6818 177.346 74.9298C177.653 74.1778 178.12 73.5018 178.715 72.9489C179.31 72.3959 180.019 71.9793 180.791 71.7281L315.165 25.4721C316.838 24.9337 318.649 25.0117 320.271 25.6918C321.892 26.3719 323.216 27.6094 324.005 29.1807L372.039 128.577C372.413 129.301 372.623 130.1 372.654 130.915C372.685 131.73 372.537 132.542 372.22 133.293C371.902 134.045 371.423 134.717 370.817 135.263C370.211 135.808 369.492 136.214 368.711 136.451Z" fill="#FECC00"/>
        <path opacity="0.2" d="M272.745 112.929C272.021 121.731 268.379 130.04 262.397 136.537C256.415 143.035 248.435 147.349 239.723 148.796C236.925 159.37 231.873 169.214 224.914 177.652L193.238 112.04C219.986 116.663 250.772 119.254 272.745 112.929Z" fill="black"/>
        <path d="M368.711 136.451L234.363 182.732C232.686 183.258 230.877 183.171 229.258 182.488C227.639 181.804 226.315 180.567 225.523 178.998L224.863 177.652L193.238 112.04L177.565 79.628C177.189 78.9079 176.975 78.1145 176.937 77.3031C176.899 76.4917 177.039 75.6818 177.346 74.9298C177.653 74.1778 178.12 73.5018 178.715 72.9489C179.31 72.3959 180.019 71.9793 180.791 71.7281L315.165 25.4721C316.838 24.9337 318.649 25.0117 320.271 25.6918C321.892 26.3719 323.216 27.6094 324.005 29.1807L372.039 128.577C372.413 129.301 372.623 130.1 372.654 130.915C372.685 131.73 372.537 132.542 372.22 133.293C371.902 134.045 371.423 134.717 370.817 135.263C370.211 135.808 369.492 136.214 368.711 136.451V136.451Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M272.745 112.904C272.745 112.904 237.894 120.524 204.643 90.6013C171.393 60.6784 64.9353 110.262 64.9353 110.262C64.9353 110.262 70.0156 122.963 88.203 120.423L78.2203 130.253C78.2203 130.253 86.7298 143.868 117.669 131.93L112.258 143.995C112.258 143.995 129.074 150.295 160.521 134.368L156.99 144.529C156.99 144.529 176.092 150.041 199.741 133.631L199.461 142.014C199.461 142.014 219.783 136.705 229.816 127.637C229.816 127.713 257.199 133.987 272.745 112.904Z" fill="white"/>
        <path opacity="0.4" d="M272.745 112.929C257.199 133.987 229.816 127.713 229.816 127.713C219.833 136.781 199.461 142.09 199.461 142.09L199.741 133.682C176.092 150.142 156.99 144.605 156.99 144.605L160.521 134.444C129.074 150.396 112.258 144.071 112.258 144.071L117.669 132.031C86.7297 143.944 78.2202 130.329 78.2202 130.329L88.2284 120.499C69.9394 123.039 64.9099 110.338 64.9099 110.338C64.9099 110.338 75.0705 119.381 92.8515 112.878C92.8515 112.878 92.3688 121.464 111.369 116.587C111.369 116.587 129.303 117.959 138.955 109.119C138.955 109.119 145.407 115.571 158.565 109.652L156.025 117.273C156.025 117.273 160.14 124.766 175.686 115.088L175.863 123.776C175.863 123.776 183.484 128.297 203.678 116.155C203.678 116.155 198.395 122.887 201.367 124.385C204.338 125.884 211.527 124.639 217.319 122.074C217.369 122.074 246.708 132.488 272.745 112.929Z" fill="#B0B0B0"/>
        <path d="M272.745 112.904C272.745 112.904 237.894 120.524 204.643 90.6013C171.393 60.6784 64.9353 110.262 64.9353 110.262C64.9353 110.262 70.0156 122.963 88.203 120.423L78.2203 130.253C78.2203 130.253 86.7298 143.868 117.669 131.93L112.258 143.995C112.258 143.995 129.074 150.295 160.521 134.368L156.99 144.529C156.99 144.529 176.092 150.041 199.741 133.631L199.461 142.014C199.461 142.014 219.783 136.705 229.816 127.637C229.816 127.713 257.199 133.987 272.745 112.904Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M109.413 102.997C99.2528 109.602 92.1911 110.948 85.3582 117.984C85.3582 117.984 96.0013 125.376 114.468 117.628" fill="white"/>
        <path d="M109.413 102.997C99.2528 109.602 92.1911 110.948 85.3582 117.984C85.3582 117.984 96.0013 125.376 114.468 117.628" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M124.197 118.594C119.755 121.236 115.571 124.289 111.7 127.713C111.7 127.713 118.761 139.626 146.779 123.268" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M159.962 123.598L153.078 132.26C164.721 132.933 176.278 129.91 186.1 123.623" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M194.839 121.032C194.839 121.032 188.285 129.999 188.869 130.761C189.454 131.523 208.555 132.133 221.078 118.568" fill="white"/>
        <path d="M194.839 121.032C194.839 121.032 188.285 129.999 188.869 130.761C189.454 131.523 208.555 132.133 221.078 118.568" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M129.023 100.406C124.527 104.47 109.718 112.142 109.464 114.783C109.464 114.783 119.422 127.357 142.918 110.414" fill="white"/>
        <path d="M129.023 100.406C124.527 104.47 109.718 112.142 109.464 114.783C109.464 114.783 119.422 127.357 142.918 110.414" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.164 111.151C148.729 114.101 145.727 117.522 143.248 121.312C143.248 121.312 158.184 131.98 178.81 111.456" fill="white"/>
        <path d="M152.164 111.151C148.729 114.101 145.727 117.522 143.248 121.312C143.248 121.312 158.184 131.98 178.81 111.456" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M181.833 110.364C179.989 112.075 178.516 114.146 177.502 116.448C176.489 118.75 175.957 121.235 175.939 123.75C175.939 123.75 190.774 127.51 208.961 112.218" fill="white"/>
        <path d="M181.833 110.364C179.989 112.075 178.516 114.146 177.502 116.448C176.489 118.75 175.957 121.235 175.939 123.75C175.939 123.75 190.774 127.51 208.961 112.218" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.291 97.9678C152.291 97.9678 138.498 106.198 139.031 109.068C139.031 109.068 154.45 115.292 171.799 103.658" fill="white"/>
        <path d="M152.291 97.9678C152.291 97.9678 138.498 106.198 139.031 109.068C139.031 109.068 154.45 115.292 171.799 103.658" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M173.603 101.575C171.682 103.516 170.246 105.884 169.412 108.484C169.412 108.484 178.048 115.063 191.282 105.41" fill="white"/>
        <path d="M173.603 101.575C171.682 103.516 170.246 105.884 169.412 108.484C169.412 108.484 178.048 115.063 191.282 105.41" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.7991 452.592C83.1048 426.937 167.031 365.465 148.971 265.434C134.085 183.134 66.035 190.475 45.5614 207.443C10.8376 236.248 5.55414 303.968 55.722 318.142C139.039 341.486 202.365 237.467 223.067 197.892" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="7.9 4.94"/>
    </svg>

}