import { AnswerType } from './answerTypes.config';

export const questions = [
    {
        id: '1',
        text: 'Первый вопрос — как первое свидание: всегда просят рассказать о себе.',
        question: 'Что делает тебя «сыном маминой подруги»?',
        answers: [
            {
                id: '1',
                text: 'Люблю вдохновлять и убеждать, у меня это отлично получается! Собрать друзей и завтра же отправиться в Сочи? Запросто! ',
                type: AnswerType.Presenter,
            },
            {
                id: '2',
                text: 'Поделить огромный счет в баре на 6 человек, спланировать путешествие или найти нужный магазин в ТЦ с первого раза — умею, практикую. ',
                type: AnswerType.Analytics,
            },
            {
                id: '3',
                text: 'В разговоре со мной не бывает неловких пауз. Заболтаю кого угодно, даже сурового препода на экзамене. ',
                type: AnswerType.Negotiator,
            },
            {
                id: '4',
                text: 'Я уже тот самый сын маминой подруги! Для меня не существует препятствий, а главная цель — конечно же, деньги. Разве у кого-то не так?',
                type: AnswerType.Entrepreneur,
            },
            {
                id: '5',
                text: 'Ну, тут надо сперва поразмыслить. Между прочим, наука допускает, что успех не является синонимом счастья. Более того, иногда ожидание какого-либо события даже лучше влияет на человека, чем само это событие. А о чем был вопрос?',
                type: AnswerType.Intellectual,
            },
        ],
        afterTitle: 'Если бы я не был тестом,\n я бы уже тебя схантил.',
        afterText: 'Но давай посмотрим, что будет дальше!'
    },
    {
        id: '2',
        text: 'У каждого был такой момент: смотришь фильм, а потом еще неделю представляешь себя в роли главного героя.',
        question: 'Раскрывай карты, на кого из героев тебе хотелось бы походить?',
        answers: [
            {
                id: '1',
                text: 'Конечно, на Джордана Белфорта из «Волка с Уолл-стрит». У меня чутье на возможности, которые позволяют делать деньги.',
                type: AnswerType.Entrepreneur,
            },
            {
                id: '2',
                text: 'Думаю, я Алан Тьюринг из «Игры в имитацию». Энигму, конечно, не взломаю, зато могу рассказать, как эта штуковина работает. ',
                type: AnswerType.Intellectual,
            },
            {
                id: '3',
                text: 'Я, как Дрис из «1+1»: люблю пошутить, но и своих в беде не бросаю! И, кстати, тоже не понимаю, как за современное искусство можно отдать 30 000 евро… ',
                type: AnswerType.Negotiator,
            },
            {
                id: '4',
                text: 'Мне кажется, я ближе к Тайлеру из «Бойцовского клуба». Продать ручку на собеседовании — прошлый век, хотите, продам вам мыло?',
                type: AnswerType.Presenter,
            },
            {
                id: '5',
                text: 'Тут все просто: я Шерлок из одноименного сериала BBC. Нет, моя фамилия не Кукумбердвач, но могу пошагово объяснить, как ему удалось достичь такой популярности.',
                type: AnswerType.Analytics,
            },
        ],
        afterTitle: 'Интересный выбор!',
        afterText: 'Но вот совет: если хочешь найти работу мечты – никогда не «играй роли» на собеседовании. Если тебе действительно подходит Яндекс, ты сможешь развиваться в любом интересном направлении.'

    },
    {
        id: '3',
        text: 'Поговорим о стоп-слове. Заинтриговали? Ладно, это не столько стоп-слово, сколько стоп-фактор, который точно заставит тебя отклонить оффер компании.',
        question: 'Чем ты никогда не поступишься?',
        answers: [
            {
                id: '1',
                text: 'Ни за что не пойду работать в мутную контору. Трудовые отношения должны быть надежными. ',
                type: AnswerType.Entrepreneur,
            },
            {
                id: '2',
                text: 'Для меня стоп-фактор — это слишком простые задачи. Деньги деньгами, но деградировать я могу и дома. ',
                type: AnswerType.Intellectual,
            },
            {
                id: '3',
                text: 'Не пойду работать в компанию, сайт которой не менялся с 2005. Особенно смешно читать на допотопном баннере про «инновации и перспективы». ',
                type: AnswerType.Presenter,
            },
            {
                id: '4',
                text: 'Не смогу работать без людей. Бетонные стены достали меня за первый месяц пандемии, скучаю по совместным обедам и локальным мемам.',
                type: AnswerType.Negotiator,
            },
            {
                id: '5',
                text: 'Ой, у меня целый список таких факторов. Могу прислать табличку в Excel с ранжированием значимости.',
                type: AnswerType.Analytics,
            },
        ],
        afterTitle: 'На самом деле, все факторы крайне важны.',
        afterText: 'Поэтому выбрать крутую компанию бывает сложно. Но с Яндексом все прозрачно: это надежный и престижный работодатель, каждый третий студент России хочет работать в компании, приходится соответствовать. 😊'
    },
    {
        id: '4',
        text: 'А что насчет страхов?',
        question: 'Чего ты опасаешься на новом месте работы?',
        answers: [
            {
                id: '1',
                text: 'Боюсь, что не смогу разобраться, как устроен бизнес.',
                type: AnswerType.Analytics,
            },
            {
                id: '2',
                text: 'Боюсь не достичь KPI.',
                type: AnswerType.Entrepreneur,
            },
            {
                id: '3',
                text: 'Боюсь не вырасти в настоящего эксперта, к мнению которого прислушиваются. ',
                type: AnswerType.Intellectual,
            },
            {
                id: '4',
                text: 'Боюсь предстать не в том свете перед начальством.',
                type: AnswerType.Presenter,
            },
            {
                id: '5',
                text: 'Боюсь не найти общий язык с коллегами.',
                type: AnswerType.Negotiator,
            },
        ],
        afterTitle:'А ты не бойся!',
        afterText: 'Ну правда, в хорошей компании к тебе всегда прислушиваются и помогают.'
    },
    {
        id: '5',
        text: 'Давай перейдем к вопросу вознаграждения. З/п 100 000 рублей и полцарства в придачу — это всегда приятно. Но что по нематериальным ценностям?',
        question: 'Что из списка ниже ты можешь назвать лучшей наградой?',
        answers: [
            {
                id: '1',
                text: 'Публичную благодарность от руководства. Я уже репетирую речь перед коллегами. ',
                type: AnswerType.Presenter,
            },
            {
                id: '2',
                text: 'Мне будет приятно услышать искреннее «Спасибо».',
                type: AnswerType.Negotiator,
            },
            {
                id: '3',
                text: 'Какие нематериальные ценности, вы о чем? Время – деньги, так что лучший вид награды — премия.',
                type: AnswerType.Entrepreneur,
            },
            {
                id: '4',
                text: 'Просьбу стать наставником. Если тебе готовы доверить обучение новых специалистов, это многое значит.',
                type: AnswerType.Intellectual,
            },
            {
                id: '5',
                text: 'Лучшая награда – рост выручки компании. Работать «за спасибо» или исключительно за деньги скучно: нужна какая-то цель.',
                type: AnswerType.Analytics,
            },
        ],
        afterTitle: 'Согласны. Но мы за все и сразу! ',
        afterText:  'Со старта карьеры предлагаем: высокую з/п с соц. пакетом, понятные и достижимые цели, хорошие премии, потрясающих коллег. Чем больше ты стараешься, тем больше Яндекс готов тебе предложить, а что конкретно — можешь выбрать самостоятельно :) '
    },
    {
        id: '6',
        text: 'У нас тут, конечно, демоверсия собеседования, но не абстрактного, а в Яндекс!',
        question: 'Если бы ты был продуктом Яндекса, то каким?',
        answers: [
            {
                id: '1',
                text: 'Поисковиком, конечно. Old but gold. ',
                type: AnswerType.Intellectual,
            },
            {
                id: '2',
                text: 'Яндекс.Метрикой: буду измерять успех. ',
                type: AnswerType.Analytics,
            },
            {
                id: '3',
                text: 'Яндекс.Музыкой – это весело!',
                type: AnswerType.Negotiator,
            },
            {
                id: '4',
                text: 'Яндекс.Кью: обожаю каверзные вопросы! ',
                type: AnswerType.Presenter,
            },
            {
                id: '5',
                text: 'Яндекс.Почтой: буду знать обо всех промо-акциях на свете! ',
                type: AnswerType.Entrepreneur,
            },
        ],
        afterTitle: 'У нас реально много продуктов. ',
        afterText: 'Пришлось даже создать отдельную страницу в сети под список с ними. Один из ключевых - Яндекс.Директ. Это сервис для размещения контекстной рекламы, настолько глубокий и популярный, что есть даже отдельные специалисты ТОЛЬКО по его настройке!'
    },
    {
        id: '7',
        text: 'Мы на финишной прямой!',
        question: 'Скажи, какой супергерой тебе нравится больше остальных?',
        answers: [
            {
                id: '1',
                text: 'Человек-Паук',
                type: AnswerType.Negotiator,
            },
            {
                id: '2',
                text: 'Железный человек',
                type: AnswerType.Entrepreneur,
            },
            {
                id: '3',
                text: 'Мистер Фантастик ',
                type: AnswerType.Intellectual,
            },
            {
                id: '4',
                text: 'Халк',
                type: AnswerType.Analytics,
            },
            {
                id: '5',
                text: 'Дэдпул',
                type: AnswerType.Presenter,
            },
        ],
        afterTitle: 'Штош, неплохой выбор.',
        afterText: 'Но в нашем мире супергерои выглядят по-другому. Например, специалист по работе с клиентами Яндекс.Директа помогает сотням компаний и их покупателям находить друг друга.',
    },
    {
        id: '8',
        text: 'Заключительный вопрос. Старт карьеры – это всегда непросто. Сложно определиться с направлением, работодателем. Да и смотреть на требования в интересных позициях бывает очень больно.',
        question: 'А как обстоят дела у тебя?',
        answers: [
            {
                id: '1',
                text: 'Еще не знаю, чем хочу заниматься.',
                type: "",
            },
            {
                id: '2',
                text: 'Я ничего толком не умею, думаю, что меня никуда не возьмут.',
                type: "",
            },
            {
                id: '3',
                text: 'Рассматриваю разные предложения, прохожу собеседования, но всё не то.',
                type: "",
            },
            {
                id: '4',
                text: 'Уже работаю, но это точно не работа мечты.',
                type: '',
            },
            {
                id: '5',
                text: 'У меня всё схвачено!',
                type: '',
            },
        ],
        afterTitle: 'Яндекс занимает первое место ',
        afterText: 'в рейтинге лучших работодателей для молодых специалистов 2021 года. Пока одни мечтают попасть в Яндекс, ты можешь сделать это прямо сейчас, без регистрации и смс. '

    },
];