import React from "react";

export const IntroImgDesktop = (props) =>{
    return <svg {...props} viewBox="0 0 838 718" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.7" d="M121.893 445.583C115.073 413.952 118.024 380.988 130.353 351.08C147.228 309.235 183.492 279.507 224.11 262.159C283.152 236.958 341.113 236.475 389.095 187.07C403.896 171.832 416.315 154.382 431.466 139.466C476.058 95.5114 541.325 100.786 590.066 135.686C691.728 208.49 728.299 352.692 722.644 471.898C720.117 525.391 694.635 575.177 654.324 610.283C563.153 689.665 408.352 676.318 303.666 634.868C225.513 603.968 140.23 531.882 121.893 445.583Z" fill="#F7F7F7"/>
        <path d="M723.349 37.96H394.586V493.062H723.349V37.96Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M558.968 38.2256V494.656" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M723.615 266.45H394.303" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M723.615 152.161H394.303" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M723.615 380.74H394.303" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M663.693 204.911L631.65 235.707" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M711.351 159.089L673.069 195.892" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M629.329 221.036L619.156 230.817" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M673.033 179.005L636.347 214.285" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M485.896 100.704L453.853 131.5" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M533.553 54.8818L495.271 91.7025" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M451.531 116.828L441.358 126.61" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M495.236 74.8162L458.549 110.078" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M656.038 325.969L623.994 356.765" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M703.695 280.147L665.413 316.968" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M621.672 342.094L611.499 351.875" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M665.378 300.081L628.673 335.343" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M511.718 361.851H641.079L610.95 472.809H481.589L511.718 361.851Z" fill="#7D7D7D" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M515.405 365.979H635.124L607.246 468.68H487.526L515.405 365.979Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M610.95 466.926H429.607V472.827H610.95V466.926Z" fill="#7D7D7D" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M610.949 466.926H527.615V472.827H610.949V466.926Z" fill="#595959" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M381.347 482.218H371.6V791.614H381.347V482.218Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M363.252 791.614H371.582L371.582 482.218H363.252L363.252 791.614Z" fill="#A8A8A8" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M631.048 644.101H621.3V791.614H631.048V644.101Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M612.988 791.632H621.317V644.119H612.988V791.632Z" fill="#A8A8A8" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M735.436 644.101H725.689V791.614H735.436V644.101Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M717.341 791.632H725.671V644.119H717.341V791.632Z" fill="#A8A8A8" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M524.674 652.518H619.776V485.212H524.674V652.518Z" fill="#A8A8A8" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M745.113 485.23H619.793V652.535H745.113V485.23Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M619.793 485.23H524.691V493.381H619.793H745.113V485.23H619.793Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M613.892 494.816H532.809V533.869H613.892V494.816Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M584.028 510.657H562.654V518.028H584.028V510.657Z" fill="#263238"/>
        <path d="M613.892 548.612H532.809V587.665H613.892V548.612Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M584.028 563.727H562.654V571.098H584.028V563.727Z" fill="#263238"/>
        <path d="M613.892 602.408H532.809V641.461H613.892V602.408Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M584.028 616.778H562.654V624.149H584.028V616.778Z" fill="#263238"/>
        <path d="M758.033 477.877H349.818V487.604H758.033V477.877Z" fill="#A8A8A8" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M758.033 477.877H634.769V487.604H758.033V477.877Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M384.431 576.892C384.431 576.892 396.589 585.309 414.56 637.332C420.551 654.626 454.242 747.883 456.015 754.28C457.787 760.676 461.332 768.455 466.649 768.455C471.966 768.455 491.461 743.648 489.086 740.707C486.711 737.765 475.51 725.929 475.51 725.929C475.51 725.929 468.811 650.445 467.623 629.181C466.436 607.918 471.274 582.314 460.056 565.179C448.837 548.045 399.194 565.764 399.194 565.764L384.431 576.892Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M470.512 666.924C469.236 651.384 468.12 636.801 467.694 629.27C466.507 608.007 471.345 582.402 460.126 565.268C448.908 548.133 399.265 565.853 399.265 565.853L384.431 576.892C384.431 576.892 396.589 585.308 414.56 637.332C416.847 643.942 423.192 661.679 430.263 681.4L470.512 666.924Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M441.588 273.928C443.121 278.033 443.849 282.394 443.733 286.774C443.201 292.675 437.831 295.35 437.299 293.756C436.84 291.1 436.661 288.404 436.767 285.711V283.567C435.443 285.749 433.821 287.735 431.947 289.467C428.189 293.206 426.045 292.143 424.432 292.143C422.819 292.143 422.288 286.827 424.982 283.035C427.675 279.244 436.236 270.703 441.588 273.928Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M453.923 287.855C448.606 284.63 440.525 295.9 428.189 293.171C426.612 292.834 426.045 290.495 428.721 285.144C431.397 279.793 439.975 275.487 444.264 272.28C448.553 269.073 451.779 266.379 451.779 262.623C451.779 258.866 442.12 264.767 439.373 266.911C436.128 268.932 432.753 270.738 429.27 272.315L426.949 272.439C422.181 272.741 414.826 273.591 412.044 275.983C408.393 279.155 406.301 290.052 405.433 294.252C403.058 297.689 400.931 300.861 399.708 302.775C395.951 308.658 320.38 363.871 320.38 363.871L325.697 411.022L411.459 313.566C413.834 311.624 416.568 310.167 419.505 309.278C425.407 307.134 433.435 305.521 440.418 301.765C445.584 299.005 450.596 295.964 455.43 292.657C459.169 290.336 456.83 289.592 453.923 287.855Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M363.784 331.728C342.729 347.675 320.451 363.959 320.451 363.959L325.768 411.11L378.494 351.219L363.784 331.728Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M337.412 510.108C337.412 510.108 375.215 508.336 430.157 517.196C485.098 526.055 505.657 535.5 504.009 546.131C497.309 589.26 396.589 731.245 396.589 731.245L398.361 751.321C398.361 751.321 394.817 756.052 382.411 756.636C370.004 757.221 354.639 739.52 354.639 739.52C354.639 739.52 368.516 725.167 373.833 708.05C379.15 690.933 421.898 580.383 421.898 580.383C421.898 580.383 365.769 592.786 303.738 581.57C241.707 570.354 337.412 510.108 337.412 510.108Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M430.157 517.196C375.215 508.336 337.412 510.108 337.412 510.108C337.412 510.108 241.707 570.354 303.738 581.57C365.769 592.786 421.898 580.383 421.898 580.383C421.898 580.383 404.175 626.453 389.996 663.663L431.663 679.611C461.473 634.533 499.95 572.232 504.009 546.131C505.657 535.5 485.098 526.055 430.157 517.196Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M218.065 372.482C218.065 372.482 211.631 423.408 212.181 433.047C212.73 442.686 219.677 525.754 221.29 543.987C222.903 562.22 230.4 578.274 243.798 581.499C257.197 584.724 292.041 589.526 307.052 585.787C322.064 582.048 338.138 548.275 343.491 535.411C348.843 522.547 352.618 514.502 351.005 511.827C349.393 509.151 342.427 503.251 342.427 503.251C342.427 503.251 329.029 405.795 325.803 380.527C322.578 355.259 324.208 353.204 312.404 343.547C300.601 333.89 239.509 325.898 218.065 372.482Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M303.844 550.224C301.912 551.695 299.892 553.095 297.836 554.406" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M339.184 507.149C339.184 507.149 327.806 529.475 308.966 546.025" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M330.907 508.336C330.907 508.336 312.599 525.453 294.292 536.084" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M345.635 212.282C348.583 218.473 350.922 224.935 352.618 231.579C354.213 239.623 354.762 247.118 358.502 248.731C362.241 250.343 369.136 250.875 368.161 255.163C367.186 259.451 360.115 266.415 360.115 266.415C360.115 266.415 361.728 292.143 361.178 296.963C360.629 301.782 332.236 301.782 330.641 301.782C329.046 301.782 312.918 339.843 312.918 339.843L267.902 317.322L276.48 289.467C276.48 289.467 262.549 262.658 261.468 250.875C260.387 239.092 287.787 217.651 303.295 212.832C318.802 208.012 345.635 212.282 345.635 212.282Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M330.641 301.782L316.339 297.707L321.691 318.633L330.641 301.782Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M350.952 242.6C351.661 245.63 351.325 248.306 350.173 248.571C349.021 248.837 347.496 246.622 346.77 243.61C346.043 240.598 346.398 237.904 347.55 237.639C348.702 237.373 350.226 239.588 350.952 242.6Z" fill="#263238"/>
        <path d="M341.896 271.234C341.896 271.234 348.329 276.072 354.213 273.928" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M342.959 202.111C342.959 202.111 350.474 215.507 345.104 224.083C339.734 232.659 326.884 236.398 319.919 244.443C312.954 252.487 309.285 266.415 306.521 267.478C303.756 268.541 295.887 252.47 290.96 253.533C286.033 254.596 278.111 265.936 285.076 277.649C286.994 281.003 289.828 283.742 293.245 285.547C296.663 287.351 300.524 288.145 304.376 287.837C304.376 287.837 285.076 298.026 281.336 298.026C277.597 298.026 266.325 291.594 260.972 285.622C255.62 279.651 236.391 262.127 235.221 247.668C234.051 233.209 253.44 214.976 253.44 214.976C253.44 214.976 230.4 213.363 236.302 204.787C242.204 196.211 263.099 190.86 263.099 190.86C263.099 190.86 259.342 177.464 264.712 174.771C270.082 172.077 302.763 182.886 302.763 182.886C302.763 182.886 293.654 171.103 306.521 170.483C319.388 169.862 341.347 186.022 342.959 202.111Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M300.317 260.302L368.693 232.66" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M350.864 228.779C353.709 227.545 356.92 227.454 359.831 228.525C362.742 229.595 365.129 231.745 366.497 234.528C367.865 237.311 368.109 240.514 367.178 243.472C366.247 246.43 364.213 248.916 361.497 250.414" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M325.803 354.763C325.803 354.763 325.272 339.223 320.983 334.935C316.694 330.647 285.076 312.963 270.596 309.225C256.116 305.486 241.122 310.819 231.463 314.044C221.804 317.269 215.388 326.412 211.578 346.276C207.768 366.139 207.289 378.418 215.335 379.499C223.382 380.58 234.636 356.464 259.82 351.627C285.005 346.789 308.062 351.095 313.433 353.771C318.803 356.446 325.803 354.763 325.803 354.763Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.401 338.39C239.563 340.162 236.302 341.438 236.302 341.438" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M325.803 354.763C325.803 354.763 292.129 336.016 279.723 332.259C273.29 330.31 261.734 332.88 252.164 335.803" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M484.974 441.942C484.974 441.942 483.202 453.087 481.164 454.345C479.126 455.603 479.25 443.997 479.25 443.997C479.25 443.997 476.698 450.677 475.12 452.272C473.543 453.867 474.482 445.433 474.482 445.433L476.254 437.477L484.974 441.942Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M462.288 430.956L478.735 431.222C478.735 431.222 483.078 431.842 483.698 434.323C484.578 437.067 485.614 439.758 486.799 442.385L461.349 434.943L462.288 430.956Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M468.172 436.148C468.172 436.148 491.212 433.047 491.762 434.907C492.223 436.307 493.995 448.923 494.385 452.999C494.393 453.138 494.356 453.276 494.28 453.393C494.204 453.51 494.093 453.6 493.962 453.649C493.832 453.698 493.689 453.705 493.555 453.668C493.42 453.63 493.301 453.551 493.215 453.442L486.799 442.35L469.413 443.59C469.413 443.59 464.451 439.249 468.172 436.148Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M277.703 366.671C277.703 366.671 263.418 377.214 268.38 403.279C273.343 429.344 308.098 500.735 311.199 506.866C314.301 512.996 327.948 513.688 334.151 511.827C340.354 509.966 450.839 465.934 450.839 465.934C450.839 465.934 462.625 462.815 464.486 460.335C466.347 457.854 478.151 444.83 478.151 444.83C478.151 444.83 496.76 447.311 501.102 446.071C505.444 444.83 496.76 443.59 492.418 442.35C489.068 441.393 470.601 434.181 462.288 430.921C461.091 430.438 459.777 430.323 458.514 430.592C457.251 430.86 456.097 431.499 455.199 432.427C449.386 438.381 436.998 451.014 434.64 452.893C431.539 455.373 344.058 470.275 344.058 470.275C344.058 470.275 323.57 374.113 315.506 366.05C307.442 357.988 293.175 359.849 293.175 359.849" fill="#FECC00"/>
        <path d="M277.703 366.671C277.703 366.671 263.418 377.214 268.38 403.279C273.343 429.344 308.098 500.735 311.199 506.866C314.301 512.996 327.948 513.688 334.151 511.827C340.354 509.966 450.839 465.934 450.839 465.934C450.839 465.934 462.625 462.815 464.486 460.335C466.347 457.854 478.151 444.83 478.151 444.83C478.151 444.83 496.76 447.311 501.102 446.071C505.444 444.83 496.76 443.59 492.418 442.35C489.068 441.393 470.601 434.181 462.288 430.921C461.091 430.438 459.777 430.323 458.514 430.592C457.251 430.86 456.097 431.499 455.199 432.427C449.386 438.381 436.998 451.014 434.64 452.893C431.539 455.373 344.058 470.275 344.058 470.275C344.058 470.275 323.57 374.113 315.506 366.05C307.442 357.988 293.175 359.849 293.175 359.849" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M393.097 488.863C422.075 477.38 450.839 465.934 450.839 465.934C450.839 465.934 462.625 462.815 464.486 460.334C466.347 457.854 478.133 444.83 478.133 444.83C478.133 444.83 496.76 447.311 501.173 446.07C505.586 444.83 496.831 443.59 492.489 442.349C489.139 441.393 470.671 434.181 462.359 430.921C461.162 430.438 459.848 430.323 458.585 430.591C457.322 430.86 456.168 431.499 455.27 432.427C449.457 438.38 437.068 451.014 434.711 452.892C433.098 454.186 408.658 458.828 385.565 462.992L393.097 488.863Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M199.172 610.948L172.144 790.516C172.048 790.894 172.105 791.295 172.305 791.631C172.504 791.966 172.829 792.209 173.207 792.305C173.586 792.402 173.987 792.344 174.323 792.144C174.658 791.945 174.901 791.62 174.997 791.242L215.76 613.908L199.172 610.948Z" fill="#9C9C9C" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M239.51 612.135L251.26 790.179C251.26 790.569 251.415 790.943 251.691 791.219C251.967 791.495 252.341 791.65 252.731 791.65C253.121 791.65 253.495 791.495 253.771 791.219C254.047 790.943 254.202 790.569 254.202 790.179L256.24 610.7L239.51 612.135Z" fill="#9C9C9C" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M279.156 614.811L311.412 789.771C311.507 790.122 311.728 790.425 312.033 790.622C312.338 790.819 312.705 790.896 313.064 790.839C313.422 790.782 313.747 790.594 313.975 790.312C314.204 790.03 314.32 789.673 314.301 789.311L295.514 611.001L279.156 614.811Z" fill="#9C9C9C" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M341.506 613.341L367.896 789.47C367.975 789.825 368.183 790.138 368.479 790.348C368.775 790.559 369.139 790.652 369.501 790.611C369.862 790.569 370.195 790.395 370.435 790.122C370.675 789.849 370.806 789.497 370.802 789.134L358.006 610.169L341.506 613.341Z" fill="#9C9C9C" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M316.286 577.495C316.286 577.495 285.253 567.146 270.773 529.936C256.293 492.725 232.527 428.617 215.973 420.325C199.42 412.032 167.377 427.554 165.303 487.516C163.229 547.478 168.086 592.06 181.449 606.802C193.27 619.79 213.35 636.34 283.055 637.51C345.316 638.573 370.731 614.847 379.362 606.802C387.993 598.758 402.101 581.57 390.74 581.57C379.38 581.57 330.766 581.57 316.286 577.495Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M459.577 209.465C459.577 209.465 447.472 195.29 430.653 192.649C413.834 190.009 412.699 214.533 434.197 225.288C440.227 228.062 446.91 229.105 453.498 228.301C453.498 228.301 436.324 248.589 447.437 251.832C472.249 259.132 466.932 220.894 466.932 220.894L459.577 209.465Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M463.653 208.49C463.653 208.49 456.564 191.285 461.881 175.09C467.198 158.894 488.27 169.898 488.058 193.943C487.976 200.164 486.311 206.263 483.219 211.662C483.219 211.662 510.265 205.903 506.933 216.978C499.365 242.157 470.494 220.256 470.494 220.256L463.653 208.49Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M473.046 229.984C472.739 229.984 472.437 229.904 472.169 229.751C471.902 229.599 471.679 229.38 471.522 229.116L454.703 200.871C454.462 200.469 454.39 199.988 454.503 199.533C454.616 199.078 454.904 198.686 455.306 198.444C455.507 198.322 455.73 198.242 455.962 198.207C456.194 198.173 456.431 198.185 456.658 198.242C456.886 198.3 457.1 198.402 457.287 198.543C457.475 198.684 457.633 198.861 457.751 199.064L474.553 227.308C474.794 227.71 474.866 228.191 474.753 228.646C474.64 229.101 474.352 229.493 473.95 229.736C473.675 229.894 473.364 229.979 473.046 229.984Z" fill="#263238"/>
        <path d="M457.574 203.334C457.574 203.334 451.513 190.559 440.755 189.159" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M457.751 199.064C457.751 199.064 454.207 188.539 456.901 181.805" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M447.029 211.007C446.391 214.125 441.712 215.826 436.643 214.798C431.574 213.771 427.906 210.386 428.544 207.268C429.182 204.149 433.861 202.43 438.929 203.476C443.998 204.521 447.667 207.87 447.029 211.007Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M459.701 232.979C461.473 234.166 461.473 237.497 459.471 240.456C457.468 243.415 454.384 244.904 452.612 243.628C450.839 242.352 450.84 239.109 452.842 236.15C454.845 233.191 457.805 231.791 459.701 232.979Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M496.547 218.148C496.547 220.309 493.747 222.099 490.22 222.152C486.693 222.205 483.822 220.487 483.786 218.325C483.751 216.163 486.587 214.373 490.114 214.32C493.64 214.267 496.512 216.004 496.547 218.148Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M474.713 199.649C471.54 200.003 468.492 196.105 467.925 190.913C467.358 185.721 469.467 181.274 472.639 180.919C475.811 180.565 478.842 184.463 479.409 189.655C479.976 194.847 477.885 199.294 474.713 199.649Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

}