import React from "react";

export const AnalyticsResult = (props) => {
    return (<svg {...props} viewBox="0 0 292 204" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M289.839 203.122V194.941C289.839 188.509 284.566 182.666 279.287 176.823C274.007 170.979 283.394 140.013 283.977 114.302C284.559 88.5912 272.252 80.9909 261.118 88.5912C249.983 96.1915 244.704 64.049 241.768 45.9309C238.834 27.8126 221.845 -3.7187 194.258 0.360209C166.671 4.43912 172.595 34.2594 159.115 38.9265C145.636 43.5936 129.804 25.4829 102.248 36.5892C74.6919 47.6954 76.4544 120.726 63.5575 134.75C50.6608 148.774 32.4918 120.146 17.25 134.75C2.93556 148.499 3.61757 197.248 3.75551 203.122H289.839Z" fill="#F7F7F7" fillOpacity="0.5"/>
            <path d="M211.684 38.7584C211.684 38.7584 191.423 36.8412 188.633 46.5878C185.844 56.3344 195.614 64.5226 198.595 66.0884C199.997 71.3552 199.564 76.9405 197.369 81.9306C193.699 90.4626 189.3 103.005 170.142 120.413C150.985 137.821 137.827 144.955 134.685 164.456C131.543 183.957 140.969 191.618 166.817 191.794C192.664 191.97 234.741 161.5 245.24 125.286C255.738 89.0724 252.75 74.1089 252.229 61.0395C251.708 47.9703 244.174 35.787 230.894 34.4045C217.615 33.0219 211.684 38.7584 211.684 38.7584Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M204.219 94.1138C211.251 93.7052 218.3 93.7052 225.331 94.1138C236.573 94.8014 240.473 99.1475 240.473 99.1475C240.473 99.1475 223.492 167.993 221.883 180.802C220.274 193.612 220.051 203.45 220.051 203.45H177.805C177.805 203.45 188.127 159.529 192.258 138.264C196.388 116.998 204.219 94.1138 204.219 94.1138Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M209.591 96.0386C209.591 96.0386 206.442 109.268 206.442 117.098C206.442 124.927 211.86 135.033 220.764 131.725C229.669 128.418 231.071 119.046 231.071 112.935C231.071 106.824 230.726 98.4906 230.726 98.4906C230.726 98.4906 226.358 101.974 219.546 99.7053C212.733 97.4364 209.591 96.0386 209.591 96.0386Z" fill="white"/>
            <path d="M231.04 109.268C230.948 103.921 230.726 98.4755 230.726 98.4755C230.726 98.4755 226.358 101.959 219.546 99.6896C212.733 97.4212 209.584 96.0386 209.584 96.0386C209.584 96.0386 208.135 102.149 207.201 108.49C209.178 111.69 212.756 114.417 219.293 114.05C224.55 113.752 228.304 111.896 231.04 109.268Z" fill="#F2F2F2"/>
            <path opacity="0.5" d="M231.04 109.268C230.948 103.921 230.726 98.4755 230.726 98.4755C230.726 98.4755 226.358 101.959 219.546 99.6896C212.733 97.4212 209.584 96.0386 209.584 96.0386C209.584 96.0386 208.135 102.149 207.201 108.49C209.178 111.69 212.756 114.417 219.293 114.05C224.55 113.752 228.304 111.896 231.04 109.268Z" fill="white"/>
            <path d="M209.591 96.0386C209.591 96.0386 206.442 109.268 206.442 117.098C206.442 124.927 211.86 135.033 220.764 131.725C229.669 128.418 231.071 119.046 231.071 112.935C231.071 106.824 230.726 98.4906 230.726 98.4906C230.726 98.4906 226.358 101.974 219.546 99.7053C212.733 97.4364 209.591 96.0386 209.591 96.0386Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M229.852 101.256C229.852 101.256 225.661 106.832 224.435 112.401C223.209 117.969 225.132 121.284 225.132 121.284" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M213.055 202.235C212.879 199.623 212.703 191.267 218.121 177.518C223.538 163.768 227.722 143.214 228.772 126.341C229.822 109.467 230.695 98.4832 230.695 98.4832C230.695 95.3436 231.914 93.4343 241.17 94.8246C250.428 96.2148 271.386 99.1783 271.217 101.966C271.049 104.754 258.956 143.749 257.592 154.022C256.229 164.296 256.543 168.466 261.784 165.854C267.026 163.241 274.359 158.888 274.359 158.888V166.717C274.359 166.717 255.845 190.396 250.428 194.055C245.01 197.714 245.186 197.011 243.791 192.833C242.397 188.655 242.397 174.21 242.397 174.21C236.504 190.312 236.818 199.524 237.469 203.45H213.17C213.147 203.068 213.116 202.702 213.055 202.235ZM250.243 143.848L257.14 118.259L250.243 143.848ZM151.974 203.45H183.767C184.809 199.043 189.607 178.908 193.169 167.939C197.185 155.58 202.427 140.258 203.476 131.03C204.526 121.803 208.894 97.2534 208.894 97.2534C209.591 92.5556 208.894 93.0752 208.894 93.0752C208.894 93.0752 175.353 93.5947 172.878 93.7703C170.403 93.9458 171.483 110.659 171.483 134.513C171.514 156.428 154.709 196.995 151.974 203.45ZM177.629 136.95L183.215 129.64L177.629 136.95Z" fill="white"/>
            <path d="M257.623 154.014C257.891 152.028 258.558 148.98 259.455 145.329L253.056 144.13C253.056 144.13 231.163 183.009 220.212 191.198C217.76 193.031 215.377 194.696 213.247 196.255C213.021 198.24 212.968 200.241 213.086 202.235C213.086 202.702 213.147 203.091 213.17 203.45H237.5C236.849 199.524 236.535 190.312 242.428 174.21C242.428 174.21 242.428 188.655 243.822 192.833C245.217 197.011 245.049 197.714 250.458 194.055C255.868 190.396 274.359 166.717 274.359 166.717V158.888C274.359 158.888 267.026 163.241 261.784 165.846C256.543 168.451 256.221 164.28 257.623 154.014Z" fill="#F7F7F7"/>
            <path d="M173.338 145.834L168.311 155.855C163.292 176.257 153.95 198.783 151.974 203.45H159.545L173.338 145.834Z" fill="#F1F2F4"/>
            <path d="M177.629 136.95L183.215 129.64L177.629 136.95ZM213.055 202.235C212.879 199.623 212.703 191.267 218.121 177.518C223.538 163.768 227.722 143.214 228.772 126.341C229.822 109.467 230.695 98.4832 230.695 98.4832C230.695 95.3436 231.914 93.4343 241.17 94.8246C250.428 96.2148 271.386 99.1783 271.217 101.966C271.049 104.754 258.956 143.749 257.592 154.022C256.229 164.296 256.543 168.466 261.784 165.854C267.026 163.241 274.359 158.888 274.359 158.888V166.717C274.359 166.717 255.845 190.396 250.428 194.055C245.01 197.714 245.186 197.011 243.791 192.833C242.397 188.655 242.397 174.21 242.397 174.21C236.504 190.312 236.818 199.524 237.469 203.45H213.17C213.147 203.068 213.116 202.702 213.055 202.235ZM250.243 143.848L257.14 118.259L250.243 143.848ZM151.974 203.45H183.767C184.809 199.043 189.607 178.908 193.169 167.939C197.185 155.58 202.427 140.258 203.476 131.03C204.526 121.803 208.894 97.2534 208.894 97.2534C209.591 92.5556 208.894 93.0752 208.894 93.0752C208.894 93.0752 175.353 93.5947 172.878 93.7703C170.403 93.9458 171.483 110.659 171.483 134.513C171.514 156.428 154.709 196.995 151.974 203.45Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M207.139 92.8997L193.346 117.625L198.932 125.286L192.472 129.809L188.626 179.603" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M235.822 96.2224L240.052 121.62L234.236 126.05L241.079 132.192L223.975 172.782" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M191.423 165.327C191.423 165.327 200.335 161.668 205.569 157.322C210.802 152.976 220.067 140.082 221.661 140.258C223.255 140.433 232.665 147.224 237.386 148.79C242.106 150.356 245.592 150.356 245.416 151.402C245.24 152.448 243.324 153.487 239.654 153.487C235.983 153.487 226.029 148.263 226.029 148.263C226.029 148.263 231.446 155.229 230.573 156.1C227.151 158.781 223.393 161.004 219.393 162.715C215.201 164.28 212.404 165.678 211.53 165.503C210.657 165.327 210.833 163.585 211.362 163.066L212.933 161.5C212.933 161.5 209.224 164.113 207.316 164.113H205.393L190.373 176.815L191.423 165.327Z" fill="white"/>
            <path d="M243.86 152.815H243.708C235.47 152.25 224.121 145.459 224.121 145.459L226.006 148.278C226.006 148.278 235.968 153.503 239.631 153.503C241.072 153.548 242.509 153.315 243.86 152.815Z" fill="#FFF3BF"/>
            <path d="M225.822 154.778C224.404 155.916 222.136 153.365 222.136 153.365C222.136 153.365 222.703 159.025 221.002 159.308C219.301 159.59 217.025 157.895 217.025 157.895C217.025 157.895 217.025 161.569 215.323 161.569C213.622 161.569 211.07 156.222 211.07 156.222C211.07 156.222 211.684 158.911 209.929 160.744C208.174 162.577 204.825 162.157 204.825 162.157L191.484 171.751L190.717 172.637L190.342 176.808L205.392 164.113H207.308C209.232 164.113 212.902 161.5 212.902 161.5L211.331 163.066C210.802 163.585 210.626 165.357 211.499 165.503C212.373 165.647 215.17 164.28 219.362 162.715C223.362 161.004 227.121 158.781 230.542 156.1C231.416 155.229 225.998 148.263 225.998 148.263C225.998 148.263 227.24 153.647 225.822 154.778Z" fill="#FFF3BF"/>
            <g opacity="0.5">
                <path opacity="0.5" d="M243.86 152.815H243.707C235.47 152.25 224.121 145.459 224.121 145.459L226.006 148.278C226.006 148.278 235.968 153.503 239.631 153.503C241.072 153.548 242.509 153.315 243.86 152.815Z" fill="white"/>
            </g>
            <path d="M212.902 161.492L211.684 158.887" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M216.228 155.58C216.228 155.58 219.722 162.019 219.37 162.715" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M220.941 150.875C220.941 150.875 226.358 158.513 225.485 159.758" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M191.423 165.327C191.423 165.327 200.335 161.668 205.569 157.322C210.802 152.976 220.067 140.082 221.661 140.258C223.255 140.433 232.665 147.224 237.386 148.79C242.106 150.356 245.592 150.356 245.416 151.402C245.24 152.448 243.324 153.487 239.654 153.487C235.983 153.487 226.029 148.263 226.029 148.263C226.029 148.263 231.446 155.229 230.573 156.1C227.151 158.781 223.393 161.004 219.393 162.715C215.201 164.28 212.404 165.678 211.53 165.503C210.657 165.327 210.833 163.585 211.362 163.066L212.933 161.5C212.933 161.5 209.224 164.113 207.316 164.113H205.393L190.373 176.815L191.423 165.327Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M176.059 93.4268C176.059 93.4268 170.288 92.8997 168.893 92.8997C167.498 92.8997 165.399 93.9458 165.062 98.6436C164.725 103.341 141.789 191.267 141.789 193.528C141.789 195.789 145.26 196.583 155.989 193.879C165.039 191.587 191.921 182.567 191.921 182.567L194.296 163.555L166.311 169.161L179.936 129.289" fill="white"/>
            <path d="M192.74 176.219C190.763 176.747 159.452 185.126 153.491 187.104C147.36 189.151 160.334 178.916 155.889 179.259C151.445 179.603 146.303 185.737 146.303 185.737L158.211 127.486C151.36 154.946 141.789 192.069 141.789 193.528C141.789 195.819 145.26 196.583 155.989 193.879C165.039 191.587 191.921 182.567 191.921 182.567L192.74 176.219Z" fill="#F1F2F4"/>
            <path d="M176.059 93.4268C176.059 93.4268 170.288 92.8997 168.893 92.8997C167.498 92.8997 165.399 93.9458 165.062 98.6436C164.725 103.341 141.789 191.267 141.789 193.528C141.789 195.789 145.26 196.583 155.989 193.879C165.039 191.587 191.921 182.567 191.921 182.567L194.296 163.555L166.311 169.161L179.936 129.289" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M177.207 141.594L175.637 161.095L188.25 161.599L189.43 142.641L177.207 141.594Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M181.928 136.69L181.752 144.352L185.07 144.527L185.246 136.69H181.928Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M250.259 131.871L227.807 180.833C227.669 181.133 227.608 181.462 227.631 181.792C227.653 182.121 227.758 182.439 227.935 182.718C228.113 182.997 228.357 183.227 228.647 183.387C228.936 183.547 229.261 183.633 229.592 183.636L265.976 183.942C266.351 183.946 266.719 183.844 267.038 183.648C267.357 183.452 267.613 183.17 267.777 182.834L291.218 134.651C291.363 134.354 291.431 134.025 291.415 133.695C291.4 133.364 291.301 133.042 291.129 132.76C290.957 132.477 290.716 132.242 290.429 132.076C290.142 131.91 289.818 131.818 289.486 131.81L252.136 130.717C251.745 130.705 251.358 130.808 251.024 131.013C250.69 131.218 250.424 131.516 250.259 131.871Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M235.462 166.526L233.661 170.437" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M258.428 131.634L252.872 131.466C252.482 131.456 252.097 131.561 251.766 131.767C251.435 131.974 251.172 132.273 251.01 132.627L236.811 163.539" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M263.11 131.764L260.167 131.679" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M284.061 160.423C284.061 160.423 279.716 162.294 276.398 163.578C273.079 164.861 274.988 166.037 276.283 166.037C277.578 166.037 282.62 164.509 284.497 163.348C286.375 162.187 286.168 160.071 284.061 160.423Z" fill="#FFE680"/>
            <path opacity="0.5" d="M284.061 160.423C284.061 160.423 279.716 162.294 276.398 163.578C273.079 164.861 274.988 166.037 276.283 166.037C277.578 166.037 282.62 164.509 284.497 163.348C286.375 162.187 286.168 160.071 284.061 160.423Z" fill="white"/>
            <path d="M284.061 160.423C284.061 160.423 279.716 162.294 276.398 163.578C273.079 164.861 274.988 166.037 276.283 166.037C277.578 166.037 282.62 164.509 284.497 163.348C286.375 162.187 286.168 160.071 284.061 160.423Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M286.459 155.878C286.459 155.878 280.558 158.422 276.091 160.163C271.624 161.905 274.183 163.501 275.938 163.501C277.693 163.501 284.543 161.439 287.095 159.85C289.647 158.261 289.325 155.397 286.459 155.878Z" fill="#FFF9DF"/>
            <path opacity="0.5" d="M286.459 155.878C286.459 155.878 280.558 158.422 276.091 160.163C271.624 161.905 274.183 163.501 275.938 163.501C277.693 163.501 284.543 161.439 287.095 159.85C289.647 158.261 289.325 155.397 286.459 155.878Z" fill="white"/>
            <path d="M286.459 155.878C286.459 155.878 280.558 158.422 276.091 160.163C271.624 161.905 274.183 163.501 275.938 163.501C277.693 163.501 284.543 161.439 287.095 159.85C289.647 158.261 289.325 155.397 286.459 155.878Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M286.283 151.631C286.283 151.631 279.815 154.412 274.926 156.328C270.037 158.246 272.834 159.988 274.75 159.988C276.666 159.988 284.183 157.696 287.011 155.977C289.838 154.259 289.424 151.127 286.283 151.631Z" fill="#FFF3BF"/>
            <path opacity="0.5" d="M286.283 151.631C286.283 151.631 279.815 154.412 274.926 156.328C270.037 158.246 272.834 159.988 274.75 159.988C276.666 159.988 284.183 157.696 287.011 155.977C289.838 154.259 289.424 151.127 286.283 151.631Z" fill="white"/>
            <path d="M286.283 151.631C286.283 151.631 279.815 154.412 274.926 156.328C270.037 158.246 272.834 159.988 274.75 159.988C276.666 159.988 284.183 157.696 287.011 155.977C289.838 154.259 289.424 151.127 286.283 151.631Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M286.789 147.743C286.789 147.743 280.329 150.531 275.44 152.441C270.551 154.351 273.34 156.1 275.263 156.1C277.187 156.1 284.696 153.808 287.486 152.097C290.275 150.386 289.93 147.224 286.789 147.743Z" fill="#FFF3BF"/>
            <path opacity="0.5" d="M286.789 147.743C286.789 147.743 280.329 150.531 275.44 152.441C270.551 154.351 273.34 156.1 275.263 156.1C277.187 156.1 284.696 153.808 287.486 152.097C290.275 150.386 289.93 147.224 286.789 147.743Z" fill="white"/>
            <path d="M286.789 147.743C286.789 147.743 280.329 150.531 275.44 152.441C270.551 154.351 273.34 156.1 275.263 156.1C277.187 156.1 284.696 153.808 287.486 152.097C290.275 150.386 289.93 147.224 286.789 147.743Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M220.94 51.6367C220.94 51.6367 208.366 59.6493 205.745 62.7813C203.124 65.9128 204.35 72.5356 204.526 74.6209C204.702 76.7062 204.871 90.6613 206.097 92.8995C207.323 95.1377 211.071 104.143 223.27 103.593C232.06 103.188 236.489 96.0542 238.236 91.5244C239.355 88.3801 240.059 85.1039 240.328 81.7777C240.328 81.7777 245.408 80.8996 245.408 76.7213C245.408 72.5431 244.274 67.6698 241.899 68.1969C240.695 68.4643 239.11 71.1529 239.11 71.1529C234.597 68.0662 230.479 64.4418 226.848 60.3595C221.293 53.9053 220.94 51.6367 220.94 51.6367Z" fill="white"/>
            <path d="M220.151 58.8856C220.151 58.8856 231.645 74.0554 239.109 71.107C234.597 68.0209 230.479 64.396 226.848 60.3142C221.262 53.8748 220.909 51.6062 220.909 51.6062C220.909 51.6062 208.335 59.6191 205.714 62.7506C205.118 63.5108 204.697 64.3925 204.48 65.3323L220.151 58.8856Z" fill="#F7F7F7"/>
            <path d="M245.24 74.1472C245.111 75.513 244.778 76.8515 244.251 78.1191C242.826 81.2355 239.14 80.6705 239.14 80.6705C239.14 80.6705 236.841 92.5479 233.776 96.2222C230.711 99.896 222.143 103.012 213.63 98.7731C205.116 94.5338 206.251 84.0618 206.251 84.0618C206.251 84.0618 211.361 84.0618 213.347 79.2495C215.331 74.4372 212.779 72.1764 207.668 72.4664C206.473 72.5123 205.331 72.9743 204.442 73.7729C204.48 74.1013 204.511 74.3913 204.534 74.6209C204.71 76.7138 204.879 90.6613 206.105 92.8995C207.331 95.1377 211.078 104.143 223.277 103.593C232.067 103.188 236.496 96.0542 238.243 91.5244C239.364 88.3801 240.067 85.1039 240.335 81.7777C240.335 81.7777 245.416 80.8995 245.416 76.7213C245.404 75.8606 245.345 75.0016 245.24 74.1472Z" fill="#F7F7F7"/>
            <path d="M230.88 82.2458C234.451 80.762 236.138 76.6745 234.65 73.1152C233.162 69.5565 229.061 67.874 225.49 69.3578C221.92 70.8416 220.232 74.9291 221.721 78.4884C223.209 82.047 227.31 83.7296 230.88 82.2458Z" fill="#FECC00"/>
            <path opacity="0.5" d="M230.88 82.2458C234.451 80.762 236.138 76.6745 234.65 73.1152C233.162 69.5565 229.061 67.874 225.49 69.3578C221.92 70.8416 220.232 74.9291 221.721 78.4884C223.209 82.047 227.31 83.7296 230.88 82.2458Z" fill="white"/>
            <path d="M220.94 51.6367C220.94 51.6367 208.366 59.6493 205.745 62.7813C203.124 65.9128 204.35 72.5356 204.526 74.6209C204.702 76.7062 204.871 90.6613 206.097 92.8995C207.323 95.1377 211.071 104.143 223.27 103.593C232.06 103.188 236.489 96.0543 238.236 91.5244C239.355 88.3801 240.059 85.1039 240.328 81.7777C240.328 81.7777 245.408 80.8996 245.408 76.7213C245.408 72.5431 244.274 67.6698 241.899 68.1969C240.695 68.4643 239.11 71.1529 239.11 71.1529C234.597 68.0662 230.479 64.4418 226.848 60.3595C221.293 53.9053 220.94 51.6367 220.94 51.6367Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M208.71 80.7083C212.663 80.7083 215.867 77.5141 215.867 73.5741C215.867 69.6342 212.663 66.4399 208.71 66.4399C204.757 66.4399 201.553 69.6342 201.553 73.5741C201.553 77.5141 204.757 80.7083 208.71 80.7083Z" fill="white"/>
            <path d="M202.794 77.5921C203.764 79.0039 205.21 80.0221 206.868 80.4609C208.527 80.8997 210.289 80.7306 211.833 79.9843C213.378 79.2375 214.602 77.9635 215.283 76.3931C215.965 74.8227 216.058 73.0611 215.546 71.428C214.948 72.7339 214.235 73.7196 213.461 73.7807C211.04 73.987 210.588 73.4674 208.718 73.5743C207.507 73.6511 204.664 75.9578 202.794 77.5921Z" fill="#FECC00"/>
            <path d="M208.71 80.7083C212.663 80.7083 215.867 77.5141 215.867 73.5741C215.867 69.6342 212.663 66.4399 208.71 66.4399C204.757 66.4399 201.553 69.6342 201.553 73.5741C201.553 77.5141 204.757 80.7083 208.71 80.7083Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M228.274 80.7083C232.227 80.7083 235.431 77.5141 235.431 73.5741C235.431 69.6342 232.227 66.4399 228.274 66.4399C224.321 66.4399 221.117 69.6342 221.117 73.5741C221.117 77.5141 224.321 80.7083 228.274 80.7083Z" fill="white"/>
            <path d="M228.282 80.7161C230.18 80.7144 232.001 79.9611 233.343 78.6221C234.685 77.2824 235.439 75.4673 235.439 73.5744C235.439 72.8136 235.315 72.0574 235.071 71.3362C232.588 75.7591 229.562 73.4523 226.527 73.1466C224.703 72.9635 222.987 74.9646 221.852 76.714C222.44 77.9142 223.355 78.9255 224.492 79.6334C225.629 80.3407 226.942 80.7161 228.282 80.7161Z" fill="#FECC00"/>
            <path d="M228.274 80.7083C232.227 80.7083 235.431 77.5141 235.431 73.5741C235.431 69.6342 232.227 66.4399 228.274 66.4399C224.321 66.4399 221.117 69.6342 221.117 73.5741C221.117 77.5141 224.321 80.7083 228.274 80.7083Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M215.875 70.7939C215.875 70.7939 218.848 68.182 221.469 70.7939" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M206.097 64.5228C206.097 64.5228 208.718 60.8641 212.909 61.7348" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M222.167 61.3912C222.167 61.3912 226.358 59.6494 230.373 64.3472" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M216.925 73.4062C216.925 73.4062 217.446 77.5845 216.404 79.5169C215.361 81.4494 213.085 82.4805 213.959 83.8707C214.833 85.2609 217.791 85.9636 217.791 85.9636" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M217.101 89.9437C217.101 89.9437 221.469 90.9899 224.434 87.8508" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M58.9368 203.443H0.582886" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.71" d="M174.801 47.9016H35.5873V134.33H174.801V47.9016Z" fill="white"/>
            <path d="M33.319 42.738V139.486H177.039V42.738H33.319ZM174.794 134.323H35.5872V47.9016H174.794V134.323Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M41.1507 50.6362H134.18C134.519 50.6362 134.854 50.7028 135.168 50.8322C135.481 50.9615 135.766 51.1512 136.006 51.3902C136.246 51.6292 136.436 51.913 136.566 52.2253C136.695 52.5376 136.762 52.8723 136.762 53.2104V55.754C136.762 56.4387 136.489 57.0954 136.003 57.5795C135.518 58.0637 134.859 58.3359 134.172 58.3359H41.1507C40.8109 58.3359 40.4745 58.2691 40.1607 58.1389C39.8469 58.0092 39.5618 57.8189 39.3219 57.5791C39.082 57.3393 38.892 57.0546 38.7626 56.7414C38.6333 56.4282 38.5673 56.0927 38.5682 55.754V53.2104C38.5682 52.8723 38.635 52.5376 38.7648 52.2253C38.8946 51.913 39.0848 51.6292 39.3246 51.3902C39.5644 51.1512 39.8491 50.9615 40.1624 50.8322C40.4757 50.7028 40.8116 50.6362 41.1507 50.6362Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M40.1621 66.9597H60.3693" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M40.1621 71.5042H60.3693" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M40.1621 76.0415H60.3693" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M40.1621 80.8613H60.3693" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M64.3007 66.9597H84.5081" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M64.3007 71.5042H84.5081" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M64.3007 76.0415H84.5081" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M64.3007 80.8613H84.5081" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M88.4393 66.9597H108.647" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M88.4393 71.5042H108.647" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M88.4393 76.0415H108.647" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M88.4393 80.8613H108.647" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M112.578 66.9597H132.785" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M112.578 71.5042H132.785" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M112.578 76.0415H132.785" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M112.578 80.8613H132.785" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M52.3309 113.126L69.4885 96.0237L82.1173 107.313L92.7995 97.6353L108.669 109.574L125.827 91.502" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M69.4884 98.2844C70.7415 98.2844 71.7568 97.2725 71.7568 96.0236C71.7568 94.7751 70.7415 93.7627 69.4884 93.7627C68.2359 93.7627 67.22 94.7751 67.22 96.0236C67.22 97.2725 68.2359 98.2844 69.4884 98.2844Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M92.7995 99.896C94.052 99.896 95.0679 98.8841 95.0679 97.6351C95.0679 96.3867 94.052 95.3743 92.7995 95.3743C91.547 95.3743 90.5311 96.3867 90.5311 97.6351C90.5311 98.8841 91.547 99.896 92.7995 99.896Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M108.669 111.835C109.922 111.835 110.938 110.823 110.938 109.574C110.938 108.325 109.922 107.313 108.669 107.313C107.417 107.313 106.401 108.325 106.401 109.574C106.401 110.823 107.417 111.835 108.669 111.835Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M125.505 93.7627C126.758 93.7627 127.774 92.7503 127.774 91.5019C127.774 90.2529 126.758 89.2405 125.505 89.2405C124.253 89.2405 123.237 90.2529 123.237 91.5019C123.237 92.7503 124.253 93.7627 125.505 93.7627Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M52.331 115.387C53.5837 115.387 54.5992 114.375 54.5992 113.126C54.5992 111.877 53.5837 110.865 52.331 110.865C51.0783 110.865 50.0627 111.877 50.0627 113.126C50.0627 114.375 51.0783 115.387 52.331 115.387Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M82.1173 108.933C83.3698 108.933 84.3851 107.92 84.3851 106.672C84.3851 105.423 83.3698 104.411 82.1173 104.411C80.8642 104.411 79.8489 105.423 79.8489 106.672C79.8489 107.92 80.8642 108.933 82.1173 108.933Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M143.008 132.398C142.662 132.398 142.33 132.261 142.086 132.017C141.842 131.774 141.705 131.443 141.705 131.099V51.8278C141.705 51.4834 141.842 51.1531 142.086 50.9096C142.33 50.6661 142.662 50.5293 143.008 50.5293C143.354 50.5293 143.686 50.6658 143.932 50.9091C144.177 51.1525 144.316 51.4827 144.318 51.8278V131.099C144.316 131.444 144.177 131.775 143.932 132.018C143.686 132.261 143.354 132.398 143.008 132.398Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M157.391 66.4399H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 66.4399H152.065" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M169.422 70.2749H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M162.249 70.2749H167.338" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 70.2749H158.778" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M159.008 74.1013H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 74.1013H156.233" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M166.41 77.936H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M152.532 77.936H164.096" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 77.936H149.988" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M157.391 81.2891H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 81.2891H152.065" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M169.422 85.1162H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M162.249 85.1162H167.338" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 85.1162H158.778" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M159.008 88.9504H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 88.9504H156.233" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M166.41 92.7852H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M152.532 92.7852H164.096" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 92.7852H149.988" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M157.391 96.1379H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 96.1379H152.065" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M169.422 99.9653H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M162.249 99.9653H167.338" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 99.9653H158.778" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M159.008 103.799H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 103.799H156.233" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M166.41 107.626H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M152.532 107.626H164.096" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 107.626H149.988" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M157.391 110.979H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 110.979H152.065" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M169.422 114.814H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M162.249 114.814H167.338" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 114.814H158.778" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M159.008 118.641H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 118.641H156.233" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M166.41 122.476H171.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M152.532 122.476H164.096" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M146.977 122.476H149.988" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M45.2889 124.37H41.8406V132.008H45.2889V124.37Z" fill="#FECC00"/>
            <path d="M52.1855 127.777H48.7372V132.008H52.1855V127.777Z" fill="#263238"/>
            <path d="M59.0825 129.747H55.6339V132.008H59.0825V129.747Z" fill="#FECC00"/>
            <path d="M65.9786 126.6H62.5305V132.008H65.9786V126.6Z" fill="#263238"/>
            <path d="M72.8753 128.563H69.4272V132.008H72.8753V128.563Z" fill="#FECC00"/>
            <path d="M79.7725 126.333H76.3239V132.008H79.7725V126.333Z" fill="#263238"/>
            <path d="M86.6693 131.053H83.2206V132.008H86.6693V131.053Z" fill="#FECC00"/>
            <path d="M93.5659 124.37H90.1172V132.008H93.5659V124.37Z" fill="#263238"/>
            <path d="M100.463 129.22H97.0139V132.008H100.463V129.22Z" fill="#FECC00"/>
            <path d="M107.359 130.923H103.911V132.008H107.359V130.923Z" fill="#263238"/>
            <path d="M114.256 129.479H110.808V132.008H114.256V129.479Z" fill="#FECC00"/>
            <path d="M121.153 124.37H117.705V132.008H121.153V124.37Z" fill="#263238"/>
            <path d="M128.049 125.943H124.601V132.008H128.049V125.943Z" fill="#FECC00"/>
            <path d="M134.946 126.99H131.497V132.008H134.946V126.99Z" fill="#263238"/>
            <path d="M149.452 61.1773V21.1748H157.138" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M163.116 20.8845H177.345" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M163.116 25.4294H177.345" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M163.116 29.9666H177.345" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M42.6681 184.843H57.7566" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M42.6681 189.098H57.7566" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M42.6681 193.352H57.7566" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M42.6681 197.607H57.7566" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M93.9745 191.62C106.664 188.593 114.489 175.885 111.452 163.236C108.415 150.588 95.6659 142.788 82.9764 145.815C70.287 148.842 62.4624 161.55 65.4996 174.199C68.5367 186.847 81.2851 194.647 93.9745 191.62Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M92.5948 185.809C102.075 183.554 107.927 174.065 105.665 164.615C103.403 155.165 93.8841 149.332 84.4031 151.587C74.9227 153.842 69.0711 163.33 71.333 172.781C73.595 182.231 83.1145 188.064 92.5948 185.809Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M98.7997 172.915C101.135 167.236 98.4098 160.744 92.712 158.416C87.0148 156.088 80.5022 158.805 78.1668 164.484C75.8308 170.164 78.5567 176.655 84.2545 178.983C89.9517 181.311 96.4643 178.595 98.7997 172.915Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M58.5917 176.939C63.1495 172.396 63.1495 165.03 58.5917 160.487C54.0342 155.944 46.6447 155.944 42.087 160.487C37.5293 165.03 37.5292 172.396 42.087 176.939C46.6447 181.482 54.0342 181.482 58.5917 176.939Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M54.9563 173.315C57.506 170.773 57.506 166.652 54.9563 164.111C52.4066 161.57 48.2728 161.57 45.723 164.111C43.1734 166.652 43.1734 170.773 45.723 173.315C48.2728 175.856 52.4066 175.856 54.9563 173.315Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M30.1108 175.416C33.832 174.808 36.3547 171.309 35.7454 167.6C35.136 163.89 31.6253 161.376 27.904 161.983C24.1827 162.59 21.66 166.09 22.2694 169.799C22.8788 173.509 26.3895 176.023 30.1108 175.416Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32.8289 168.695C32.8289 167.94 32.6042 167.202 32.1832 166.573C31.7622 165.946 31.1638 165.456 30.4637 165.167C29.7636 164.878 28.9932 164.802 28.2499 164.949C27.5067 165.097 26.824 165.461 26.2881 165.995C25.7523 166.529 25.3873 167.21 25.2395 167.95C25.0917 168.691 25.1676 169.459 25.4576 170.157C25.7475 170.855 26.2386 171.451 26.8687 171.871C27.4988 172.29 28.2396 172.515 28.9974 172.515C30.0136 172.515 30.9881 172.112 31.7067 171.396C32.4252 170.679 32.8289 169.708 32.8289 168.695Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M88.4854 168.711H14.4758" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}