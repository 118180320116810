export const Question6Img = (props) => {
    return <svg {...props} viewBox="0 0 491 428" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M64.9892 43.0324L65.6541 42.4384C126.889 -12.7322 208.215 -15.2151 267.515 44.161C298.388 75.1202 353.234 69.1921 392.467 80.3831C527.737 118.981 504.345 302.61 437.529 391.377C377.232 471.472 246.581 489.565 158.807 450.159C81.6249 415.529 27.36 338.155 8.00508 257.264C6.06563 249.154 4.48241 240.969 3.25542 232.708C-6.93261 163.84 12.5885 90.7068 64.9892 43.0324Z" fill="#F7F7F7" fillOpacity="0.5"/>
        <path d="M87.8115 366.061C101.431 366.061 181.154 328.461 342.334 322.556" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M115.181 364.576C115.181 364.576 120.251 420.281 283.699 423.465" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M38.4741 277.698C38.4741 277.698 268.833 265.996 390.4 275.322" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M106.299 98.4049H28.5592C26.0213 98.4049 23.9639 100.463 23.9639 103.002V158.815C23.9639 161.354 26.0213 163.412 28.5592 163.412H106.299C108.837 163.412 110.895 161.354 110.895 158.815V103.002C110.895 100.463 108.837 98.4049 106.299 98.4049Z" fill="white"/>
        <path opacity="0.4" d="M110.895 145.462V158.815C110.895 160.034 110.41 161.203 109.549 162.066C108.687 162.928 107.518 163.412 106.299 163.412H86.6001V150.796C86.6001 149.381 87.1618 148.024 88.1617 147.024C89.1615 146.024 90.5176 145.462 91.9316 145.462H110.895Z" fill="#92E3A9"/>
        <path d="M106.299 98.4049H28.5592C26.0213 98.4049 23.9639 100.463 23.9639 103.002V158.815C23.9639 161.354 26.0213 163.412 28.5592 163.412H106.299C108.837 163.412 110.895 161.354 110.895 158.815V103.002C110.895 100.463 108.837 98.4049 106.299 98.4049Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M93.0832 142.242H41.7631C39.0678 142.242 36.8828 144.428 36.8828 147.125V148.444C36.8828 151.14 39.0678 153.326 41.7631 153.326H93.0832C95.7785 153.326 97.9635 151.14 97.9635 148.444V147.125C97.9635 144.428 95.7785 142.242 93.0832 142.242Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M76.4956 145.462H71.8291V150.13H76.4956V145.462Z" fill="#263238"/>
        <path d="M62.8404 145.462H61.3799V150.13H62.8404V145.462Z" fill="#263238"/>
        <path d="M60.002 145.462H58.5415V150.13H60.002V145.462Z" fill="#263238"/>
        <path d="M88.9153 145.462V147.719L84.8662 145.462V150.118L88.9153 147.849V150.118H90.3758V145.462H88.9153Z" fill="#263238"/>
        <path d="M45.9309 150.118V147.849L49.98 150.118V145.462L45.9309 147.719V145.462H44.4585V150.118H45.9309Z" fill="#263238"/>
        <path d="M60.4652 108.479H38.308C37.4883 108.479 36.8237 109.144 36.8237 109.964V132.132C36.8237 132.953 37.4883 133.617 38.308 133.617H60.4652C61.2849 133.617 61.9495 132.953 61.9495 132.132V109.964C61.9495 109.144 61.2849 108.479 60.4652 108.479Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M43.2715 127.63C43.2715 129.222 45.2426 130.505 47.6649 130.505C50.0873 130.505 52.0584 129.222 52.0584 127.63C52.0584 126.038 50.0991 124.743 47.6649 124.743C45.2307 124.743 43.2715 126.038 43.2715 127.63Z" fill="#263238"/>
        <path d="M48.1992 112.293L51.2152 127.63H52.0583L49.8497 116.427L49.2798 113.505C48.8649 113.162 48.5011 112.762 48.1992 112.317V112.293Z" fill="#263238"/>
        <path d="M48.1992 112.293L48.9473 115.999C49.2311 116.172 49.5339 116.312 49.8497 116.415C50.788 116.736 51.7829 116.857 52.7708 116.772C53.4446 116.715 54.1207 116.851 54.7199 117.165C55.3192 117.478 55.8169 117.956 56.1549 118.542C56.1549 118.364 55.2525 115.097 52.2008 114.764C51.1373 114.638 50.1361 114.195 49.3273 113.493C48.8947 113.15 48.5147 112.746 48.1992 112.293Z" fill="#263238"/>
        <path d="M70.5463 121.048H68.2783V133.617H70.5463V121.048Z" fill="#263238"/>
        <path d="M75.1298 121.048H72.8618V133.617H75.1298V121.048Z" fill="#263238"/>
        <path d="M79.7133 117.009H77.4453V133.617H79.7133V117.009Z" fill="#263238"/>
        <path d="M84.285 114.312H82.0171V133.617H84.285V114.312Z" fill="#263238"/>
        <path d="M88.8681 117.009H86.6001V133.617H88.8681V117.009Z" fill="#263238"/>
        <path d="M93.4511 114.312H91.1831V133.617H93.4511V114.312Z" fill="#263238"/>
        <path d="M98.035 121.048H95.7671V133.617H98.035V121.048Z" fill="#263238"/>
        <path d="M77.5283 166.976H106.359C108.521 166.973 110.594 166.112 112.124 164.582C113.653 163.052 114.513 160.978 114.516 158.815V131.491" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.5894 166.964H73.7045" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.4019 133.368V158.815C20.405 160.978 21.2655 163.052 22.7946 164.582C24.3238 166.112 26.3969 166.973 28.5594 166.976H55.87" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M40.8254 94.8411H28.5594C26.3969 94.8442 24.3238 95.7051 22.7946 97.235C21.2655 98.7649 20.405 100.839 20.4019 103.003V123.555" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50.6811 94.8411H45.0527" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M114.457 110.808V103.003C114.454 100.839 113.594 98.7649 112.065 97.235C110.535 95.7051 108.462 94.8442 106.3 94.8411H58.2095" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M114.457 129.602V124.434" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M112.7 145.414H99.638C97.4755 145.417 95.4024 146.278 93.8732 147.808C92.3441 149.338 91.4836 151.412 91.4805 153.576V167.214" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M135.712 145.414H126.783" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M218.676 167.214V153.576C218.673 151.412 217.813 149.338 216.284 147.808C214.754 146.278 212.681 145.417 210.519 145.414H139.464" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M176.583 242.355H210.519C212.681 242.352 214.754 241.491 216.284 239.961C217.813 238.431 218.673 236.357 218.676 234.193V202.949" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M146.98 242.331H158.261" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M91.4922 185.545V234.169C91.4953 236.333 92.3558 238.407 93.8849 239.937C95.4141 241.467 97.4872 242.328 99.6497 242.331H140.877" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M218.677 194.466V185.533" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M210.519 148.978H99.6495C97.1116 148.978 95.0542 151.036 95.0542 153.575V234.169C95.0542 236.708 97.1116 238.767 99.6495 238.767H210.519C213.056 238.767 215.114 236.708 215.114 234.169V153.575C215.114 151.036 213.056 148.978 210.519 148.978Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M199.336 188.009C201.333 179.501 196.057 170.985 187.553 168.988C179.049 166.991 170.537 172.269 168.541 180.777C166.544 189.285 171.82 197.802 180.324 199.799C188.828 201.796 197.34 196.518 199.336 188.009Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M191.651 175.827L191.224 175.399C191.004 175.195 190.716 175.082 190.416 175.082C190.117 175.082 189.828 175.195 189.609 175.399L186.201 178.809C185.997 179.028 185.884 179.317 185.884 179.617C185.884 179.916 185.997 180.205 186.201 180.424L187.388 181.612C187.51 181.732 187.606 181.874 187.672 182.031C187.737 182.188 187.771 182.357 187.771 182.527C187.771 182.697 187.737 182.866 187.672 183.023C187.606 183.18 187.51 183.323 187.388 183.442L182.971 187.861C182.729 188.1 182.403 188.234 182.063 188.234C181.723 188.234 181.396 188.1 181.154 187.861L179.967 186.673C179.747 186.47 179.459 186.356 179.159 186.356C178.86 186.356 178.572 186.47 178.352 186.673L174.897 190.083C174.689 190.303 174.574 190.594 174.574 190.897C174.574 191.199 174.689 191.49 174.897 191.71L175.336 192.138C175.844 192.647 176.448 193.05 177.112 193.325C177.776 193.601 178.488 193.742 179.207 193.742C179.926 193.742 180.638 193.601 181.302 193.325C181.966 193.05 182.57 192.647 183.078 192.138L191.627 183.573C192.137 183.066 192.542 182.463 192.82 181.799C193.097 181.136 193.24 180.424 193.243 179.705C193.245 178.985 193.105 178.273 192.832 177.607C192.559 176.942 192.158 176.337 191.651 175.827Z" fill="#263238"/>
        <path d="M150.257 164.719H110.087C109.012 164.719 108.14 165.591 108.14 166.667V206.857C108.14 207.933 109.012 208.806 110.087 208.806H150.257C151.333 208.806 152.205 207.933 152.205 206.857V166.667C152.205 165.591 151.333 164.719 150.257 164.719Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M144.249 173.178C144.249 173.178 146.517 171.17 148.275 177.763C150.032 184.357 148.856 191.722 145.555 198.981C142.254 206.24 149.498 205.741 149.616 208.806H112.89C112.89 208.806 108.045 203.008 112.89 198.981C117.734 194.954 117.366 196.284 116.262 191.508C115.157 186.733 113.448 174.14 124.657 168.438C141.744 159.765 144.249 173.178 144.249 173.178Z" fill="#263238"/>
        <path d="M137.172 199.848C137.172 199.848 135.391 204.755 136.377 208.319H122.27C123.941 205.274 124.407 201.712 123.576 198.339C122.27 192.364 137.172 199.848 137.172 199.848Z" fill="white" stroke="#263238" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M121 184.499C121 184.499 120.002 182.955 119.1 183.311C118.197 183.668 118.197 187.814 118.269 188.907C118.34 190 120.537 192.471 121.463 191.77" fill="white"/>
        <path d="M121 184.499C121 184.499 120.002 182.955 119.1 183.311C118.197 183.668 118.197 187.814 118.269 188.907C118.34 190 120.537 192.471 121.463 191.77" stroke="#263238" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.122 187.707C143.122 187.707 144.535 186.519 145.306 187.077C146.078 187.636 144.867 191.651 144.487 192.673C144.107 193.694 141.293 195.405 140.616 194.502" fill="white"/>
        <path d="M143.122 187.707C143.122 187.707 144.535 186.519 145.306 187.077C146.078 187.636 144.867 191.651 144.487 192.673C144.107 193.694 141.293 195.405 140.616 194.502" stroke="#263238" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M129.466 203.816C125.761 203.281 122.08 196.997 121.641 195.227C121.142 193.255 120.062 183.549 122.175 178.226C124.55 172.286 129.526 169.732 134.37 170.445C139.215 171.158 143.264 175.007 143.81 181.422C144.309 187.125 140.474 196.106 139.429 197.852C138.502 199.408 133.337 204.398 129.466 203.816Z" fill="white" stroke="#263238" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M137.849 188.253C137.754 188.942 137.363 189.441 136.983 189.441C136.603 189.441 136.389 188.788 136.484 188.099C136.579 187.41 136.971 186.911 137.351 186.911C137.731 186.911 137.956 187.564 137.849 188.253Z" fill="#263238"/>
        <path d="M125.761 186.519C125.666 187.208 125.892 187.802 126.272 187.861C126.652 187.921 127.031 187.41 127.138 186.673C127.245 185.937 127.008 185.378 126.628 185.331C126.248 185.283 125.868 185.782 125.761 186.519Z" fill="#263238"/>
        <path d="M130.843 187.921C130.95 190.297 128.92 190.689 129.3 191.485C129.579 192 130.047 192.388 130.606 192.566" fill="white"/>
        <path d="M130.843 187.921C130.95 190.297 128.92 190.689 129.3 191.485C129.579 192 130.047 192.388 130.606 192.566" stroke="#263238" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M126.699 194.526C129.155 195.628 131.864 196.039 134.536 195.714" stroke="#263238" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M127.246 194.752C127.246 194.752 127.246 198.316 129.62 198.91C131.995 199.504 133.978 195.762 133.978 195.762" stroke="#263238" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M123.576 183.347C124.251 182.545 125.132 181.944 126.125 181.609C127.117 181.274 128.182 181.217 129.205 181.446" stroke="#263238" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M140.52 185.853C140.108 184.887 139.438 184.054 138.584 183.444C137.729 182.834 136.723 182.472 135.676 182.396" stroke="#263238" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M138.004 175.566C136.084 177.703 133.473 179.095 130.63 179.498C124.301 180.603 120.81 186.067 120.893 188.36C119.65 185.157 119.277 181.681 119.812 178.286C121.071 169.887 128.908 167.475 134.596 168.069C140.283 168.663 143.038 174.366 143.038 174.366C143.038 174.366 147.325 177.692 146.695 181.898C146.102 185.913 145.128 186.032 144.617 186.935C143.905 188.194 144.831 188.717 142.338 190.796C142.338 190.796 143.323 185.176 142.1 180.995C141.833 179.854 141.33 178.782 140.625 177.847C139.919 176.912 139.027 176.135 138.004 175.566Z" fill="#263238"/>
        <path d="M139.571 174.14C139.571 174.14 137.338 182.658 124.657 184C124.657 184 133.206 182.444 137.148 174.639L139.571 174.14Z" fill="#263238"/>
        <path d="M152.205 218.001H108.14V222.052H152.205V218.001Z" fill="#263238"/>
        <path d="M176.131 215.993H165.824V222.42H176.131V215.993Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M176.12 218.143L179.433 216.86V221.553L176.12 220.163V218.143Z" fill="#92E3A9" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M202.017 221.161H197.991L196.412 223.026L195.142 221.161H190.819V215.375H202.017V221.161Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M189.621 104.131H202.207C204.37 104.128 206.443 103.267 207.972 101.737C209.501 100.207 210.362 98.1334 210.365 95.9697V72.7444" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.576 104.119H182.567" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M123.338 68.0397V95.9695C123.342 98.1331 124.202 100.207 125.731 101.737C127.26 103.267 129.333 104.128 131.496 104.131H170.36" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M142.171 37.2943H131.485C129.322 37.2975 127.249 38.1583 125.72 39.6882C124.191 41.2182 123.33 43.2922 123.327 45.4559V61.4701" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M156.254 37.2826H151.088" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M210.305 56.2904V45.4559C210.302 43.3025 209.45 41.2373 207.933 39.7091C206.417 38.1809 204.359 37.3131 202.207 37.2943H160.018" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M202.148 40.8466H131.497C128.959 40.8466 126.901 42.905 126.901 45.4441V95.9578C126.901 98.4969 128.959 100.555 131.497 100.555H202.148C204.686 100.555 206.743 98.4969 206.743 95.9578V45.4441C206.743 42.905 204.686 40.8466 202.148 40.8466Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M198.87 50.1366H134.75V91.2651H198.87V50.1366Z" fill="white"/>
        <path opacity="0.3" d="M197.889 50.1366H133.769V91.2651H197.889V50.1366Z" fill="#FECC00"/>
        <path d="M198.87 50.1366H134.75V91.2651H198.87V50.1366Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M198.87 50.1366H134.75V55.6845H198.87V50.1366Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M162.488 59.819H155.803V64.3334H162.488V59.819Z" fill="#263238"/>
        <path d="M170.158 59.819H163.473V64.3334H170.158V59.819Z" fill="#263238"/>
        <path d="M177.817 59.819H171.132V64.3334H177.817V59.819Z" fill="#263238"/>
        <path d="M185.488 59.819H178.803V64.3334H185.488V59.819Z" fill="#263238"/>
        <path d="M193.159 59.819H186.474V64.3334H193.159V59.819Z" fill="#263238"/>
        <path d="M147.159 65.1768H140.474V69.6912H147.159V65.1768Z" fill="#263238"/>
        <path d="M154.829 65.1768H148.144V69.6912H154.829V65.1768Z" fill="#263238"/>
        <path d="M162.488 65.1768H155.803V69.6912H162.488V65.1768Z" fill="#263238"/>
        <path d="M170.158 65.1768H163.473V69.6912H170.158V65.1768Z" fill="#263238"/>
        <path d="M177.817 65.1768H171.132V69.6912H177.817V65.1768Z" fill="#263238"/>
        <path d="M185.488 65.1768H178.803V69.6912H185.488V65.1768Z" fill="#263238"/>
        <path d="M193.159 65.1768H186.474V69.6912H193.159V65.1768Z" fill="#263238"/>
        <path d="M147.159 70.5466H140.474V75.061H147.159V70.5466Z" fill="#263238"/>
        <path d="M154.829 70.5466H148.144V75.061H154.829V70.5466Z" fill="#263238"/>
        <path d="M162.488 70.5466H155.803V75.061H162.488V70.5466Z" fill="#263238"/>
        <path d="M170.158 70.5466H163.473V75.061H170.158V70.5466Z" fill="#263238"/>
        <path d="M177.817 70.5466H171.132V75.061H177.817V70.5466Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M185.488 70.5466H178.803V75.061H185.488V70.5466Z" fill="#263238"/>
        <path d="M193.159 70.5466H186.474V75.061H193.159V70.5466Z" fill="#263238"/>
        <path d="M147.159 75.9044H140.474V80.4188H147.159V75.9044Z" fill="#263238"/>
        <path d="M154.829 75.9044H148.144V80.4188H154.829V75.9044Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M162.488 75.9044H155.803V80.4188H162.488V75.9044Z" fill="#263238"/>
        <path d="M170.158 75.9044H163.473V80.4188H170.158V75.9044Z" fill="#263238"/>
        <path d="M177.817 75.9044H171.132V80.4188H177.817V75.9044Z" fill="#263238"/>
        <path d="M185.488 75.9044H178.803V80.4188H185.488V75.9044Z" fill="#263238"/>
        <path d="M193.159 75.9044H186.474V80.4188H193.159V75.9044Z" fill="#263238"/>
        <path d="M147.159 81.274H140.474V85.7884H147.159V81.274Z" fill="#263238"/>
        <path d="M154.829 81.274H148.144V85.7884H154.829V81.274Z" fill="#263238"/>
        <path d="M162.488 81.274H155.803V85.7884H162.488V81.274Z" fill="#263238"/>
        <path d="M170.158 81.274H163.473V85.7884H170.158V81.274Z" fill="#263238"/>
        <path d="M328.893 107.517V99.9969" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M309.644 146.626H320.746C322.909 146.623 324.982 145.762 326.511 144.232C328.04 142.702 328.901 140.628 328.904 138.464V112.222" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M281.455 146.614H286.157" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M223.58 124.909V138.464C223.583 140.628 224.444 142.702 225.973 144.232C227.502 145.762 229.575 146.623 231.738 146.626H273.974" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M223.557 107.992V120.205" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M248.112 66.0439H231.714C229.552 66.0471 227.479 66.908 225.949 68.4379C224.42 69.9678 223.56 72.0419 223.557 74.2055V100.983" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M273.475 66.0322H265.021" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M328.904 92.4888V74.2055C328.901 72.0419 328.041 69.9678 326.511 68.4379C324.982 66.908 322.909 66.0471 320.747 66.0439H277.24" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M320.735 69.5961H231.714C229.177 69.5961 227.119 71.6545 227.119 74.1936V138.452C227.119 140.992 229.177 143.05 231.714 143.05H320.735C323.273 143.05 325.33 140.992 325.33 138.452V74.1936C325.33 71.6545 323.273 69.5961 320.735 69.5961Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M315.332 79.8248H237.117V132.833H315.332V79.8248Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M315.332 106.495L304.942 108.527L309.692 132.833H302.793L298.305 109.822L260.343 117.259L262.124 126.406L281.633 122.605L285.944 121.749L286.716 125.61L288.129 132.833H283.616L282.37 126.466L262.884 130.267L263.383 132.833H257.232L254.418 118.411L248.516 119.551L251.105 132.833H245.595L243.209 120.609L237.117 121.797V114.728L253.1 111.604L250.036 95.946L237.117 98.4645V93.8313L239.136 93.4274L237.117 83.1869V79.8248H240.763L243.256 92.6196L249.169 91.4672L246.89 79.8248H253.029L259.001 110.451L267.586 108.776L265.853 99.9257L264.76 94.2828L266.898 91.087L274.473 79.8248H280.885L270.234 95.6727L272.585 107.802L315.332 99.4268V106.495Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M297.379 90.2076C296.631 89.4594 295.743 88.8659 294.766 88.4609C293.789 88.056 292.742 87.8475 291.685 87.8475C290.627 87.8475 289.58 88.056 288.603 88.4609C287.626 88.8659 286.739 89.4594 285.991 90.2076C284.484 91.7178 283.638 93.7644 283.638 95.8982C283.638 98.0319 284.484 100.079 285.991 101.589L291.679 107.279L297.379 101.589C298.885 100.079 299.732 98.0319 299.732 95.8982C299.732 93.7644 298.885 91.7178 297.379 90.2076ZM294.03 98.2385C293.567 98.703 292.977 99.0196 292.334 99.1483C291.691 99.2769 291.024 99.2119 290.419 98.9613C289.813 98.7107 289.295 98.2859 288.93 97.7406C288.566 97.1953 288.371 96.5541 288.371 95.8982C288.371 95.2422 288.566 94.601 288.93 94.0557C289.295 93.5104 289.813 93.0856 290.419 92.835C291.024 92.5844 291.691 92.5194 292.334 92.648C292.977 92.7767 293.567 93.0933 294.03 93.5578C294.338 93.8647 294.583 94.2294 294.749 94.631C294.916 95.0326 295.002 95.4632 295.002 95.8982C295.002 96.3331 294.916 96.7637 294.749 97.1653C294.583 97.5669 294.338 97.9316 294.03 98.2385Z" fill="#263238"/>
        <path d="M126.319 356.89C115.525 351.715 103.597 349.357 91.6465 350.035L87.5499 338.88L81.0903 321.202C82.2777 311.176 104.031 280.264 104.031 280.264L106.703 289.459L126.319 356.89Z" fill="#757575"/>
        <path opacity="0.3" d="M126.319 356.89C115.525 351.715 103.597 349.356 91.6464 350.035L87.5498 338.88L92.062 339.759L86.5405 327.13L99.4834 319.337C99.4834 319.337 98.1297 300.032 106.679 289.459L126.319 356.89Z" fill="black" fillOpacity="0.7"/>
        <path d="M126.319 356.89C115.525 351.715 103.597 349.357 91.6465 350.035L87.5499 338.88L81.0903 321.202C82.2777 311.176 104.031 280.264 104.031 280.264L106.703 289.459L126.319 356.89Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M97.9397 356.89C97.1916 358.494 84.51 384.345 84.51 384.345L43.6392 315.251C43.6392 315.251 49.2556 314.764 55.9883 314.312C64.5971 313.742 74.9869 313.231 77.9317 313.849C79.8316 314.253 83.1564 319.979 86.5405 327.131C88.4285 331.122 90.3402 335.554 92.0501 339.759C95.6124 348.479 98.2603 356.201 97.9397 356.89Z" fill="#757575"/>
        <path opacity="0.3" d="M97.9397 356.89C97.1916 358.494 84.51 384.344 84.51 384.344L43.6392 315.25C43.6392 315.25 49.2556 314.763 55.9883 314.312L76.5306 336.718L92.062 339.771C95.6124 348.479 98.2603 356.201 97.9397 356.89Z" fill="black" fillOpacity="0.7"/>
        <path d="M97.9397 356.89C97.1916 358.494 84.51 384.345 84.51 384.345L43.6392 315.251C43.6392 315.251 49.2556 314.764 55.9883 314.312C64.5971 313.742 74.9869 313.231 77.9317 313.849C79.8316 314.253 83.1564 319.979 86.5405 327.131C88.4285 331.122 90.3402 335.554 92.0501 339.759C95.6124 348.479 98.2603 356.201 97.9397 356.89Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M100.956 413.035C65.1078 382.147 37.5241 341.066 20.366 296.86C18.8223 292.88 17.3499 288.877 15.9844 284.862C14.4012 280.22 12.9328 275.552 11.5791 270.855C20.4372 267.042 33.107 262.48 43.6393 262.23C47.3678 262.147 46.9522 278.601 46.0142 291.502C45.8717 293.51 45.7173 295.423 45.5748 297.181C45.0999 302.705 44.6486 306.685 44.6486 306.685C44.6486 306.685 91.1954 376.86 97.9399 391.425C100.374 396.593 101.063 404.73 100.956 413.035Z" fill="#757575"/>
        <path opacity="0.3" d="M100.956 413.035C65.1078 382.147 37.5241 341.066 20.3659 296.86C18.8223 292.881 17.3499 288.877 15.9844 284.862C23.8807 282.486 33.95 280.11 38.474 281.69C41.4307 282.711 43.9955 286.774 46.0497 291.502C45.9073 293.51 45.7529 295.423 45.6104 297.181C45.1354 302.705 44.6842 306.685 44.6842 306.685C44.6842 306.685 91.2309 376.86 97.9754 391.425C100.374 396.593 101.063 404.731 100.956 413.035Z" fill="black" fillOpacity="0.7"/>
        <path d="M100.956 413.035C65.1078 382.147 37.5241 341.066 20.366 296.86C18.8223 292.88 17.3499 288.877 15.9844 284.862C14.4012 280.22 12.9328 275.552 11.5791 270.855C20.4372 267.042 33.107 262.48 43.6393 262.23C47.3678 262.147 46.9522 278.601 46.0142 291.502C45.8717 293.51 45.7173 295.423 45.5748 297.181C45.0999 302.705 44.6486 306.685 44.6486 306.685C44.6486 306.685 91.1954 376.86 97.9399 391.425C100.374 396.593 101.063 404.73 100.956 413.035Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.3" d="M86.5524 327.13C88.4404 331.122 90.3521 335.553 92.062 339.759L86.5405 327.13H86.5524Z" fill="black" fillOpacity="0.7"/>
        <path d="M264.891 445.907V470.142C255.146 470.395 245.395 470.117 235.68 469.31C208.429 467.041 182.08 460.626 158.76 450.16C151.334 446.827 144.096 443.091 137.077 438.969C126.642 432.849 116.683 425.951 107.285 418.333C107.285 418.333 129.609 414.425 140.177 415.826C150.745 417.228 264.891 445.907 264.891 445.907Z" fill="#FECC00"/>
        <path opacity="0.3" d="M264.891 445.907V470.142C255.146 470.395 245.395 470.117 235.68 469.31C208.429 467.041 182.08 460.626 158.76 450.16C151.334 446.827 144.096 443.091 137.077 438.969C126.642 432.849 116.683 425.951 107.285 418.333C107.285 418.333 129.609 414.425 140.177 415.826C150.745 417.228 264.891 445.907 264.891 445.907Z" fill="black" fillOpacity="0.7"/>
        <path d="M264.891 445.907V470.142C255.146 470.395 245.395 470.117 235.68 469.31C208.429 467.041 182.081 460.626 158.76 450.16C151.334 446.827 144.096 443.091 137.077 438.969C126.642 432.849 116.683 425.951 107.285 418.333C107.285 418.333 129.609 414.425 140.177 415.826C150.745 417.228 264.891 445.907 264.891 445.907Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M454.949 364.232C449.77 373.679 443.933 382.75 437.482 391.377C427.583 404.428 415.876 416 402.714 425.746C381.673 441.451 357.07 452.951 330.947 460.305L330.686 460.376C320.122 463.337 309.382 465.627 298.53 467.231L323.383 423.144L402.144 283.472C402.144 283.472 410.646 262.539 422.33 255.779C423.45 255.115 424.646 254.589 425.893 254.211C427.663 253.703 429.463 253.306 431.283 253.023V253.023C441.709 251.372 455.768 252.002 463.142 253.023C464.829 253.134 466.484 253.536 468.034 254.211C469.637 255.566 476.322 293.439 454.949 364.232Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M155.898 280.264L126.212 271.092C126.212 271.092 99.8396 247.843 97.9873 245.907C96.1349 243.97 81.0903 249.483 81.0903 253.605C81.0903 257.727 86.8018 268.788 86.8018 268.788L97.9635 262.23C97.9635 262.23 101.763 283.424 106.145 287.938C110.526 292.453 124.93 291.3 124.93 291.3L158.747 306.649L155.898 280.264Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M116.998 291.3C109.577 289.958 105.064 288.568 104.008 280.264C102.951 271.96 86.6238 255.803 93.2021 249.221C93.7613 249.115 94.3401 249.195 94.8488 249.451C95.3575 249.706 95.7679 250.123 96.0163 250.635C98.4949 253.985 101.277 257.099 104.328 259.937C106.834 262.147 107.772 266.851 110.823 268.966" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M384.202 262.23C384.202 262.23 313.432 268.063 297.77 271.057C282.108 274.051 236.393 286.608 236.393 286.608C236.393 286.608 173.139 278.553 163.735 274.431C161.162 273.201 158.321 272.643 155.474 272.807C152.628 272.971 149.87 273.852 147.455 275.369L135.581 272.078C135.581 272.078 131.817 291.3 135.581 301.173L145.603 304.523C145.603 304.523 144.974 316.296 174.41 327.095C203.846 337.894 247.697 342.325 267.729 342.325C275.506 342.325 307.626 339.177 307.626 339.177L312.031 356.854L384.202 262.23Z" fill="#263238"/>
        <path d="M303.137 275.357C303.137 275.357 297.805 304.44 300.631 332.643" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M282.428 302.289C282.428 302.289 285.872 321.678 294.955 327.938" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M236.084 288.461C236.084 288.461 232.64 293.486 236.084 301.161C239.527 308.835 247.043 313.849 247.043 313.849" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M146.825 277.698C144.768 284.329 144.341 291.359 145.579 298.191" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M315.439 293.795C315.439 293.795 303.648 315.405 299.765 336.718" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M360.371 262.658C360.371 262.658 351.251 266.044 345.112 272.673C338.973 279.302 336.385 291.3 336.385 291.3L365.275 272.293L360.371 262.658Z" fill="#263238"/>
        <path d="M351.145 288.366C351.37 287.796 360.145 266.459 357.308 264.463C355.669 263.275 355.586 261.268 354.102 258.797C352.617 256.326 349.459 253.178 341.491 250.219L340.042 249.697L352.807 206.869L391.683 212.928C391.683 212.928 387.444 242.355 397.787 258.916L351.145 288.366Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M402.714 425.746C381.602 441.499 356.903 453.07 330.685 460.376C300.002 469.001 266.767 452.999 266.767 452.999L259.048 442.877C267.835 435.749 272.716 436.07 272.716 436.07C272.716 436.07 297.77 271.057 397.786 258.916C397.786 258.916 421.012 282.806 423.375 329.875C425.203 366.299 410.646 406.703 402.714 425.746Z" fill="#263238"/>
        <path d="M350.765 274.692C350.765 274.692 342.773 278.779 341.598 291.324C341.598 291.324 306.284 341.22 295.775 381.814V388.823L303.137 380.044C303.137 380.044 326.779 314.704 344.875 291.3L357.367 280.81L350.765 274.692Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M397.786 253.368C385.567 251.253 351.227 270.546 351.227 270.546L348.33 296.468L404.875 266.376C404.875 266.376 400.754 253.89 397.786 253.368Z" fill="#263238"/>
        <path d="M349.019 290.35L348.33 296.468C348.33 296.468 371.342 270.118 402.144 264.048" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.24 381.351L97.5241 257.253C97.2869 256.55 96.9113 255.903 96.4197 255.348C95.9281 254.793 95.3304 254.343 94.6618 254.023C93.9933 253.704 93.2675 253.521 92.5273 253.487C91.7872 253.453 91.0476 253.567 90.3524 253.824C89.6572 254.08 89.0204 254.473 88.4797 254.98C87.939 255.487 87.5052 256.097 87.2041 256.774C86.903 257.452 86.7406 258.183 86.7266 258.924C86.7125 259.665 86.8471 260.401 87.1223 261.09L132.85 385.188C133.102 385.871 133.485 386.497 133.979 387.031C134.473 387.565 135.067 387.997 135.727 388.301C136.388 388.606 137.102 388.777 137.828 388.805C138.555 388.834 139.28 388.719 139.962 388.467C140.645 388.215 141.27 387.831 141.804 387.337C142.338 386.843 142.77 386.249 143.074 385.588C143.378 384.927 143.55 384.213 143.578 383.486C143.606 382.759 143.491 382.033 143.24 381.351Z" fill="#999999" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M384.297 294.627C367.863 294.128 289.505 371.063 289.505 371.063C289.505 371.063 212.703 367.807 203.263 368.199C197.983 368.301 192.784 369.515 188.005 371.763L175.169 371.015C175.169 371.015 174.136 392.803 178.862 400.608L187.304 401.095C187.304 401.095 201.316 415.434 212.596 418.464C230.051 423.144 289.173 424.285 303.944 422.942C318.716 421.6 402.143 376.48 411.762 350.296" fill="#263238"/>
        <path d="M182.021 396.961L172.723 395.832C167.973 401.701 154.912 399.396 154.912 399.396C154.995 404.445 139.749 402.782 137.445 400.715C135.141 398.648 131.08 389.56 130.415 387.896C129.751 386.233 132.398 374.591 132.398 374.591C132.398 374.591 112.664 377.858 106.275 377.703C100.671 377.561 101.134 373.046 101.134 373.046C101.134 373.046 119.254 370.599 137.766 365.918C140.378 365.265 164.803 373.046 164.803 373.046L178.114 374.769C178.114 374.769 182.496 382.836 182.021 396.961Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M132.696 393.088C132.696 393.088 133.301 387.421 138.407 376.456" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M132.304 374.579C132.304 374.579 133.955 371.217 133.23 369.827" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M137.35 400.703C136.222 397.674 142.219 385.164 143.133 383.608" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M154.817 399.385C154.817 399.385 149.023 396.07 148.156 389.881" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M149.675 394.181C148.449 396.124 146.629 397.619 144.486 398.446" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M190.203 376.48C190.203 376.48 194.157 390.95 192.577 397.757" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M293.424 372.559C293.424 372.559 320.284 364.731 330.875 368.188" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M343.189 347.873C343.189 347.873 389.498 356.617 402.144 341.577" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M320.794 341.576C320.794 341.576 331.006 332.262 342.334 331.752C342.334 331.752 375.32 289.946 397.786 298.179" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M368.92 298.191C368.92 298.191 348.259 315.203 347.428 325.8" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M404.875 366.061C404.875 366.061 384.855 389.156 364.337 393.088C364.337 393.088 321.507 426.162 295.775 423.346" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M357.248 398.208C357.248 398.208 376.757 403.923 388.905 394.11" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M347.428 408.306C348.508 411.134 362.983 424.938 375.237 427.896" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M123.529 422.574L118.031 425.711L117.39 426.079C115.431 424.653 113.483 423.204 111.572 421.719L94.0098 380.59L87.8115 366.061H101.431C107.108 375.45 112.009 385.288 116.084 395.476C119.676 404.166 122.176 413.268 123.529 422.574Z" fill="#999999"/>
        <path opacity="0.3" d="M118.031 425.71C118.138 426.055 118.233 426.411 118.328 426.756L117.39 426.079C115.431 424.653 113.483 423.204 111.572 421.719L94.0098 380.59H99.4838L99.8993 380.935C101.669 382.372 108.9 388.348 116.084 395.476C119.676 404.166 122.176 413.268 123.529 422.574L118.031 425.71Z" fill="black" fillOpacity="0.7"/>
        <path d="M123.529 422.574L118.031 425.711L117.39 426.079C115.431 424.653 113.483 423.204 111.572 421.719L94.0098 380.59L87.8115 366.061H101.431C107.108 375.45 112.009 385.288 116.084 395.476C119.676 404.166 122.176 413.268 123.529 422.574V422.574Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M118.328 426.756L117.39 426.079C115.431 424.653 113.484 423.204 111.572 421.719C108.01 418.927 104.447 416.04 100.956 413.035C75.1537 390.772 53.614 363.246 37.1564 333.082C30.7846 321.382 25.1723 309.283 20.3545 296.86L45.599 297.181L50.218 297.241C50.218 297.241 72.5533 329.495 92.1932 366.061C94.8292 370.956 97.4059 375.945 99.8876 380.935C107.582 396.438 114.232 412.037 118.019 425.711C118.138 426.055 118.233 426.412 118.328 426.756Z" fill="#999999"/>
        <path opacity="0.3" d="M118.328 426.756C112.343 422.455 106.552 417.882 100.956 413.035C75.1531 390.772 53.6133 363.246 37.1558 333.082C47.2132 333.356 59.3723 333.629 62.8158 333.439C68.9904 333.118 93.9974 380.59 93.9974 380.59H99.4714L99.887 380.935C107.795 396.795 114.587 412.833 118.328 426.756Z" fill="black" fillOpacity="0.7"/>
        <path d="M118.328 426.756L117.39 426.079C115.431 424.653 113.484 423.204 111.572 421.719C108.01 418.927 104.447 416.04 100.956 413.035C75.1537 390.772 53.614 363.246 37.1564 333.082C30.7846 321.382 25.1723 309.283 20.3545 296.86L45.599 297.181L50.218 297.241C50.218 297.241 72.5533 329.495 92.1932 366.061C94.8292 370.956 97.4059 375.945 99.8876 380.935C107.582 396.438 114.232 412.037 118.019 425.711C118.138 426.055 118.233 426.412 118.328 426.756Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M359.29 245.23C359.29 245.23 359.147 253.736 354.101 258.797C352.545 256.219 349.458 253.178 341.49 250.22C341.918 248.675 342.333 247.63 342.333 247.63L359.29 245.23Z" fill="#263238"/>
        <path d="M395.328 223.252C397.341 217.952 398.152 212.272 397.703 206.62C394.841 166.43 385.342 157.069 369.692 159.682C311.259 169.424 328.584 199.124 326.339 208.96C324.095 218.797 315.819 219.557 316.947 224.048C317.398 225.818 324.285 225.996 324.285 225.996L324.701 235.275L327.325 235.904C327.325 235.904 329.438 257.015 334.817 259.462C341.621 262.563 361.962 250.279 361.926 242.652" fill="white"/>
        <path d="M395.328 223.252C397.341 217.952 398.152 212.272 397.703 206.62C394.841 166.43 385.342 157.069 369.692 159.682C311.259 169.424 328.584 199.124 326.339 208.96C324.095 218.797 315.819 219.557 316.947 224.048C317.398 225.818 324.285 225.996 324.285 225.996L324.701 235.275L327.325 235.904C327.325 235.904 329.438 257.015 334.817 259.462C341.621 262.563 361.962 250.279 361.926 242.652" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M330.875 194.514C330.875 194.514 339.081 192.839 343.189 199.409" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M334.295 206.715C334.592 208.663 335.661 210.113 336.67 209.958C337.679 209.804 338.261 208.093 337.952 206.157C337.644 204.22 336.587 202.759 335.577 202.913C334.568 203.068 334.01 204.731 334.295 206.715Z" fill="#263238"/>
        <path d="M327.301 235.88C327.301 235.88 335.328 235.88 340.042 234.039" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M365.239 216.219L360.774 218.761C360.774 218.761 360.584 207.713 356.025 202.486C351.465 197.258 348.687 184.773 351.275 180.995C351.275 180.995 338.13 176.017 327.527 182.599C327.527 182.599 314.465 176.314 318.253 156.463C320.812 158.33 323.84 159.448 326.997 159.694C330.155 159.939 333.319 159.302 336.136 157.853C352.985 149.537 373.551 150.131 381.376 158.34C381.376 158.34 402.144 154.075 406.478 187.267C409.174 207.879 398.724 226.685 392.229 233.314C392.229 233.314 388.334 231.722 386.85 224.06C385.425 216.801 387.135 205.159 377.505 203.092C358.768 199.029 365.239 216.219 365.239 216.219Z" fill="#263238"/>
        <path d="M367.329 203.246C367.329 203.246 373.408 193.885 378.811 193.659C384.214 193.433 387.847 212.299 382.896 219.486C377.944 226.673 373.064 229.216 370.82 227.053C370.155 226.334 369.665 225.471 369.387 224.532C369.109 223.592 369.051 222.602 369.217 221.636" fill="white"/>
        <path d="M367.329 203.246C367.329 203.246 373.408 193.885 378.811 193.659C384.214 193.433 387.847 212.299 382.896 219.486C377.944 226.673 373.064 229.216 370.82 227.053C370.155 226.334 369.665 225.471 369.387 224.532C369.109 223.592 369.051 222.602 369.217 221.636" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M338.273 234.609C338.273 234.609 334.889 239.361 331.825 239.361C328.762 239.361 328.607 236.985 328.607 236.985" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

}