import React from 'react';
import {AnswerType} from "./answerTypes.config";
import {
    analyticsImage1,
    analyticsImage2,
    communicationImage1, communicationImage2, entrepreneurImage1, entrepreneurImage2, intellectImage1, intellectImage2,
    presenterImage1,
    presenterImage2
} from "./constants/images";
import {Text} from "./shared/Text";

export const feedbacks = {
    [AnswerType.Presenter]: [
        {
            id: '1',
            image: presenterImage1,
            name: 'Саша Урошников',
            minText: ()=> (<Text>Пришёл в компанию на должность менеджера по работе с клиентами, когда открылся офис в Челябинске. С первого дня работы команда помогала мне адаптироваться и изучать </Text>),
            text: ()=>(<Text> Пришёл в компанию на должность менеджера по работе с клиентами, когда открылся офис в Челябинске. С первого дня работы команда помогала мне адаптироваться и изучать продукт. <br />
            За время работы получил знания о маркетинге, а также стал экспертом в рынке региона. <b> Сейчас выступаю от лица Яндекса на профильных форумах и конференциях, что позволяет компании лучше узнать рынок, а мне — получить знакомства и опыт работы с разными клиентами.</b>
            <br/> В компании отлично выстроена система обучения, поэтому я также решил развиваться в наставничестве и развитии людей и помогаю адаптироваться сотрудникам. <br/>
                Больше всего я ценю, что у нас <b> насыщенная внутренняя жизнь и замечательная команда. </b> </Text>)
        },
        {
            id: '2',
            image: presenterImage2,
            name: 'Женя Божко',
            minText: ()=>(<Text> Мой путь в Яндексе начался внезапно и стал развиваться очень быстро. Совсем недавно я занимался финансированием крупных компаний — а ровно <b> через год уже  выступал  на</b> </Text>),
            text: ()=>(<Text> Мой путь в Яндексе начался внезапно и стал развиваться очень быстро. Совсем недавно я занимался финансированием крупных компаний — а ровно <b> через год уже  выступал  на региональной конференции по маркетингу.</b> <br/>
                Сейчас я руководитель команды в Красноярске и Владивостоке, мы помогаем развитию бизнеса клиентов по всей Восточной Сибири и Дальнему Востоку.<br/>
                Каждый день для меня полон интересных задач, многие из которых — это вызов самому себе, и от этого захватывает дух.
            </Text>),
        }
    ],
    [AnswerType.Analytics]: [
        {
            id: '1',
            image: analyticsImage1,
            name: 'Даша Гончарова',
            minText: ()=>(<Text>Каждый день я выполняю <b> множество самых разных задач </b>(от помощи клиентам до обучения новичков), так что <b>скучать не приходится.</b> Сегодня могу провести весь день на встречах,</Text>),
            text: ()=>(<Text>Каждый день я выполняю <b> множество самых разных задач </b>(от помощи клиентам до обучения новичков), так что <b>скучать не приходится.</b> Сегодня могу провести весь день на встречах, а завтра полностью погрузиться в <b> аналитические отчёты.</b> У нас в команде <b> простор для реализации себя и любых идей,</b> которые помогают другим. Самое ценное, что дала мне компания, — <b>рост уровня моей экспертности </b> и отсутствие страха, что я чего-то не смогу. Тут как в видеоигре: каждый переход на новый уровень ещё более сложный, но интересный :)</Text>)
        },
        {
            id: '2',
            image: analyticsImage2,
            name: 'Дима Карманов',
            minText: ()=>(<Text>Для меня работа в Яндексе — это команда, в которой каждый отвечает за результат, ответственность делится на всех в равной степени. </Text>),
            text: ()=>(<Text>Для меня работа в Яндексе — это команда, в которой каждый отвечает за результат, ответственность делится на всех в равной степени. <br/> Вместе мы помогаем бизнесу развиваться, найти точки роста в сложных ситуациях, внедрять стратегии развития, ставить цели и быстро достигать их. <br/> Вместе мы помогаем бизнесу развиваться, найти точки роста в сложных ситуациях, внедрять стратегии развития, ставить цели и быстро достигать их. </Text>)
        }
    ],
    [AnswerType.Negotiator]: [
        {
            id: '1',
            image: communicationImage1,
            name: 'Егор Громов',
            minText: ()=>(<Text>Я всегда мечтал попасть в компанию мирового уровня, где <b> нет границ для роста. </b> Поэтому работа здесь — реализация моей мечты. <br/> С первого дня я стал <b>частью крутой команды</b> </Text>),
            text: ()=>(<Text>Я всегда мечтал попасть в компанию мирового уровня, где <b> нет границ для роста. </b> Поэтому работа здесь — реализация моей мечты. <br/> С первого дня я стал <b>частью крутой команды, где собраны сильнейшие специалисты, есть командный дух, общие цели и задачи, поддержка и помощь с любыми вопросами.</b> <br/> Благодаря этому ты быстро учишься и растёшь как специалист и человек. Даже в период удалённой работы чувствуешь, что ты в большой и дружной компании. Плюс мне приятно помогать клиентам и делать наши сервисы лучше для них, ведь я и сам — один из клиентов. </Text>)
        },
        {
            id: '2',
            image: communicationImage2,
            name: 'Настя Ионина',
            minText: ()=>(<Text>Мой путь к работе мечты был тернистым: пробовала себя в разных сферах, но всё не оправдывало ожиданий. Зато теперь я могу точно сказать: нашла своё место, нашла себя. </Text>),
            text: ()=>(<Text>Мой путь к работе мечты был тернистым: пробовала себя в разных сферах, но всё не оправдывало ожиданий. Зато теперь я могу точно сказать: нашла своё место, нашла себя. Больше всего в работе нравится то, что  <b>могу помогать другим, слышать положительную обратную связь от клиентов и бесконечно расти и развиваться.</b> Я ценю, что в Яндексе ты всегда в движении: всё вокруг стремительно меняется, требует быстрой адаптации. Благодаря выходу из зоны комфорта достигаешь профессиональных целей. <b>Команда всегда готова тебя поддержать и направить.</b> Даже работая из дома, чувствуешь себя частью чего-то большего. </Text>)
           }
    ],
    [AnswerType.Intellectual]: [
        {
            id: '1',
            image: intellectImage1,
            name: 'Люба Смирнова',
            minText: ()=>(<Text>Работа с региональными рекламодателями Директа для меня — это бесконечный <b>поток интересных задач! Погружение в детали маркетинга разных бизнесов, постоянное</b> </Text>),
            text: ()=>(<Text>Работа с региональными рекламодателями Директа для меня — это бесконечный <b>поток интересных задач! Погружение в детали маркетинга разных бизнесов, постоянное повышение экспертных знаний, поиск нестандартных решений...</b> Ни в одной компании я ещё не встречала такой <b>дружественной атмосферы</b> в команде и коллег, которые всегда готовы помочь в любой задаче. Здесь <b>комфортно работать</b>, расти профессионально и покорять новые горизонты. </Text>)
        },
        {
            id: '2',
            image: intellectImage2,
            name: 'Кристина Лифанова',
            minText: ()=>(<Text>Я всегда хотела работать в компании,<b> которая меняет жизни людей к лучшему,</b> делает большое и классное дело на всех уровнях: <b>меняет рынок, внедряет новые технологии, задаёт стандарты </b>  </Text>),
            text: ()=>(<Text>Я всегда хотела работать в компании,<b> которая меняет жизни людей к лучшему,</b> делает большое и классное дело на всех уровнях: <b>меняет рынок, внедряет новые технологии, задаёт стандарты сервиса, заботится о сотрудниках.</b> <br/>Мне очень нравится работать в динамичной, молодой, энергичной команде, с людьми, у которых можно учиться каждый день; <b>решать сложные задачи клиентов, становиться экспертом в разных сферах бизнеса, помогать клиентам зарабатывать больше денег.</b> У нас прекрасная атмосфера, бешеный ритм, крутые эксперты и очень классные люди. Если ты ищешь dream team — ты знаешь, что делать :) </Text>),
        }
    ],
    [AnswerType.Entrepreneur]: [
        {
            id: '1',
            image: entrepreneurImage1,
            name: 'Виолетта Иванова',
            minText: ()=>(<Text>Возможность самореализоваться есть далеко не в каждой компании. Устроившись в Яндекс, ты не только получаешь интересную работу, которую выполняешь с удовольствием, но ещё и </Text>),
            text: ()=>(<Text>Возможность самореализоваться есть далеко не в каждой компании. Устроившись в Яндекс, ты не только получаешь интересную работу, которую выполняешь с удовольствием, но ещё и попадаешь в дружный коллектив, который разделяет твои интересы, мечты и планы. Если работаешь добросовестно и стараешься — <b>твои усилия всегда заметят и поощрят.</b> Руководство узнаёт твои желания и потребности, пытается их удовлетворить. И самое главное — оно выполняет обещания. Это действительно радует.<b> Ты чувствуешь каждый день, что компания развивается, и развиваешься вместе с ней.</b>Здесь очень разнообразные вакансии и задачи — я уверена, каждый найдёт то, что ему по душе.</Text>)
        },
        {
            id: '2',
            image: entrepreneurImage2,
            name: 'Илья Давыдов',
            minText: ()=>(<Text> До Яндекса, честно говоря, я скитался. Работал в подозрительных серых компаниях, брался за любую работу продажником с высокой зарплатой, даже ОМОН однажды прибегал в одну из контор :) </Text>),
            text: ()=>(<Text> До Яндекса, честно говоря, я скитался. Работал в подозрительных серых компаниях, брался за любую работу продажником с высокой зарплатой, даже ОМОН однажды прибегал в одну из контор :) <br/> <br/> Прекрасным днём мне позвонили по старой вакансии на hh.ru и пригласили в продажи. Так и начался мой путь от менеджера до руководителя. Вначале было столько эмоций! Я не мог поверить, что это происходит со мной: огромный офис, где каждый друг за друга, телефонные звонки без перегородок («Волк с Уолл-Стрит»), продажи налево и направо, кофе-брейки, переговорки, планёрки, обучалки-тренинги... <b>Я хотел много зарабатывать менеджером по продажам — получите, распишитесь.</b> После желал больше компетенций и быть наставником — пожалуйста :) И через 14 месяцев меня взяли руководителем исходящего канала продаж. Но на этом история не закончилась, хотел чего-то ещё... Может, в новом направлении себя испытать? «Шагай в клиентский сервис руководителем, будешь показывать класс». И так я оказался тут :)
                <br/>
                Яндекс — компания возможностей? Это мягко говоря. <b>Яндекс — компания заслуг, где ты вкладываешь усилия и получаешь развитие + отдачу.</b>
            </Text>),

        }
    ]

}