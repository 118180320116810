export const Question7Img =(props) => {
    return  <svg {...props} viewBox="0 0 365 326" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.7" d="M320.564 197.231C323.991 181.392 322.499 164.885 316.29 149.917C307.822 128.983 289.631 114.111 269.241 105.432C239.614 92.8248 210.536 92.583 186.451 67.8742C179.023 60.2512 172.791 51.5214 165.188 44.0523C142.819 22.0629 110.06 24.709 85.6086 42.1686C34.6231 78.6197 16.2496 150.752 19.087 210.388C20.3628 237.141 33.1568 262.063 53.3706 279.654C99.1212 319.367 176.809 312.69 229.334 291.961C268.508 276.466 311.333 240.403 320.564 197.231Z" fill="#F7F7F7" fillOpacity="0.5"/>
        <path d="M31.1996 62.1243H15.916C15.916 62.1243 17.44 58.3403 29.3292 59.6045C29.3292 59.6045 35.2521 52.8157 43.8853 52.8157C44.0516 51.2126 44.75 49.7114 45.869 48.5516C46.988 47.3917 48.4633 46.6401 50.0594 46.4165C55.9563 45.2735 57.0993 50.5296 57.0993 50.5296C58.7102 49.8358 60.4457 49.478 62.1996 49.478C63.9535 49.478 65.689 49.8358 67.2999 50.5296C73.1449 52.8416 71.9586 59.5872 71.9586 59.5872C71.9586 59.5872 88.8267 59.033 91.7276 62.1243H83.1116" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M294.12 148.301H227.444C227.444 148.301 230.207 141.486 251.612 143.764C251.612 143.764 262.272 131.537 277.824 131.537C277.824 131.537 278.343 122.012 288.96 119.994C299.576 117.976 301.637 127.424 301.637 127.424C301.637 127.424 309.482 123.267 320.012 127.424C330.541 131.58 328.411 143.729 328.411 143.729C328.411 143.729 358.796 142.733 364 148.301H348.596" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M251.162 230.798H304.339C304.339 230.798 302.148 225.368 285.072 227.178C285.072 227.178 276.56 217.428 264.16 217.428C264.16 217.428 263.744 209.851 255.284 208.223C246.824 206.595 245.17 214.146 245.17 214.146C245.17 214.146 238.91 210.829 230.519 214.146C222.128 217.462 223.816 227.135 223.816 227.135C223.816 227.135 199.571 226.338 195.423 230.78H211.52" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M59.2649 205.937H3.52539C7.69048 201.495 31.919 202.291 31.919 202.291C31.919 202.291 30.1872 192.602 38.6213 189.302C47.0553 186.003 53.2727 189.302 53.2727 189.302C53.2727 189.302 54.9179 181.752 63.3867 183.379C71.8554 185.007 72.2624 192.584 72.2624 192.584C84.671 192.584 93.1744 202.335 93.1744 202.335C110.25 200.525 112.45 205.954 112.45 205.954H106.224" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M130.998 122.696H108.64C108.64 122.696 109.947 119.466 120.087 120.548C120.087 120.548 125.135 114.755 132.504 114.755C132.649 113.387 133.246 112.106 134.202 111.116C135.157 110.127 136.416 109.484 137.778 109.291C142.809 108.321 143.787 112.807 143.787 112.807C145.161 112.212 146.642 111.905 148.139 111.905C149.635 111.905 151.116 112.212 152.49 112.807C157.478 114.772 156.465 120.531 156.465 120.531C156.465 120.531 170.865 120.054 173.333 122.696H167.973" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M33.8672 62.1243H36.413" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50.502 62.081H73.8472" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M136.271 122.531H139.467" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M129.414 14.5418H107.056C107.056 14.5418 108.363 11.3119 118.503 12.3943C118.503 12.3943 123.552 6.60128 130.921 6.60128C131.065 5.23318 131.663 3.95257 132.618 2.96269C133.573 1.9728 134.832 1.33036 136.194 1.13731C141.225 0.167478 142.203 4.65296 142.203 4.65296C143.571 4.06449 145.044 3.76099 146.533 3.76099C148.022 3.76099 149.495 4.06449 150.863 4.65296C155.85 6.6186 154.837 12.377 154.837 12.377C154.837 12.377 169.238 11.9094 171.705 14.5418H166.293" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M134.687 14.3772H137.882" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M101.565 205.937H99.2012" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M68.9111 205.937H77.0595" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M215.85 230.832H223.695" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M300.502 148.301H306.693" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M73.9927 127.553C73.9927 127.553 56.2673 138.23 51.4008 138.715C46.5343 139.2 34.9916 138.715 34.9916 138.715C34.9916 138.715 32.6622 139.425 31.1036 140.04C29.3717 140.733 25.908 141.624 26.6701 139.676C27.4321 137.728 30.9997 134.559 33.0606 134.585C33.5715 134.585 34.7924 135.606 34.7924 135.606L47.279 134.585L54.4229 131.233C54.4229 131.233 52.8123 130.922 50.5522 130.506H50.5003C45.8156 129.64 38.3773 128.281 36.7407 128.211C34.2555 128.107 25.0854 118.149 24.1242 116.954C23.1631 115.759 18.2706 117.171 17.0583 116.686C15.846 116.201 16.6427 115.058 16.6427 115.058C18.7688 113.644 21.1519 112.661 23.6566 112.166C26.9385 111.534 26.6701 114.097 26.6701 114.097C26.6701 114.097 36.1952 121.093 38.1349 121.093C40.0745 121.093 57.774 120.531 60.1813 119.561C62.2162 118.695 70.6849 125.016 73.2481 126.973C73.7243 127.337 73.9927 127.553 73.9927 127.553Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.1418 116.963C23.172 115.794 18.2882 117.179 17.0759 116.695C15.8636 116.21 16.6603 115.067 16.6603 115.067C18.7805 113.652 21.1575 112.666 23.6569 112.166C26.9388 111.534 26.6703 114.097 26.6703 114.097C26.6703 114.097 23.9513 114.642 24.1418 116.963Z" fill="#263238"/>
        <path d="M73.9931 127.553C73.9931 127.553 56.2677 138.23 51.4012 138.715C46.5347 139.2 34.9919 138.715 34.9919 138.715C34.9919 138.715 32.6626 139.425 31.1039 140.04C29.3721 140.733 25.9084 141.625 26.6704 139.676C27.4324 137.728 31 134.559 33.0609 134.585C33.5718 134.585 34.7928 135.606 34.7928 135.606L47.2794 134.585L54.4232 131.234C54.4232 131.234 52.8126 130.922 50.5526 130.506H50.6305L58.3372 130.904C58.3372 130.904 54.5012 129.735 54.8735 129.051C55.2459 128.367 60.0691 130.879 66.5808 128.419C68.6845 127.554 70.9237 127.065 73.1965 126.973C73.7247 127.337 73.9931 127.553 73.9931 127.553Z" fill="#263238"/>
        <path d="M78.1328 110.85L86.1945 121.622L99.0102 131.086C99.0102 131.086 103.296 132.221 103.833 133.32C103.937 133.519 104.699 136.005 104.292 135.918C102.422 135.563 101.565 134.706 101.054 134.974C99.4085 135.84 101.634 137.425 101.054 137.572C99.4431 137.858 97.4428 133.156 97.4428 133.156C97.4428 133.156 86.4976 126.332 84.1336 124.955C81.7696 123.579 73.4395 117.162 73.4395 117.162C73.4395 117.162 75.7255 112.062 78.1328 110.85Z" fill="white" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M89.6396 119.266L91.181 122.462C91.181 122.462 92.0902 121.596 92.0902 122.462C92.0902 123.328 86.2625 128.904 84.2103 128.402C82.158 127.9 81.8203 126.739 81.8203 126.739C81.8203 126.739 84.7298 120.574 85.9248 119.89C87.1198 119.206 89.6396 119.266 89.6396 119.266Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M58.3545 113.785C58.3545 113.785 77.6819 123.83 77.0671 127.198C76.4523 130.567 75.9327 130.913 75.9327 130.913C75.9327 130.913 82.9554 128.558 83.9339 124.055C84.9123 119.552 72.7202 107.672 58.3545 113.785Z" fill="#263238"/>
        <path d="M65.5234 103.117C70.8353 103.586 75.9073 105.542 80.1575 108.763C85.812 113.092 89.3277 117.846 88.8947 119.89C88.2106 123.146 76.9883 127.345 69.8011 129.224C62.6139 131.104 54.838 125.761 54.9245 122.358C55.0111 118.955 59.003 119.292 59.003 119.292C59.003 119.292 62.6139 105.853 65.5234 103.117Z" fill="#263238"/>
        <path d="M76.0101 108.252C74.987 107.919 73.8844 107.92 72.8618 108.255C71.8392 108.589 70.9494 109.24 70.321 110.114C68.1808 113.221 64.9828 115.444 61.3241 116.366C53.4442 118.262 41.7109 115.751 41.7109 115.751C42.5389 112.938 43.5691 110.189 44.7936 107.525C46.9325 102.762 52.0674 99.5928 57.5487 98.8655C59.878 98.5451 70.8319 102.727 70.8319 102.727C87.5962 97.9996 90.5144 116.409 90.5144 116.409H88.9297C89.4609 117.408 89.6981 118.537 89.6138 119.665L84.7733 123.995C81.1537 132.108 72.8582 132.654 71.9316 131.268C71.9316 131.268 82.6518 131.156 85.4227 114.184C85.8384 111.603 79.8375 109.473 76.0101 108.252Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M65.5245 103.117L52.3019 100.649L38.1441 93.3408C38.1441 93.3408 35.2259 90.007 34.0049 90.007C33.7798 90.007 31.2253 90.44 31.4591 90.743C32.6108 92.2498 33.7538 92.6567 33.7538 93.2283C33.7538 95.0813 31.2946 93.7998 31.4505 94.3453C31.9007 95.9126 37.0097 95.6269 37.0097 95.6269C37.0097 95.6269 47.9723 102.424 50.2497 103.94C52.527 105.455 61.9483 110.088 61.9483 110.088C61.9483 110.088 65.5072 105.81 65.5245 103.117Z" fill="white" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M67.1432 108.59C65.2035 111.724 60.0599 112.053 60.0599 112.053L55.8429 108.044L53.8945 107.507C54.6998 106.053 54.925 100.026 54.925 100.026C54.925 100.026 64.6233 102.234 66.1127 101.887C67.6021 101.541 68.061 105.542 68.061 105.542" fill="#FECC00"/>
        <path d="M67.1431 108.59C65.2035 111.724 60.0599 112.053 60.0599 112.053L55.8429 108.044L53.8945 107.507C54.6998 106.053 54.925 100.026 54.925 100.026C54.925 100.026 64.6233 102.234 66.1127 101.887C67.6021 101.541 68.061 105.542 68.061 105.542" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M69.8018 108.59C69.8018 108.59 71.7674 105.239 69.8018 103.117C69.8018 103.117 74.8068 100.337 81.1367 103.619C87.4666 106.901 89.6834 111.3 90.5146 116.366C90.5146 116.366 86.8951 115.041 85.3191 119.492" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M90.2638 102.424C91.6926 102.831 93.5197 105.022 92.039 105.68C90.5582 106.338 91.3116 107.274 91.4155 107.62C91.5194 107.966 91.58 108.763 90.2638 108.875C88.9476 108.988 88.8437 108.417 88.3242 109.213C87.8046 110.01 88.6532 109.534 87.3803 110.079C86.1074 110.625 86.3325 110.677 85.354 110.711C84.3755 110.746 83.2499 111.317 82.6524 109.724C82.0549 108.131 87.7007 101.68 90.2638 102.424Z" fill="#263238"/>
        <path d="M79.3786 97.9647C72.9968 97.6097 71.4987 108.157 76.5297 114.582C81.5607 121.007 96.5066 98.9432 79.3786 97.9647Z" fill="white" stroke="#263238" strokeMiterlimit="10"/>
        <path d="M77.4744 104.373C78.1758 103.299 80.6784 106.537 80.9381 105.741C81.1979 104.944 82.4189 106.676 82.9384 106.866C83.7581 106.988 84.5954 106.855 85.337 106.485C86.0211 106.139 88.5323 107.672 89.3289 106.936C89.5533 106.77 89.7189 106.537 89.8015 106.271C89.8841 106.004 89.8793 105.718 89.7879 105.455C89.7879 105.455 91.6756 105.169 92.0133 104.373C92.351 103.576 90.8703 104.736 91.615 103.126C92.3597 101.515 94.2127 100.528 92.8619 99.8611C91.511 99.1943 90.5326 98.4323 91.0435 97.1767C91.5543 95.9211 91.2166 94.0334 90.0736 94.0334C88.9306 94.0334 86.0211 94.8387 86.1337 93.4099C86.2462 91.9812 81.5702 91.5222 80.8775 92.4401C80.1848 93.358 78.2797 91.9465 77.2839 91.9638C76.2881 91.9812 73.4046 94.0334 73.8202 95.3496C74.2359 96.6658 73.2574 96.7004 72.8504 97.9474C72.6817 98.4119 72.6198 98.9084 72.6693 99.4001C72.7187 99.8918 72.8783 100.366 73.1362 100.788C73.4825 101.099 71.4043 100.476 72.1663 102.242C72.9283 104.009 72.963 104.797 73.2487 104.909C73.5345 105.022 73.6557 102.926 74.8507 102.467C76.0457 102.009 75.6473 105.438 75.6473 105.438C75.6473 105.438 76.6258 105.68 77.4744 104.373Z" fill="#263238"/>
        <path d="M77.0498 106.814C77.5151 106.655 78.0091 106.596 78.4988 106.643C78.9886 106.689 79.4628 106.839 79.89 107.083" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M83.4141 107.733C83.6349 107.62 83.8763 107.553 84.1236 107.536C84.371 107.518 84.6193 107.551 84.8537 107.632C85.0881 107.713 85.3038 107.84 85.4879 108.006C85.672 108.172 85.8208 108.374 85.9252 108.598" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M81.8213 109.784C81.6481 111.612 82.3928 112.928 80.4531 112.529" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M78.782 109.274C78.704 109.603 78.4702 109.819 78.2538 109.776C78.0373 109.733 77.9161 109.421 77.9853 109.1C78.0546 108.78 78.2971 108.555 78.5135 108.607C78.73 108.659 78.782 108.953 78.782 109.274Z" fill="#263238"/>
        <path d="M84.3233 110.183C84.2541 110.503 84.0203 110.72 83.7951 110.677C83.57 110.633 83.4574 110.322 83.5354 110.001C83.6133 109.681 83.8471 109.456 84.0636 109.508C84.2801 109.56 84.4013 109.854 84.3233 110.183Z" fill="#263238"/>
        <path d="M306.415 105.975C305.893 106.161 305.332 106.205 304.787 106.105C302.657 105.836 299.661 104.252 299.081 101.983C299.042 101.827 298.993 101.673 298.933 101.524C298.755 100.837 298.398 100.21 297.899 99.7053C297.401 99.2005 296.778 98.8362 296.093 98.649C296.093 98.649 294.69 97.506 294.69 96.7526C294.69 95.9993 295.617 94.2761 295.392 92.9426C295.167 91.6091 295.833 89.2364 296.881 88.6649C297.695 88.2727 298.605 88.1268 299.501 88.2453C300.396 88.3637 301.237 88.7414 301.921 89.3317C303.653 90.847 305.255 96.2591 306.874 99.03C308.493 101.801 308.13 105.239 306.415 105.975Z" fill="#FECC00"/>
        <path opacity="0.3" d="M304.787 106.104C302.657 105.836 299.661 104.251 299.081 101.983C299.042 101.827 298.993 101.673 298.934 101.524C300.345 101.411 302.822 100.658 303.211 97.1941C303.229 97.1768 307.446 102.381 304.787 106.104Z" fill="black" fillOpacity="0.7"/>
        <path d="M306.415 105.975C305.893 106.161 305.332 106.205 304.787 106.105C302.657 105.836 299.661 104.252 299.081 101.983C299.042 101.827 298.993 101.673 298.933 101.524C298.755 100.837 298.398 100.21 297.899 99.7053C297.401 99.2005 296.778 98.8362 296.093 98.649C296.093 98.649 294.69 97.506 294.69 96.7526C294.69 95.9993 295.617 94.2761 295.392 92.9426C295.167 91.6091 295.833 89.2364 296.881 88.6649C297.695 88.2727 298.605 88.1268 299.501 88.2453C300.396 88.3637 301.237 88.7414 301.921 89.3317C303.653 90.847 305.255 96.2591 306.874 99.03C308.493 101.801 308.13 105.239 306.415 105.975Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M303.229 96.2243C303.229 96.2243 303.021 100.658 301.237 100.866" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M302.017 145.79L293.53 123.103C293.453 122.877 293.375 122.652 293.314 122.418C291.998 117.716 293.747 111.387 294.864 107.447C295.212 106.15 295.469 104.83 295.635 103.498C295.916 101.311 296.086 99.1121 296.146 96.9084C299.064 94.7522 301.341 98.3544 301.341 98.3544C301.341 98.3544 300.579 101.221 302.675 114.019C304.77 126.817 307.654 142.898 302.017 145.79Z" fill="white"/>
        <path opacity="0.4" d="M299.8 126.194L293.349 122.419C292.033 117.717 293.782 111.387 294.899 107.447C295.247 106.15 295.505 104.831 295.67 103.498C300.909 110.166 299.8 126.194 299.8 126.194Z" fill="#D3D3D3"/>
        <path d="M302.017 145.79L293.53 123.103C293.453 122.877 293.375 122.652 293.314 122.418C291.998 117.716 293.747 111.387 294.864 107.447C295.212 106.15 295.469 104.83 295.635 103.498C295.916 101.311 296.086 99.1121 296.146 96.9084C299.064 94.7522 301.341 98.3544 301.341 98.3544C301.341 98.3544 300.579 101.221 302.675 114.019C304.77 126.817 307.654 142.898 302.017 145.79Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M285.009 134.334C272.254 139.659 233.625 114.348 232.673 114.149C232.673 114.149 263.5 108.702 268.08 107.628C268.275 107.596 268.464 107.537 268.643 107.455C268.782 107.594 270.202 108.685 272.228 110.356C279.563 116.426 294.794 130.238 285.009 134.334Z" fill="white"/>
        <path opacity="0.4" d="M285.009 134.334C272.254 139.659 233.625 114.348 232.673 114.149C232.673 114.149 263.5 108.702 268.08 107.629C269.454 108.489 270.837 109.398 272.228 110.356C279.563 116.426 294.794 130.238 285.009 134.334Z" fill="#D3D3D3"/>
        <path d="M285.009 134.334C272.254 139.659 233.625 114.348 232.673 114.149C232.673 114.149 263.5 108.702 268.08 107.628C268.275 107.596 268.464 107.537 268.643 107.455C268.782 107.594 270.202 108.685 272.228 110.356C279.563 116.426 294.794 130.238 285.009 134.334Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M304.735 136.065C305.671 143.478 302.796 147.634 296.899 146.508L296.232 146.37C285.902 144.162 284.577 138.1 271.345 127.32C266.765 123.57 262.279 120.574 258.452 117.898C252.39 113.629 247.914 110.157 247.325 105.775C246.372 98.5968 237.531 88.1884 254.036 81.9711C266.436 77.3125 287.625 101.238 296.899 116.487C298.544 119.174 300.019 121.962 301.315 124.834" fill="white"/>
        <path opacity="0.4" d="M296.232 146.37C285.902 144.162 284.577 138.1 271.346 127.32C266.765 123.57 262.28 120.574 258.452 117.898L263.83 116.34L267.12 117.543C272.972 122.99 278.419 128.856 283.417 135.096C286.869 139.694 291.231 143.532 296.232 146.37Z" fill="#ACACAC"/>
        <path d="M304.735 136.065C305.671 143.478 302.796 147.634 296.899 146.508L296.232 146.37C285.902 144.162 284.577 138.1 271.345 127.32C266.765 123.57 262.279 120.574 258.452 117.898C252.39 113.629 247.914 110.157 247.325 105.775C246.372 98.5968 237.531 88.1884 254.036 81.9711C266.436 77.3125 287.625 101.238 296.899 116.487C298.544 119.174 300.019 121.962 301.315 124.834" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M267.119 82.4474C273.016 83.5211 286.17 95.6527 286.715 96.9083C287.261 98.1639 288.811 100.84 288.811 100.84H290.335C290.335 100.84 287.668 111.88 283.295 116.063C278.922 120.245 260.911 120.816 259.161 120.254C257.822 119.705 256.587 118.932 255.507 117.968L242.38 114.149L267.119 82.4474Z" fill="#263238"/>
        <path d="M190.26 105.542C188.884 108.373 182.077 110.616 181.601 111.975C181.097 113.03 180.316 113.928 179.341 114.573C179.041 114.244 178.874 113.815 178.874 113.37C178.874 112.924 179.041 112.495 179.341 112.166C180.441 110.928 180.562 108.278 180.562 108.278C179.626 107.847 178.662 107.477 177.679 107.17C177.349 107.092 176.648 106.962 175.825 106.823C174.18 106.537 172.05 106.2 171.496 106.052C170.841 105.817 170.248 105.435 169.764 104.935C168.656 105.022 166.958 102.71 167.972 102.866C170.684 103.184 173.425 103.184 176.137 102.866C169.089 101.134 169.755 99.4022 169.755 99.4022C171.963 100.554 178.224 100.918 178.224 100.918C178.224 100.918 174.449 99.2896 173.791 98.4324C173.65 98.2243 173.565 97.9838 173.544 97.7337C173.523 97.4837 173.566 97.2323 173.669 97.0036C175.426 97.9759 177.292 98.7347 179.229 99.2637C182.612 99.954 186.097 99.9805 189.49 99.3416L190.26 105.542Z" fill="white"/>
        <path opacity="0.4" d="M190.26 105.542C188.883 108.373 182.077 110.616 181.601 111.975C181.096 113.03 180.315 113.928 179.341 114.573C179.04 114.244 178.874 113.815 178.874 113.37C178.874 112.924 179.04 112.495 179.341 112.166C180.441 110.928 180.562 108.278 180.562 108.278C179.625 107.847 178.662 107.477 177.678 107.17C177.349 107.092 176.648 106.962 175.825 106.823C179.185 106.763 183.402 106.537 184.597 105.758C184.597 105.758 182.908 102.442 183.229 99.8005C183.263 99.3303 183.396 98.8725 183.62 98.4576C183.844 98.0426 184.153 97.6799 184.528 97.3933C185.567 96.6313 187.403 97.6704 189.368 99.3676H189.412L190.26 105.542Z" fill="#FECC00"/>
        <path d="M190.26 105.542C188.884 108.373 182.077 110.616 181.601 111.975C181.097 113.03 180.316 113.928 179.341 114.573C179.041 114.244 178.874 113.815 178.874 113.37C178.874 112.924 179.041 112.495 179.341 112.166C180.441 110.928 180.562 108.278 180.562 108.278C179.626 107.847 178.662 107.477 177.679 107.17C177.349 107.092 176.648 106.962 175.825 106.823C174.18 106.537 172.05 106.2 171.496 106.052C170.841 105.817 170.248 105.435 169.764 104.935C168.656 105.022 166.958 102.71 167.972 102.866C170.684 103.184 173.425 103.184 176.137 102.866C169.089 101.134 169.755 99.4022 169.755 99.4022C171.963 100.554 178.224 100.918 178.224 100.918C178.224 100.918 174.449 99.2896 173.791 98.4324C173.65 98.2243 173.565 97.9838 173.544 97.7337C173.523 97.4837 173.566 97.2323 173.669 97.0036C175.426 97.9759 177.292 98.7347 179.229 99.2637C182.612 99.954 186.097 99.9805 189.49 99.3416V99.3416L190.26 105.542Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M169.85 104.91C169.85 104.91 169.105 104.78 172.448 104.667C173.913 104.644 175.375 104.542 176.829 104.364" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M321.777 65.5014C323.397 65.1204 328.341 61.6914 330.151 59.5006C331.375 57.9427 332.41 56.2452 333.234 54.4436C333.45 54.5713 333.63 54.752 333.757 54.9686C333.885 55.1853 333.955 55.4307 333.961 55.6819C333.918 56.7643 331.77 60.2626 331.77 60.2626C331.77 60.2626 336.671 56.3573 337.832 54.1492C337.832 54.1492 339.399 55.2143 334.645 60.6955C337.046 59.397 339.287 57.8218 341.321 56.0022C342.066 55.2922 342.005 58.1584 341.053 58.7299C340.948 59.4121 340.687 60.0609 340.291 60.6263C339.728 61.293 336.541 64.3584 335.875 65.1204C335.241 65.9473 334.662 66.8151 334.143 67.7182C334.143 67.7182 335.753 69.8137 337.373 70.1947C337.81 70.2809 338.199 70.5279 338.464 70.8869C338.728 71.2459 338.849 71.691 338.801 72.1344C337.639 72.1743 336.487 71.8991 335.468 71.3377C334.333 70.4718 327.484 72.576 324.721 71.052" fill="white"/>
        <path d="M321.777 65.5014C323.397 65.1204 328.341 61.6914 330.151 59.5006C331.375 57.9427 332.41 56.2452 333.234 54.4436C333.45 54.5713 333.63 54.752 333.757 54.9686C333.885 55.1853 333.955 55.4307 333.961 55.6819C333.918 56.7643 331.77 60.2626 331.77 60.2626C331.77 60.2626 336.671 56.3573 337.832 54.1492C337.832 54.1492 339.399 55.2143 334.645 60.6955C337.046 59.397 339.287 57.8218 341.321 56.0022C342.066 55.2922 342.005 58.1584 341.053 58.7299C340.948 59.4121 340.687 60.0609 340.291 60.6263C339.728 61.293 336.541 64.3584 335.875 65.1204C335.241 65.9473 334.662 66.8151 334.143 67.7182C334.143 67.7182 335.753 69.8137 337.373 70.1947C337.81 70.2809 338.199 70.5279 338.464 70.8869C338.728 71.2459 338.849 71.691 338.801 72.1344C337.639 72.1743 336.487 71.8991 335.468 71.3377C334.333 70.4718 327.484 72.576 324.721 71.052" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M326.677 70.9222C326.677 70.9222 304.787 82.4477 300.042 82.4477C293.668 82.4477 274.731 93.6094 274.731 93.6094L267.119 75.2605C267.119 75.2605 283.684 72.6022 288.239 72.732C293.374 72.8706 297.184 68.134 305.557 67.3373C316.97 66.2463 323.638 65.2158 323.638 65.2158" fill="white"/>
        <path opacity="0.4" d="M326.677 70.9221C326.677 70.9221 304.787 82.4475 300.042 82.4475C293.668 82.4475 274.731 93.6093 274.731 93.6093L267.119 75.2604C267.119 75.2604 272.904 74.3338 278.575 73.5978C281.974 76.1382 284.517 79.6543 285.866 83.6771C285.866 83.6771 294.526 80.3606 295.92 79.4168C297.314 78.4729 295.92 74.9919 295.92 74.9919C297.24 74.7541 298.592 74.7541 299.912 74.9919C302.198 75.3816 300.596 78.9925 300.596 78.9925C309.552 77.2451 318.292 74.5274 326.66 70.8875L326.677 70.9221Z" fill="#D3D3D3"/>
        <path d="M326.677 70.9223C326.677 70.9223 304.787 82.4477 300.042 82.4477C293.668 82.4477 274.731 93.6094 274.731 93.6094L267.119 75.2605C267.119 75.2605 283.684 72.6022 288.239 72.732C293.374 72.8706 297.184 68.134 305.557 67.3373C316.97 66.2463 323.638 65.2158 323.638 65.2158" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M279.866 93.6092L277.268 94.6136L266.15 98.9433C266.15 98.9433 261.768 108.269 257.776 112.651C253.785 117.032 238.977 119.093 231.34 117.587C223.702 116.08 212.125 122.341 206.756 117.587C201.388 112.833 188.338 106.927 183.203 98.9346C181.636 96.4927 182.164 93.9296 183.662 91.5483C187.083 86.1189 195.508 81.6248 195.508 81.6248C206.455 76.7812 218.114 73.7429 230.032 72.6279C239.22 71.8483 248.473 72.4319 257.491 74.3597C260.418 74.9485 262.149 75.3382 262.548 74.9918C264.089 73.6497 267.016 74.0566 267.587 74.1259H267.691C267.691 74.1259 267.345 71.9438 268.843 71.5281C269.839 71.2337 271.649 71.7187 274.904 74.1259C283.139 80.2307 279.866 93.6092 279.866 93.6092Z" fill="#FECC00"/>
        <path d="M279.866 93.6092L277.268 94.6136L266.15 98.9433C266.15 98.9433 261.768 108.269 257.776 112.651C253.785 117.032 238.977 119.093 231.34 117.587C223.702 116.08 212.125 122.341 206.756 117.587C201.388 112.833 188.338 106.927 183.203 98.9346C181.636 96.4927 182.164 93.9296 183.662 91.5483C187.083 86.1189 195.508 81.6248 195.508 81.6248C206.455 76.7812 218.114 73.7429 230.032 72.6279C239.22 71.8483 248.473 72.4319 257.491 74.3597C260.418 74.9485 262.149 75.3382 262.548 74.9918C264.089 73.6497 267.016 74.0566 267.587 74.1259H267.691C267.691 74.1259 267.345 71.9438 268.843 71.5281C269.839 71.2337 271.649 71.7187 274.904 74.1259C283.139 80.2307 279.866 93.6092 279.866 93.6092Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M240.76 101.965C240.027 102.549 239.261 103.089 238.466 103.585C237.09 104.47 235.624 105.207 234.093 105.784C226.395 108.65 212.722 107.698 204.045 103.758C203.176 103.372 202.332 102.932 201.517 102.442C200.398 101.784 199.366 100.99 198.443 100.078C191.741 93.2281 194.633 87.5996 198.443 84.9846C199.483 84.2866 200.578 83.6727 201.716 83.1488C202.582 82.7158 203.604 82.2829 204.634 81.9278C214.22 78.3862 228.646 76.7323 238.509 77.8926C239.609 78.0139 240.648 78.1697 241.626 78.3776C245.471 79.1482 248.242 80.4817 249.117 82.4474C251.87 88.5695 247.35 96.7871 240.76 101.965Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M240.761 101.965C240.028 102.549 239.262 103.089 238.467 103.585C235.369 95.3008 235.369 86.1768 238.467 77.8928C239.566 78.0141 240.605 78.1699 241.584 78.3777C237.246 87.1669 239.454 97.4454 240.761 101.965Z" fill="#263238"/>
        <path d="M204.046 103.758C203.177 103.372 202.332 102.932 201.518 102.442C201.01 96.0177 201.076 89.5611 201.717 83.1489C202.583 82.716 203.604 82.283 204.635 81.928C202.539 90.3707 203.414 99.4196 204.046 103.758Z" fill="#263238"/>
        <path d="M341.035 58.73C341.035 58.73 341.572 58.1931 338.81 60.0029C337.599 60.8295 336.345 61.5897 335.052 62.2803" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M267.691 74.1691C269.701 74.5725 271.565 75.5087 273.088 76.8798C274.612 78.2509 275.739 80.0064 276.351 81.9624C278.515 88.7773 277.494 90.8122 277.494 90.8122" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M262.548 74.9921C262.548 74.9921 260.227 80.4041 260.435 84.396C260.643 88.3879 263.829 93.3496 263.829 93.3496" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M250.814 92.4836C250.814 92.4836 240.882 112.4 223.521 110.434" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M193.126 93.3494C193.126 93.3494 192.346 102.875 207.647 108.382" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M266.167 98.9347C266.167 98.9347 273.614 88.3964 272.748 84.1707" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <g opacity="0.2">
            <path opacity="0.2" d="M288.188 101.524C288.188 101.524 282.785 114.054 277.078 114.019" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.2" d="M275.598 97.6358C275.598 97.6358 277.425 109.984 271.484 114.547" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path d="M217.641 98.9348C215.97 95.4018 212.03 93.9211 208.289 93.3496C205.506 92.9542 202.683 92.9134 199.89 93.2283L200.219 92.5529L206.756 78.9926C206.756 78.9926 223.832 74.3859 227.158 82.4563" fill="white"/>
        <path d="M217.641 98.9348C215.97 95.4018 212.03 93.9211 208.289 93.3496C205.506 92.9542 202.683 92.9134 199.89 93.2284L200.219 92.5529L206.756 78.9926C206.756 78.9926 223.832 74.3859 227.158 82.4563" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M208.289 93.3496C205.506 92.9542 202.683 92.9134 199.89 93.2284L200.219 92.5529C201.725 90.9337 204.401 89.3317 208.679 88.2233C208.961 89.0602 209.072 89.9454 209.005 90.8261C208.938 91.7068 208.695 92.565 208.289 93.3496Z" fill="#263238"/>
        <path d="M184.615 71.0954C184.615 71.0954 182.762 75.9099 176.138 71.4764C169.513 67.0428 173.964 63.8476 173.964 63.8476C173.964 63.8476 171.799 66.5666 177.506 68.8613C177.506 68.8613 173.176 67.1294 174.908 61.3364C176.64 55.5434 184.615 71.0954 184.615 71.0954Z" fill="#263238"/>
        <path d="M209.822 87.4266C209.094 91.6783 201.041 98.2333 195.196 97.5925C192.477 97.2894 181.783 89.9637 182.112 79.8584C182.346 72.5674 176.449 65.5274 180.848 54.2184C186.191 40.4849 205.31 34.3975 216.654 46.8235C223.131 53.9154 220.94 69.7011 214.731 81.8068" fill="white"/>
        <path d="M209.822 87.4266C209.094 91.6783 201.041 98.2333 195.196 97.5925C192.477 97.2894 181.783 89.9637 182.112 79.8584C182.346 72.5674 176.449 65.5274 180.848 54.2184C186.191 40.4849 205.31 34.3975 216.654 46.8235C223.131 53.9154 220.94 69.7011 214.731 81.8068" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M193.127 66.7743C193.127 66.7743 198.123 64.8346 201.18 68.4195" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M196.546 75.0006C196.546 75.9444 196.087 76.7324 195.533 76.7324C194.979 76.7324 194.537 75.9531 194.546 75.0006C194.555 74.0481 195.014 73.2687 195.559 73.2687C196.105 73.2687 196.546 74.0654 196.546 75.0006Z" fill="#263238"/>
        <path d="M185.611 74.178C185.611 75.1305 185.152 75.9099 184.598 75.9099C184.043 75.9099 183.61 75.1392 183.61 74.178C183.61 73.2168 184.078 72.4462 184.632 72.4462C185.186 72.4462 185.619 73.1995 185.611 74.178Z" fill="#263238"/>
        <path d="M184.684 67.3024C184.684 67.3024 181.108 64.7046 179.714 67.5448" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M188.061 75.2604C187.922 76.5333 184.433 79.59 185.662 80.8802C186.333 81.6187 187.192 82.1606 188.148 82.4475" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M187.022 85.712C190.55 86.2964 194.164 86.0473 197.578 84.9846" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M197.56 84.9846C197.56 84.9846 196.114 89.7126 192.529 89.6779C188.944 89.6433 188.485 85.9111 188.485 85.9111" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M204.99 62.0204C204.99 62.0204 207.034 69.9176 210.437 72.1084C212.437 73.3592 213.994 75.2069 214.888 77.3905L215.927 82.8545C215.927 82.8545 224.11 88.3791 228.682 84.5864C233.254 80.7936 235.817 81.2612 235.817 81.2612C235.817 81.2612 235.436 79.1657 231.487 79.8325C231.487 79.8325 240.147 80.6984 238.813 72.9051C237.48 65.1118 209.458 50.2265 204.99 62.0204Z" fill="#263238"/>
        <path d="M195.508 55.6472C195.508 55.6472 190.814 54.6947 185.402 61.0678C181.861 65.2416 176.682 64.7827 173.686 61.7866C170.69 58.7905 170.976 49.3692 166.837 49.0835C166.837 49.0835 171.115 46.7974 174.111 50.0793C174.111 50.0793 176.111 48.079 173.972 45.5159C171.833 42.9527 175.011 35.9907 182.198 37.2376C182.198 37.2376 178.665 38.1036 181.168 40.0779C181.168 40.0779 185.818 34.5273 191.672 35.0988C197.525 35.6703 212.194 28.8815 215.917 40.658C215.917 40.658 221.485 38.3633 224.767 40.0779C228.049 41.7924 230.482 51.2223 234.05 53.5083C237.617 55.7944 241.895 51.6466 244.891 52.3653C247.887 53.084 250.173 56.0715 250.173 56.0715C250.173 56.0715 247.316 54.9284 245.324 56.3572C245.324 56.3572 251.169 60.6435 254.027 58.0891C254.027 58.0891 252.745 70.7922 241.609 72.2209C241.609 72.2209 246.181 74.2126 252.745 72.2209C247.783 75.7293 242.057 78.0086 236.041 78.8712C226.516 80.0662 228.482 73.5545 213.164 70.7922C208.384 69.9262 208.306 62.9988 208.306 62.9988C207.879 64.7809 207.75 66.6214 207.925 68.4455C208.211 71.5109 210.783 74.1693 212.921 74.1952C212.921 74.1952 206.747 75.2863 204.072 71.6581C199.491 65.4408 201.933 57.1019 201.933 57.1019C201.933 57.1019 197.144 54.7206 191.377 60.9553C186.693 66.0123 183.099 64.3757 183.099 64.3757C185.031 64.1264 186.815 63.2071 188.139 61.7779C190.096 59.5005 192.286 55.6472 195.508 55.6472Z" fill="#263238"/>
        <path d="M211.077 75.2603C211.077 75.2603 214.584 69.1988 217.251 71.7014C219.918 74.2039 220.213 75.8145 219.451 77.4338C218.505 79.3798 217.321 81.2007 215.926 82.8544C215.71 83.1098 215.441 83.315 215.137 83.4557C214.834 83.5964 214.503 83.6694 214.168 83.6694C213.834 83.6694 213.503 83.5964 213.2 83.4557C212.896 83.315 212.627 83.1098 212.411 82.8544" fill="white"/>
        <path d="M211.077 75.2603C211.077 75.2603 214.584 69.1988 217.251 71.7014C219.918 74.2039 220.213 75.8145 219.451 77.4338C218.505 79.3798 217.321 81.2007 215.926 82.8544C215.71 83.1098 215.441 83.315 215.137 83.4557C214.834 83.5964 214.503 83.6694 214.169 83.6694C213.834 83.6694 213.503 83.5964 213.2 83.4557C212.896 83.315 212.627 83.1098 212.411 82.8544" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M167.616 177.275C168.541 176.52 169.618 175.975 170.773 175.676C171.929 175.377 173.135 175.331 174.31 175.543C174.826 175.582 175.328 175.736 175.777 175.995C176.226 176.253 176.611 176.609 176.905 177.036C177.198 177.463 177.392 177.95 177.472 178.462C177.552 178.974 177.516 179.498 177.367 179.994C177.367 179.994 184.632 186.159 190.26 198.178C195.889 210.197 199.655 231.663 199.655 231.663L111.279 227.334C111.279 227.334 125.03 205.383 127.914 201.269C130.797 197.156 136.573 192.056 136.573 192.056C136.874 191.25 137.381 190.538 138.043 189.989C138.706 189.441 139.501 189.076 140.348 188.93C143.431 188.246 145.856 188.584 146.531 189.614C146.715 189.995 146.811 190.412 146.811 190.835C146.811 191.258 146.715 191.675 146.531 192.056C146.531 192.056 146.765 208.95 148.358 209.219C149.951 209.487 167.573 207.773 167.573 207.773C167.573 207.773 169.582 198.585 170.041 196.845L168.837 182.756C168.837 182.756 164.941 178.712 167.616 177.275Z" fill="#263238"/>
        <path d="M167.106 178.591C167.106 178.591 164.673 174.841 165.244 173.941C165.816 173.04 171.184 171.438 172.613 171.793C174.042 172.148 175.54 178.054 174.077 178.591C172.613 179.128 167.106 178.591 167.106 178.591Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.622 190.445C145.622 190.445 147.605 187.371 147.138 186.644C146.67 185.917 142.28 184.592 141.076 184.912C139.873 185.233 138.678 190.03 139.881 190.471C141.085 190.913 145.622 190.445 145.622 190.445Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.4" d="M172.596 195.199C172.596 195.199 174.648 187.822 178.19 187.744" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.4" d="M178.19 192.913C178.19 192.913 186.096 194.758 188.581 201.261" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.4" d="M129.083 203.564C129.083 203.564 135.37 197.026 141.994 198.169" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.4" d="M139.916 190.445C139.916 190.445 143.743 189.034 145.224 192.913" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.4" d="M169.895 179.899C169.895 179.899 172.284 176.582 175.393 178.833" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M301.385 299.933L294.458 310.367C294.658 310.925 294.89 311.471 295.151 312.003C296.077 313.943 297.965 317.069 300.996 318.489C305.213 320.472 306.269 322.871 308.72 322.109C308.925 322.052 309.125 321.977 309.317 321.884C312.209 320.516 310.019 318.229 310.962 316.402C311.906 314.575 316.089 314.047 317.613 312.592C319.137 311.138 318.236 309.129 319.033 307.258C319.83 305.388 317.786 303.656 316.704 303.214C315.621 302.773 315.751 299.283 313.439 299.751C311.127 300.218 310.746 298.14 310.252 297.638C309.759 297.136 302.554 299.318 301.385 299.933Z" fill="white"/>
        <path opacity="0.4" d="M295.142 312.004C296.068 313.943 297.956 317.069 300.987 318.489C305.204 320.472 306.26 322.871 308.711 322.109C306.641 320.793 304.944 318.931 304.433 317.546C303.714 315.658 299.056 313.155 295.142 312.004Z" fill="#B7B7B7"/>
        <path d="M301.385 299.933L294.458 310.367C294.658 310.925 294.89 311.471 295.151 312.003C296.077 313.943 297.965 317.069 300.996 318.489C305.213 320.472 306.269 322.871 308.72 322.109C308.925 322.052 309.125 321.977 309.317 321.884C312.209 320.516 310.019 318.229 310.962 316.402C311.906 314.575 316.089 314.047 317.613 312.592C319.137 311.138 318.236 309.129 319.033 307.258C319.83 305.388 317.786 303.656 316.704 303.214C315.621 302.773 315.751 299.283 313.439 299.751C311.127 300.218 310.746 298.14 310.252 297.638C309.759 297.136 302.554 299.318 301.385 299.933Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M303.921 301.179L285.287 292.087C280.628 289.81 270.834 285.359 265.925 282.917C264.502 282.157 262.941 281.691 261.335 281.549C259.43 281.428 258.166 281.947 256.763 281.774C254.486 281.488 237.574 280.952 237.574 280.952L234.647 291.672L228.716 313.45L234.95 311.363L258.893 303.336C258.893 303.336 268.115 307.405 277.233 307.302C284.464 307.215 293.426 311.12 296.838 312.748C297.704 313.173 298.241 313.441 298.241 313.441" fill="white"/>
        <path opacity="0.4" d="M237.583 280.952L234.647 291.672L234.951 311.363L258.893 303.336C258.893 303.336 268.115 307.405 277.234 307.302C284.464 307.215 293.426 311.12 296.838 312.748C291.227 308.479 281.303 304.842 277.303 304.721C271.042 304.539 263.82 298.383 263.82 298.383C260.79 301.716 255.352 301.535 255.352 301.535C262.903 292.927 262.582 285.688 261.335 281.549C259.43 281.428 258.166 281.947 256.763 281.774C254.494 281.488 237.583 280.952 237.583 280.952Z" fill="#B7B7B7"/>
        <path d="M303.921 301.179L285.287 292.087C280.628 289.81 270.834 285.359 265.925 282.917C264.502 282.157 262.941 281.691 261.335 281.549C259.43 281.428 258.166 281.947 256.763 281.774C254.486 281.488 237.574 280.952 237.574 280.952L234.647 291.672L228.716 313.45L234.95 311.363L258.893 303.336C258.893 303.336 268.115 307.405 277.233 307.302C284.464 307.215 293.426 311.12 296.838 312.748C297.704 313.173 298.241 313.441 298.241 313.441" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.6307 216.016V228.546C21.1569 228.901 20.6625 229.228 20.15 229.525C18.3056 230.624 14.9545 232.183 11.6899 231.689C7.08324 231.005 4.87513 232.417 3.25586 230.434C3.1188 230.27 2.99433 230.097 2.88351 229.914C1.2296 227.169 4.32095 226.45 4.54609 224.433C4.77123 222.415 1.57597 219.636 1.08239 217.583C0.588818 215.531 2.45921 214.38 2.81424 212.388C3.16927 210.396 5.84497 210.076 6.98799 210.31C8.13101 210.543 9.9581 207.565 11.6207 209.245C13.2832 210.924 14.7553 209.392 15.4394 209.245C16.1235 209.097 20.964 214.856 21.6307 216.016Z" fill="white"/>
        <path opacity="0.4" d="M20.1158 229.525C18.2714 230.624 14.9203 232.183 11.6558 231.689C7.04906 231.005 4.84096 232.417 3.22168 230.434C5.67224 230.477 8.11414 229.871 9.28314 228.996C10.9717 227.793 16.2192 228.312 20.1158 229.525Z" fill="#CDCDCD"/>
        <path d="M21.6307 216.016V228.546C21.1569 228.901 20.6625 229.228 20.15 229.525C18.3056 230.624 14.9545 232.183 11.6899 231.689C7.08324 231.005 4.87514 232.417 3.25586 230.434C3.1188 230.27 2.99433 230.097 2.88351 229.914C1.2296 227.169 4.32095 226.45 4.54609 224.433C4.77123 222.415 1.57597 219.636 1.08239 217.583C0.588818 215.531 2.45921 214.38 2.81424 212.388C3.16927 210.396 5.84497 210.076 6.98799 210.31C8.13101 210.543 9.9581 207.565 11.6207 209.245C13.2832 210.924 14.7553 209.392 15.4394 209.245C16.1235 209.097 20.964 214.856 21.6307 216.016Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.7819 215.644L39.3389 218.38C44.4738 219.064 55.0987 220.779 60.5367 221.463C62.142 221.614 63.7003 222.088 65.1174 222.857C66.7713 223.81 67.542 224.944 68.7976 225.576C70.8585 226.598 85.2501 235.508 85.2501 235.508L81.7431 246.055L74.6339 267.478L70.5987 262.282L55.1073 242.366C55.1073 242.366 45.1752 240.634 37.633 235.517C31.6581 231.447 22.0464 229.741 18.2883 229.204C17.3098 229.066 16.7383 229.005 16.7383 229.005" fill="white"/>
        <path opacity="0.4" d="M85.2421 235.508L81.7351 246.055L70.5907 262.291L55.0993 242.375C55.0993 242.375 45.1672 240.643 37.625 235.525C31.6501 231.455 22.0384 229.75 18.2803 229.213C25.3202 228.762 35.5987 231.23 38.9932 233.343C44.3099 236.651 53.7138 235.525 53.7138 235.525C54.3979 239.976 59.0219 242.834 59.0219 242.834C57.4979 231.481 61.7756 225.636 65.0834 222.874C66.7373 223.827 67.508 224.961 68.7636 225.593C70.8591 226.598 85.2421 235.508 85.2421 235.508Z" fill="#B7B7B7"/>
        <path d="M18.7819 215.644L39.3389 218.38C44.4738 219.064 55.0987 220.779 60.5367 221.463C62.142 221.614 63.7003 222.088 65.1174 222.857C66.7713 223.81 67.542 224.944 68.7976 225.576C70.8585 226.598 85.2501 235.508 85.2501 235.508L81.7431 246.055L74.6339 267.478L70.5987 262.282L55.1073 242.366C55.1073 242.366 45.1752 240.634 37.633 235.517C31.6581 231.447 22.0464 229.741 18.2883 229.204C17.3098 229.066 16.7383 229.005 16.7383 229.005" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M99.5031 237.387L92.6883 289.342L92.3593 291.88C92.3593 291.88 75.3179 278.311 64.6497 260.776C64.0696 259.814 63.55 258.879 63.0997 257.953C55.3064 241.994 66.9271 231.447 77.2922 228.252C88.2461 224.874 83.7693 231.672 83.7693 231.672C91.6232 230.607 99.5031 237.387 99.5031 237.387Z" fill="#757575"/>
        <path opacity="0.3" d="M92.6881 289.36L92.359 291.897C92.359 291.897 75.3177 278.328 64.6495 260.793C64.0693 259.832 63.5498 258.896 63.0995 257.97C62.2855 253.727 61.8526 249.873 62.5366 248.445C64.3637 244.557 70.0702 242.842 70.0702 242.842C70.0702 242.842 72.261 267.616 77.6037 274.977C81.3126 279.886 85.5589 284.364 90.2635 288.329L92.6881 289.36Z" fill="black" fillOpacity="0.7"/>
        <path d="M99.5031 237.387L92.6883 289.342L92.3593 291.88C92.3593 291.88 75.3179 278.311 64.6497 260.776C64.0696 259.814 63.55 258.879 63.0997 257.953C55.3064 241.994 66.9271 231.447 77.2922 228.252C88.2461 224.874 83.7693 231.672 83.7693 231.672C91.6232 230.607 99.5031 237.387 99.5031 237.387Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M252.72 304.868C249.581 307.525 245.955 309.543 242.043 310.809C228.292 315.441 194.547 319.121 194.547 319.121L196.478 315.415L219.295 271.565C219.295 271.565 246.693 273.981 252.113 279.246C252.113 279.246 251.429 271.158 257.577 282.891C261.864 291.074 259.309 299.153 252.72 304.868Z" fill="#757575"/>
        <path opacity="0.3" d="M252.72 304.868C249.581 307.525 245.955 309.543 242.043 310.808C228.292 315.441 194.547 319.121 194.547 319.121L196.478 315.415C208.298 313.502 225.486 309.769 229.729 306.098C235.791 300.902 234.059 306.643 234.059 306.643C234.059 306.643 244.788 298.201 247.299 291.801C249.81 285.402 251.923 284.519 252.694 288.736C253.206 291.554 253.097 294.451 252.373 297.223C251.649 299.994 250.328 302.574 248.503 304.782C246.338 307.379 249.126 306.886 252.72 304.868Z" fill="black" fillOpacity="0.7"/>
        <path d="M252.72 304.868C249.581 307.525 245.955 309.543 242.043 310.809C228.292 315.441 194.547 319.121 194.547 319.121L196.478 315.415L219.295 271.565C219.295 271.565 246.693 273.981 252.113 279.246C252.113 279.246 251.429 271.158 257.577 282.891C261.864 291.074 259.309 299.153 252.72 304.868Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M78.9028 271.565C71.1095 254.428 95.1043 234.417 95.1043 234.417L104.759 232.824C105.746 216.665 139.284 202.456 150.506 201.347C168.361 199.615 203.951 223.601 212.593 236.053C221.235 248.505 217.84 263.763 217.84 263.763C217.84 263.763 238.917 280.709 240.198 291.88C241.48 303.05 204.695 328.361 163.997 316.55C123.299 304.738 96.8967 310.93 78.9028 271.565Z" fill="#757575"/>
        <path opacity="0.3" d="M162.083 220.718C139.699 216.328 116.934 232.356 116.934 232.356C113.375 232.278 106.846 232.772 103.166 233.083L104.725 232.824C105.071 227.135 109.453 221.688 115.471 216.977C127.446 212.18 142.955 207.816 144.185 208.673C145.917 209.859 133.179 216.336 129.767 218.042C146.22 210.128 158.048 214.882 162.083 217.35C166.119 219.817 162.083 220.718 162.083 220.718Z" fill="black" fillOpacity="0.7"/>
        <path opacity="0.3" d="M217.814 263.755C215.442 261.832 212.065 259.33 209.224 258.178C209.224 258.178 206.28 245.674 181.61 232.356C181.61 232.356 178.796 228.979 179.237 227.394C180.285 223.61 200.427 234.166 200.963 234.443C200.652 234.131 193.958 227.386 198.773 227.091C202.236 226.884 210.896 235.525 216.334 243.466C218.528 249.996 219.037 256.975 217.814 263.755Z" fill="black" fillOpacity="0.7"/>
        <path opacity="0.3" d="M147.19 235.508C147.19 235.508 171.098 239.093 190.183 248.635C190.183 248.635 175.817 234.564 164.751 231.784C153.684 229.005 143.969 229.291 143.363 231.655C142.756 234.019 147.19 235.508 147.19 235.508Z" fill="black" fillOpacity="0.7"/>
        <path opacity="0.3" d="M185.741 316.697C191.023 316.697 220.222 311.9 228.509 306.947C214.654 316.42 190.131 324.161 163.997 316.567C128.72 306.323 104.145 309.64 86.5059 284.952C88.6966 287.03 90.2467 288.303 90.2467 288.303L111.28 297.352C111.28 297.352 99.0271 274.769 122.104 277.237C145.181 279.705 166.015 310.012 166.015 310.012C166.015 310.012 176.25 309.241 174.674 311.372C173.098 313.502 168.864 314.229 168.864 314.229C168.864 314.229 180.155 316.697 185.741 316.697Z" fill="black" fillOpacity="0.7"/>
        <path opacity="0.3" d="M231.747 304.557C231.124 305.059 230.474 305.552 229.79 306.046C230.402 305.498 231.056 305 231.747 304.557Z" fill="black" fillOpacity="0.7"/>
        <path d="M78.9028 271.565C71.1095 254.428 95.1043 234.417 95.1043 234.417L104.759 232.824C105.746 216.665 139.284 202.456 150.506 201.347C168.361 199.615 203.951 223.601 212.593 236.053C221.235 248.505 217.84 263.763 217.84 263.763C217.84 263.763 238.917 280.709 240.198 291.88C241.48 303.05 204.695 328.361 163.997 316.55C123.299 304.738 96.8967 310.93 78.9028 271.565Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.896 313.658C152.896 313.658 169.479 316.351 176.519 310.012" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M217.805 263.78C217.805 263.78 210.965 257.087 198.634 254.688" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M180.622 218.449C187.301 222.37 193.669 226.797 199.672 231.689" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M125.16 220.631C141.128 210.881 161.91 204.82 177.592 216.726" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M117.748 225.056C119.185 224.19 120.623 223.324 122.078 222.458" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M188.598 236.391C198.183 242.349 208.393 249.986 213.346 260.447" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M178.19 230.624C180.788 231.854 183.273 233.222 185.741 234.668" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M211.883 240.478C211.883 240.478 217.797 251.467 217.806 263.78" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M127.551 233.577C129.903 234.055 132.218 234.7 134.478 235.508" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M95.1045 234.417C104.42 232.179 114.067 231.668 123.567 232.91" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M141.232 221.272C132.492 223.38 124.241 227.159 116.935 232.399" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M165.244 221.636C158.811 219.333 151.312 219.48 144.679 220.562" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M249.845 294.451C243.887 304.496 234.085 311.527 223.07 315.121" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M253.699 285.662C253.125 287.789 252.324 289.847 251.309 291.802" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M227.365 272.492C227.365 272.492 239.964 274.674 244.078 282.017" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M83.7518 231.663C83.7518 231.663 74.6249 230.858 71.4297 237.222" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M72.1138 252.61C71.4904 266.361 77.7596 278.96 88.4451 287.507" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M73.707 242.92C73.0316 245.116 72.5733 247.372 72.3389 249.657" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M179.23 282.198C170.57 270.63 154.984 267.166 141.129 267.478" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M184.926 291.897C183.968 289.349 182.687 286.933 181.116 284.71" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M154.117 312.038C148.627 311.781 143.175 310.99 137.838 309.674C136.343 309.322 134.92 308.95 133.569 308.557C101.123 299.205 108.856 280.545 118.788 274.96C135.327 265.668 158.993 274.509 171.471 286.286C184.589 298.66 178.363 312.896 154.117 312.038Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M154.117 312.038C148.626 311.781 143.174 310.99 137.837 309.674C136.342 309.322 134.919 308.95 133.568 308.557C136.033 304.575 138.853 300.825 141.994 297.352C142.626 297.854 155.433 303.318 154.117 312.038Z" fill="#263238"/>
        <path d="M152.948 303.812C152.948 310.644 142.557 324.707 125.394 324.594C115.869 324.542 106.5 309.276 105.677 288.338C105.028 271.886 97.2085 253.026 117.462 239.258C137.855 225.403 165.244 236.244 165.244 266.534C165.244 276.362 164.257 285.333 161.884 293.161" fill="white"/>
        <path d="M152.948 303.812C152.948 310.644 142.557 324.707 125.394 324.594C115.869 324.542 106.5 309.276 105.677 288.338C105.028 271.886 97.2085 253.026 117.462 239.258C137.855 225.403 165.244 236.244 165.244 266.534C165.244 276.362 164.257 285.333 161.884 293.161" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M124.494 276.258C124.494 276.258 133.491 273.531 139.397 278.977" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M112.683 275.574C112.683 275.574 108.882 272.111 104.18 273.765" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M131.949 287.524C131.949 289.135 131.161 290.433 130.217 290.425C129.274 290.416 128.486 289.109 128.538 287.498C128.59 285.887 129.326 284.588 130.269 284.597C131.213 284.606 131.958 285.913 131.949 287.524Z" fill="#263238"/>
        <path d="M111.834 285.679C111.834 287.281 111.046 288.58 110.102 288.572C109.158 288.563 108.414 287.255 108.431 285.645C108.448 284.034 109.219 282.735 110.163 282.744C111.107 282.753 111.843 284.069 111.834 285.679Z" fill="#263238"/>
        <path d="M114.37 278.977C114.566 282.193 114.566 285.417 114.37 288.632C113.773 294.226 111.14 298.158 112.127 299.387C113.418 301.041 119.678 301.604 119.678 301.604" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M115.792 305.821C115.792 305.821 127.17 308.038 135.925 304.661C135.925 304.661 137.925 307.406 132.461 310.255C126.997 313.103 118.537 313.207 118.009 306.791" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M139.396 266.421C139.396 266.421 143.335 277.548 148.115 281.601L149.847 287.507C149.847 287.507 153.501 281.324 157.718 284.415C161.935 287.507 160.515 297.352 160.515 297.352C160.515 297.352 173.504 291.169 173.747 284.138C173.858 280.065 174.801 276.057 176.518 272.362C176.518 272.362 173.703 273.496 172.578 275.583C172.578 275.583 178.769 262.213 174.829 258.836C170.889 255.459 139.396 266.421 139.396 266.421Z" fill="#FECC00"/>
        <path d="M139.396 266.421C139.396 266.421 143.335 277.548 148.115 281.601L149.847 287.507C149.847 287.507 153.501 281.324 157.718 284.415C161.935 287.507 160.515 297.352 160.515 297.352C160.515 297.352 173.504 291.169 173.747 284.138C173.858 280.065 174.801 276.057 176.518 272.362C176.518 272.362 173.703 273.496 172.578 275.583C172.578 275.583 178.769 262.213 174.829 258.836C170.889 255.459 139.396 266.421 139.396 266.421Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M173.427 266.699L159.313 271.574C151.719 273.244 143.841 273.117 136.305 271.201C124.182 267.876 117.471 261.832 116.086 260.499L115.818 260.23C116.683 263.607 119.706 267.824 119.706 267.824C110.951 266.958 106.526 258.299 106.526 258.299C103.435 262.239 103.928 270.959 103.928 270.959C98.8108 258.394 103.677 251.831 103.677 251.831C101.677 241.44 105.409 237.491 105.409 237.491L108.466 239.457C114.051 227.083 130.278 227.429 130.278 227.429L127.906 230.174C141.406 223.991 169.522 230.174 169.522 230.174L170.934 241.985L175.428 240.582C175.558 240.911 175.679 241.258 175.783 241.604C177.037 245.893 177.767 250.319 177.956 254.784C177.956 254.87 177.956 254.922 177.956 254.922L179.584 253.805L182.45 251.831C182.45 251.831 182.693 260.239 173.427 266.699Z" fill="#FECC00"/>
        <path d="M173.427 266.699L159.313 271.574C151.719 273.244 143.841 273.117 136.305 271.201C124.182 267.876 117.471 261.832 116.086 260.499L115.818 260.23C116.683 263.607 119.706 267.824 119.706 267.824C110.951 266.958 106.526 258.299 106.526 258.299C103.435 262.239 103.928 270.959 103.928 270.959C98.8108 258.394 103.677 251.831 103.677 251.831C101.677 241.44 105.409 237.491 105.409 237.491L108.466 239.457C114.051 227.083 130.278 227.429 130.278 227.429L127.906 230.174C141.406 223.991 169.522 230.174 169.522 230.174L170.934 241.985L175.428 240.582C175.558 240.911 175.679 241.258 175.783 241.604C177.037 245.893 177.767 250.319 177.956 254.784C177.956 254.87 177.956 254.922 177.956 254.922L179.584 253.805L182.45 251.831C182.45 251.831 182.693 260.239 173.427 266.699Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.896 285.662C152.896 285.662 157.399 276.059 162.179 278.977C166.959 281.896 166.344 287.446 165.245 290.286C164.145 293.127 159.088 306.349 154.585 298.755" fill="white"/>
        <path d="M152.896 285.662C152.896 285.662 157.399 276.059 162.179 278.977C166.959 281.896 166.344 287.446 165.245 290.286C164.145 293.127 159.088 306.349 154.585 298.755" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M111.913 248.635C109.471 250.047 108.449 257.502 113.644 262.68" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M115.792 247.501C115.792 257.355 133.033 265.824 152.897 264.109C172.761 262.395 174.649 257.502 174.649 257.502" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M121.896 245.786C128.061 255.658 162.126 258.117 167.616 240.478" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M115.791 242.816C113.375 242.054 114.925 234.937 120.866 231.689" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M105.911 239.275C105.046 241.613 106.899 247.666 111.575 246.471" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.3" d="M179.558 253.77L182.424 251.796C181.471 252.515 180.519 253.173 179.558 253.77Z" fill="black" fillOpacity="0.7"/>
    </svg>

}