import React from "react";

export const Question4Img = (props) => {
    return <svg {...props} viewBox="0 0 636 510" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.7" d="M123.465 408.751C123.465 408.751 165.351 481.702 253.611 498.191C341.87 514.68 429.61 472.564 501.609 440.291C573.608 408.019 606.054 340.276 582.454 276.525C558.854 212.774 496.469 223.564 431.635 171.203C366.801 118.843 357.534 95.2176 274.394 86.0511C191.254 76.8847 109.508 153.151 97.4578 251.083C85.4073 349.014 123.465 408.751 123.465 408.751Z" fill="white" fillOpacity="0.5"/>
        <path d="M539.194 447.811C539.194 447.811 385.681 444.641 320.561 447.811C273.94 450.088 133.85 447.811 133.85 447.811C133.85 447.811 136.726 345.396 133.85 293.481C132.009 260.37 133.85 160.832 133.85 160.832C133.85 160.832 282.972 161.126 330.911 159.263C410.25 156.191 539.194 160.832 539.194 160.832C539.194 160.832 542.289 232.054 539.194 274.436C536.057 317.668 539.194 447.811 539.194 447.811Z" fill="white"/>
        <path opacity="0.5" d="M539.195 160.832V225.844C511.685 225.157 435.495 223.763 335.618 226.159C229.619 228.698 155.085 226.977 133.851 226.356V160.832C133.851 160.832 282.973 161.126 330.912 159.263C410.272 156.191 539.195 160.832 539.195 160.832Z" fill="#D2D2D2" fillOpacity="0.7"/>
        <path opacity="0.2" d="M385.507 443.573L381.792 222.749H336.544C334.529 257.494 335.858 397.323 336.37 443.573H385.507Z" fill="#DBDBDB"/>
        <path opacity="0.2" d="M138.993 226.497L188.697 226.16C188.697 226.16 192.488 396.985 183.837 443.54H141.858C141.858 443.54 142.436 381.307 141.858 329.958C141.281 278.609 138.993 226.497 138.993 226.497Z" fill="#DBDBDB"/>
        <path d="M138.993 192.112C138.993 192.112 262.108 190.38 299.326 192.112C336.544 193.844 438.893 190.826 453.874 189.933C468.855 189.04 536.678 192.112 536.678 192.112C539.227 171.727 534.379 162.401 534.379 162.401C534.379 162.401 352.778 164.765 264.854 165.942C176.93 167.118 139.004 165.942 139.004 165.942C139.004 165.942 140.725 182.088 138.993 192.112Z" fill="#FECC00"/>
        <path d="M539.195 447.811C539.195 447.811 385.681 444.641 320.561 447.811C273.94 450.088 133.85 447.811 133.85 447.811C133.85 447.811 136.726 345.396 133.85 293.481C132.009 260.37 133.85 160.832 133.85 160.832C133.85 160.832 282.972 161.126 330.911 159.263C410.25 156.191 539.195 160.832 539.195 160.832C539.195 160.832 542.289 232.054 539.195 274.436C536.057 317.668 539.195 447.811 539.195 447.811Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.159 194.694C145.159 194.694 192.139 192.973 246.016 194.694C299.893 196.416 381.824 195.315 425.558 193.932C469.291 192.548 522.187 192.112 522.187 192.112" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.159 226.159C145.159 226.159 486.298 222.717 522.133 222.717" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M188.696 192.112C188.696 192.112 193.436 383.05 188.696 420.551" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M235.677 192.112C235.677 192.112 233.955 402.302 235.677 417.021" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M284.814 192.112C284.814 192.112 286.536 233.514 286.993 270.122C287.451 306.729 284.814 415.289 286.993 427.404" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M336.545 193.844C336.545 193.844 333.919 319.695 335.008 362.796C336.098 405.897 336.545 429.169 336.545 429.169" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M381.792 192.112C381.792 192.112 386.151 341.725 385.671 373.811C385.192 405.897 385.671 423.809 385.671 423.809" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M430.93 189.889C430.93 189.889 430.657 278.14 433.436 302.665C436.214 327.191 432.183 429.169 432.183 429.169" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M479.271 189.889C479.271 189.889 481.123 235.017 481.45 260.24C481.777 285.462 480.589 414.134 483.629 423.809" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.159 252.907C145.159 252.907 216.065 251.611 260.997 252.907C260.997 252.907 260.888 264.968 260.997 269.718C260.997 269.718 156.403 268.858 145.159 269.718C145.159 269.697 146.02 255.903 145.159 252.907Z" fill="white" stroke="#263238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M234.903 274.436H335.64L335.008 290.496L269.724 291.999C246.844 292.533 235.677 293.285 235.677 293.285L234.903 274.436Z" fill="#FECC00" stroke="#263238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M335.64 312.831C335.64 312.831 421.712 310.826 433.435 306.86V330.829C433.435 330.829 349.335 331.167 334.747 333.826C334.747 333.847 333.897 317.581 335.64 312.831Z" fill="white" stroke="#263238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M341.566 312.667C341.566 312.667 333.341 325.36 335.029 326.984C336.718 328.607 345.064 313.223 347.613 312.82C350.163 312.417 338.723 333.978 339.812 334.73C340.902 335.482 355.349 311.937 358.781 311.981C362.213 312.024 351.154 332.475 353.333 332.388C355.512 332.3 365.667 312.34 369.371 311.981C373.075 311.621 360.785 332.082 363.629 331.995C366.473 331.908 377.88 310.118 380.233 311.294C382.587 312.471 368.521 330.252 372.247 331.712C375.973 333.172 385.496 312.656 390.061 311.632C394.626 310.608 379.503 330.317 383.916 330.83C388.329 331.342 394.942 310.27 398.385 311.959C401.828 313.648 392.066 325.338 393.504 326.123C394.942 326.907 399.54 312.035 404.137 311.959C408.735 311.883 398.69 325.338 399.779 326.962C400.869 328.585 405.543 322.985 405.543 322.985" stroke="#FECC00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M149.463 252.809C149.463 252.809 145.803 261.525 146.674 261.939C147.546 262.353 151.032 253.31 154.432 253.746C157.831 254.182 148.265 269.533 150.488 269.5C152.71 269.468 160.293 251.883 162.614 252.809C164.935 253.735 157.014 267.43 158.517 268.4C160.021 269.37 167.571 251.818 169.946 252.776C172.321 253.735 163.268 267.757 165.588 267.757C167.909 267.757 175.721 252.504 178.085 252.504C180.449 252.504 170.763 265.807 173.128 267.583C175.492 269.359 181.321 250.793 184.775 252.504C188.228 254.215 179.599 266.668 182.182 268.008C184.764 269.348 191.443 251.436 193.6 252.395C195.757 253.354 187.172 269.359 188.653 269.359C190.135 269.359 199.636 250.946 201.793 252.362C203.95 253.779 193.818 269.37 195.757 269.381C197.696 269.392 207.829 250.488 210.411 251.414C212.993 252.341 203.732 266.069 206.053 266.94C208.374 267.812 214.028 257.233 215.107 257.233C216.185 257.233 211.326 266.94 212.677 266.94C214.028 266.94 219.214 250.739 221.786 252.373C224.357 254.008 218.768 263.051 220.064 263.486C221.361 263.922 229.543 251.85 231.907 253.572C234.271 255.293 222.646 269.479 226.307 269.5C229.968 269.522 236.309 253.746 239.338 253.997C242.367 254.247 233.302 267.997 235.677 268.215C238.052 268.433 242.792 252.7 245.374 253.561C247.956 254.422 241.756 265.382 243.434 265.981C245.112 266.581 248.817 254.999 251.834 255.206C254.852 255.413 246.234 266.417 249.895 266.853C253.556 267.289 254.831 254.607 258.513 255.086C262.196 255.566 253.883 266.7 255.92 268.16C257.958 269.62 260.997 262.56 260.997 262.56" stroke="#FECC00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M146.489 279.382L151.511 277.203C151.462 278.855 151.123 280.485 150.509 282.019C151.235 281.853 151.894 281.473 152.402 280.93C152.911 280.386 153.246 279.703 153.364 278.968L152.808 280.733H157.504C157.43 280.476 157.443 280.202 157.539 279.952C157.635 279.702 157.81 279.491 158.038 279.35C157.536 279.796 157.842 280.712 158.441 281.017C159.08 281.257 159.784 281.257 160.424 281.017C162.079 280.604 163.673 279.973 165.163 279.143C165.043 279.686 165.119 280.255 165.378 280.748C165.638 281.24 166.063 281.625 166.579 281.834C167.6 282.223 168.697 282.364 169.783 282.248L183.87 281.79" stroke="#263238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.269 289.199C145.269 289.199 147.208 286.704 148.94 287.02C150.673 287.336 149.583 290.114 150.226 290.539C150.869 290.964 151.958 284.797 153.026 287.02C153.703 288.385 154.661 289.592 155.837 290.561C155.837 290.561 158.201 288.066 158.844 288.556C159.487 289.047 160.794 288.556 160.794 288.556H180.83" stroke="#263238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M208.514 277.258C208.514 277.258 192.313 330.121 327.915 324.39" stroke="#263238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M327.916 320.599L331.37 324.826L327.916 326.973" stroke="#263238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M246.877 326.112C235.677 320.937 215.357 324.445 200.278 333.924C185.199 343.402 182.094 374.432 237.322 365.378C292.55 356.324 257.62 330.851 238.281 330.851" fill="white"/>
        <path d="M246.877 326.112C235.677 320.937 215.357 324.445 200.278 333.924C185.199 343.402 182.094 374.432 237.322 365.378C292.55 356.324 257.62 330.851 238.281 330.851" stroke="#FECC00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M203.078 342.923C203.078 342.923 206.455 339.862 207.915 339.655C209.375 339.448 205.159 342.476 207.915 342.923C210.672 343.37 210.094 341.834 212.774 341.289C215.455 340.744 216.283 343.729 216.283 343.729C216.283 343.729 220.586 342.248 223.179 341.55C225.772 340.853 224.04 344.982 227.537 343.729C231.035 342.476 229.455 340.526 234.957 341.55C240.459 342.575 251.136 341.921 255.93 341.55" stroke="#FECC00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M207.916 352.053C208.951 349.417 207.655 348.916 210.672 350.245C213.69 351.574 215.412 353.393 216.708 352.053C217.242 351.456 217.946 351.037 218.726 350.854C219.506 350.671 220.323 350.732 221.066 351.029C222.352 351.574 219.334 352.435 222.352 351.574C224.104 351.096 225.727 350.233 227.102 349.046C227.102 349.046 226.34 350.888 229.521 352.522C232.703 354.156 234.969 350.79 234.969 350.79C239.825 350.605 244.687 350.678 249.536 351.007" stroke="#FECC00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.3" d="M258.437 461.147C258.437 461.147 262.37 472.859 272.524 475.234" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.3" d="M251.115 470.201C251.115 470.201 254.689 479.625 262.283 482.643" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.2" d="M385.703 141.035L390.29 135.784H400.946C403.819 135.147 406.544 133.967 408.975 132.308C408.975 132.308 404.922 128.179 400.03 128.026H390.835L366.277 123.581L229.477 135.784C228.527 138.558 228.255 141.519 228.685 144.42C229.116 147.32 230.235 150.075 231.95 152.453L385.703 141.035Z" fill="black" fillOpacity="0.7"/>
        <path opacity="0.2" d="M447.24 152.453L450.9 149.305L462.82 149.174C463.586 145.322 463.674 141.366 463.081 137.483L430.526 126.174C422.159 131.393 425.558 152.453 425.558 152.453H447.24Z" fill="black" fillOpacity="0.7"/>
        <path d="M399.442 115.889C399.442 115.889 405.184 115.17 408.453 117.807C408.453 117.807 408.028 121.184 399.41 122.045L399.442 115.889Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M388.841 114.386L381.356 109.298L229.324 121.533C222.646 132.341 230.98 143.04 230.98 143.04L384.003 130.336L389.375 125.085L399.987 123.254C400.64 121.927 400.969 120.463 400.945 118.983C401.03 117.357 400.523 115.755 399.518 114.473L388.841 114.386Z" fill="#263238"/>
        <path d="M279.54 118.896L347.352 113.329C347.49 113.648 347.55 113.996 347.526 114.343C347.503 114.69 347.396 115.027 347.216 115.324C347.036 115.622 346.787 115.872 346.49 116.054C346.193 116.235 345.857 116.343 345.51 116.369L281.937 121.816C281.633 121.847 281.326 121.813 281.036 121.717C280.746 121.621 280.479 121.466 280.253 121.26C280.027 121.055 279.847 120.804 279.724 120.525C279.601 120.245 279.539 119.943 279.54 119.637V118.896Z" fill="#FECC00"/>
        <path opacity="0.3" d="M381.214 111.651C381.214 111.651 386.106 117.098 382.652 127.994" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.3" d="M389.418 115.802C390.277 118.002 390.499 120.4 390.061 122.72" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.3" d="M230.48 123.581C230.48 123.581 235.655 128.103 232.201 141.013" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.3" d="M233.389 122.72C233.389 122.72 235.023 122.927 235.677 131.121L381.215 117.621C380.879 115.011 379.848 112.539 378.229 110.463L233.389 122.72Z" fill="white"/>
        <path opacity="0.3" d="M390.834 115.301C391.575 116.488 391.975 117.856 391.989 119.256L400.03 118.482C399.886 117.371 399.547 116.294 399.028 115.301H390.834Z" fill="white"/>
        <path d="M460.008 143.857L443.437 139.662L440.528 141.22L429.295 137.222C429.295 137.222 427.497 125.989 436.606 115.301L447.947 119.811L450.039 122.742L465.162 130.249C465.162 130.249 466.774 140.229 460.008 143.857Z" fill="#FECC00"/>
        <path d="M460.008 143.857L443.437 139.662L440.528 141.22L429.295 137.222C429.295 137.222 427.497 125.989 436.606 115.301L447.947 119.811L450.039 122.742L465.162 130.249C465.162 130.249 466.774 140.229 460.008 143.857Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M431.333 135.784C431.333 135.784 439.798 126.795 437.107 116.717" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M442.729 137.963C442.729 137.963 449.353 130.336 448.427 122.252" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.3" d="M515.976 374.857L512.414 447.332C493.38 447.037 466.174 446.667 437.324 446.504C430.504 440.533 423.749 409.057 421.156 399.284C418.562 389.511 423.89 359.68 423.89 359.68C423.89 359.68 419.717 355.649 418.857 352.881C417.996 350.114 421.145 345.985 421.145 345.985L419.434 336.506L415.98 333.891L412.821 330.001C405.652 327.027 404.203 323.028 404.203 323.028L434.524 316.099L515.976 374.857Z" fill="black" fillOpacity="0.7"/>
        <path d="M472.941 550.171C472.941 550.171 461.261 452.54 455.236 436.022C449.211 419.505 441.268 364.91 441.268 364.91C441.268 364.91 459.616 363.439 465.172 363.384C470.729 363.33 477.909 358.013 477.909 358.013C477.299 359.102 475.915 358.427 474.891 351.759C474.205 347.303 467.417 345.941 467.417 345.941C467.417 345.941 451.129 347.161 437.39 345.32C434.06 346.369 430.489 346.369 427.159 345.32C422.158 343.501 421.145 341.55 423.891 338.903C423.891 338.903 420.001 333.815 433.293 326.591C433.293 326.591 424.457 318.649 428.772 313.517C433.086 308.385 443.317 303.265 443.317 303.265C443.317 303.265 443.742 299.397 445.616 298.384C447.49 297.37 457.687 293.208 463.288 289.33C468.888 285.451 471.328 285.745 481.679 285.451C492.029 285.157 504.667 282.586 509.407 284.732C513.089 286.41 514.8 292.99 514.8 292.99C518.243 294.08 518.592 299.702 518.809 301.086C519.823 307.263 526.937 332.867 530.097 337.323C533.256 341.779 535.12 349.536 534.553 353.557C533.986 357.577 532.537 362.273 531.099 370.51C529.661 378.746 524.78 407.15 522.383 415.332C519.986 423.515 518.646 437.744 519.932 444.641L556.256 531.072" fill="white"/>
        <path opacity="0.4" d="M511.651 319.76C511.651 319.76 504.776 323.911 504.776 326.548C504.776 326.548 491.702 336.495 490.198 348.207C490.198 348.207 482.953 355.551 481.362 358.884C479.772 362.218 472.864 366.13 472.864 366.13C470.912 365.945 468.952 365.873 466.991 365.912C463.677 365.412 460.4 364.683 457.186 363.733C460.454 363.526 463.461 363.362 465.194 363.352C469.802 363.352 475.522 359.647 477.342 358.394C476.645 358.394 475.675 356.716 474.912 351.726C474.226 347.27 467.438 345.908 467.438 345.908C467.438 345.908 451.15 347.129 437.411 345.287C435.161 345.984 432.794 346.229 430.449 346.006C430.502 345.143 430.383 344.277 430.098 343.46C429.814 342.642 429.37 341.89 428.793 341.245C428.793 341.245 435.101 337.051 433.456 333.815C432.672 332.268 429.85 331.309 427.093 330.731C429.007 329.115 431.093 327.716 433.314 326.558C433.314 326.558 424.478 318.616 428.793 313.484C433.107 308.353 443.316 303.265 443.316 303.265C443.316 303.265 443.741 299.397 445.615 298.384C445.615 298.384 444.471 304.365 452.795 303.831C461.119 303.297 472.973 298.384 472.973 298.384C472.973 298.384 495.417 299.005 499.971 295.551C499.971 295.551 505.419 300.203 509.406 300.998C513.394 301.794 511.651 319.76 511.651 319.76Z" fill="#DBDBDB"/>
        <path opacity="0.4" d="M534.52 353.557C533.942 357.577 532.504 362.273 531.066 370.51C529.628 378.746 524.747 407.15 522.35 415.332C519.953 423.515 518.613 437.744 519.899 444.64L522.949 451.918C516.336 440.195 516.859 424.811 517.393 420.769C518.035 415.812 521.696 397.486 525.019 388.007C528.342 378.529 528.582 358.699 528.582 358.013C528.582 357.326 526.861 362.371 525.106 367.96C523.352 373.549 516.946 389.304 516.946 389.304C516.728 386.035 519.419 366.914 519.909 364.735C520.4 362.556 524.344 347.859 525.019 343.762C525.695 339.665 522.448 335.046 521.631 331.723C520.814 328.4 517.589 313.876 516.728 303.842C516.386 298.388 515.03 293.045 512.729 288.088C513.625 289.627 514.321 291.275 514.8 292.99C518.242 294.08 518.591 299.702 518.809 301.086C519.822 307.263 526.937 332.867 530.096 337.323C533.256 341.779 535.086 349.536 534.52 353.557Z" fill="#DBDBDB"/>
        <path opacity="0.4" d="M470.794 532.826C467.253 504.978 460.172 451.973 455.705 437.439C458.788 441.187 462.296 447.244 464.758 458.913C469.716 482.403 471.23 492.743 471.437 503.518C471.579 510.404 471.481 523.108 470.794 532.826Z" fill="#A8A8A8"/>
        <path d="M472.941 550.171C472.941 550.171 461.261 452.54 455.236 436.022C449.211 419.505 441.268 364.91 441.268 364.91C441.268 364.91 459.616 363.439 465.172 363.384C470.729 363.33 477.909 358.013 477.909 358.013C477.299 359.102 475.915 358.427 474.891 351.759C474.205 347.303 467.417 345.941 467.417 345.941C467.417 345.941 451.129 347.161 437.39 345.32C434.06 346.369 430.489 346.369 427.159 345.32C422.158 343.501 421.145 341.55 423.891 338.903C423.891 338.903 420.001 333.815 433.293 326.591C433.293 326.591 424.457 318.649 428.772 313.517C433.086 308.385 443.317 303.265 443.317 303.265C443.317 303.265 443.742 299.397 445.616 298.384C447.49 297.37 457.687 293.208 463.288 289.33C468.888 285.451 471.328 285.745 481.679 285.451C492.029 285.157 504.667 282.586 509.407 284.732C513.089 286.41 514.8 292.99 514.8 292.99C518.243 294.08 518.592 299.702 518.809 301.086C519.823 307.263 526.937 332.867 530.097 337.323C533.256 341.779 535.12 349.536 534.553 353.557C533.986 357.577 532.537 362.273 531.099 370.51C529.661 378.746 524.78 407.15 522.383 415.332C519.986 423.515 518.646 437.744 519.932 444.641L556.256 531.072" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M423.89 338.903C423.89 338.903 426.756 342.781 436.67 341.202C446.585 339.622 458.461 329.707 458.461 329.707L465.575 326.689C465.575 326.689 474.629 327.692 477.93 326.112" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M472.864 326.994C472.864 326.994 478.769 333.314 483.508 334.741" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M477.908 358.013C481.929 355.42 487.245 348.207 492.126 347.662" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M477.091 349.242C479.27 342.041 485.807 334.73 485.807 334.73C486.883 332.334 488.232 330.071 489.828 327.986C491.444 325.59 493.909 323.896 496.724 323.246C496.724 323.246 491.015 307.078 492.704 303.842" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M467.417 345.941C467.417 345.941 471.851 344.546 472.865 341.583" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M466.414 339.905C466.414 339.905 466.142 344.503 459.234 345.941" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M465.554 326.689C465.554 326.689 468.986 324.412 470.141 322.102" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M443.317 303.265C443.317 303.265 442.085 306.718 447.228 308.157C452.37 309.595 466.164 308.015 471.906 303.265C471.906 303.265 473.322 299.244 472.897 296.662" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M471.121 303.842C471.121 303.842 487.965 303.265 489.261 303.265C490.558 303.265 495.722 300.541 496.724 297.664" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M496.703 323.246C496.703 323.246 500.494 324.543 503.12 323.976" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M488.291 349.242C488.291 349.242 487.681 342.869 492.126 333.847" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M514.767 292.99C517.055 301.881 520.574 331.124 522.949 336.658C525.325 342.193 525.608 344.078 523.886 349.242C522.165 354.407 519.387 366.914 519.387 366.914" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M519.855 444.662C517.992 438.779 516.848 431.022 517.556 427.426" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M410.631 319.509C410.631 319.509 405.859 320.403 404.246 322.985C404.246 322.985 405.652 325.164 411.415 324.586C417.179 324.009 410.631 319.509 410.631 319.509Z" fill="#263238"/>
        <path d="M427.627 310.51L562.39 290.506L564.111 314.552L428.771 327.986L422.452 325.131L412.102 325.393C411.138 324.522 410.51 323.342 410.325 322.056C410.14 320.771 410.41 319.462 411.088 318.354L421.744 316.066L427.627 310.51Z" fill="#FECC00"/>
        <path d="M450.801 311.741L506.367 303.864C506.926 303.785 507.495 303.819 508.04 303.965C508.586 304.111 509.096 304.366 509.54 304.714C509.985 305.062 510.355 305.496 510.628 305.99C510.9 306.484 511.071 307.028 511.128 307.59L511.237 308.68C511.351 309.771 511.031 310.864 510.346 311.722C509.661 312.581 508.666 313.135 507.576 313.266L451.913 320.032C451.358 320.102 450.794 320.059 450.256 319.906C449.717 319.754 449.215 319.494 448.779 319.144C448.343 318.793 447.982 318.358 447.717 317.865C447.453 317.372 447.29 316.831 447.239 316.273C447.141 315.206 447.455 314.141 448.118 313.298C448.781 312.455 449.741 311.898 450.801 311.741Z" fill="#263238"/>
        <path d="M562.389 290.506C571.726 289.765 573.731 313.604 564.111 314.552C554.904 315.467 552.91 291.269 562.389 290.506Z" fill="#FECC00"/>
        <path d="M562.389 290.506C571.726 289.765 573.731 313.604 564.111 314.552C554.904 315.467 552.91 291.269 562.389 290.506Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M427.628 310.51C427.628 310.51 424.958 313.855 425.688 320.228C425.933 323.061 427.005 325.758 428.772 327.986" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M421.744 316.099C421.256 317.582 421.068 319.146 421.189 320.703C421.311 322.259 421.74 323.775 422.452 325.164" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M558.206 315.14C549 316.099 547.213 292.903 556.626 291.367" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M562.575 292.01C569.972 291.367 571.552 311.97 563.937 312.798C556.626 313.582 555.079 292.664 562.575 292.01Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M562.597 293.481C562.597 293.481 566.454 294.908 567.14 301.914C567.827 308.919 563.872 311.087 563.872 311.087" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M477.909 365.912C477.909 365.912 468.103 366.489 467.014 365.912C465.924 365.334 459.823 353.84 459.54 350.256C459.256 346.671 458.679 335.166 458.396 327.986C458.112 320.806 458.112 309.595 444.602 311.981C438.566 313.07 437.553 318.758 435.548 331.592C433.544 344.427 435.407 347.826 433.969 364.212C432.531 380.599 434.252 387.092 437.706 395.34C441.16 403.587 442.434 424.55 451.63 432.307C460.825 440.065 464.432 455.013 464.432 455.013" fill="white"/>
        <path opacity="0.4" d="M453.874 324.695C451.401 325.295 441.89 325.73 441.269 328.955C440.648 332.18 446.291 333.619 448.634 336.462C448.547 336.462 438.828 336.462 436.649 341.202C434.47 345.941 438.447 356.117 438.447 361.717C438.447 367.317 433.566 378.529 437.303 384.347C440.571 389.424 443.6 401.114 442.02 412.216C440.375 405.57 439.242 399.142 437.662 395.318C434.209 387.081 432.487 380.566 433.925 364.191C435.363 347.815 433.511 344.427 435.527 331.57C436.965 322.364 437.891 316.818 440.55 314.029C443.655 313.354 447.163 313.092 449.266 314.596C453.765 317.755 456.391 324.085 453.874 324.695Z" fill="#DBDBDB"/>
        <path d="M477.909 365.912C477.909 365.912 468.103 366.489 467.014 365.912C465.924 365.334 459.823 353.84 459.54 350.256C459.256 346.671 458.679 335.166 458.396 327.986C458.112 320.806 458.112 309.595 444.602 311.981C438.566 313.07 437.553 318.758 435.548 331.592C433.544 344.427 435.407 347.826 433.969 364.212C432.531 380.599 434.252 387.092 437.706 395.34C441.16 403.587 442.434 424.55 451.63 432.307C460.825 440.065 464.432 455.013 464.432 455.013" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M459.517 350.255C459.517 350.255 458.591 349.961 458.046 344.361C457.868 342.716 457.295 341.138 456.375 339.762C455.455 338.387 454.215 337.255 452.762 336.462" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M440.124 336.462C440.124 336.462 436.115 337.323 434.524 342.923" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

}