export const IntroImg = (props) => {
    return (<svg {...props} viewBox="0 0 375 308" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip1)">
                <path opacity="0.7" d="M20.4203 235.29C16.8157 218.573 18.3753 201.152 24.8911 185.345C33.8098 163.23 52.9752 147.519 74.4416 138.351C105.645 125.032 136.277 124.777 161.636 98.6663C169.458 90.6132 176.021 81.3908 184.029 73.5081C207.596 50.278 242.089 53.0656 267.848 71.5103C321.576 109.987 340.904 186.197 337.916 249.197C336.58 277.468 323.113 303.78 301.809 322.333C253.625 364.287 171.813 357.233 116.487 335.327C75.1829 318.996 30.1111 280.899 20.4203 235.29Z" fill="#F7F7F7"/>
                <path d="M338.288 19.8623H164.538V260.383H338.288V19.8623Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M251.413 20.0027V261.225" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M338.429 140.619H164.388" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M338.429 80.217H164.388" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M338.429 201.02H164.388" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M306.76 108.095L289.825 124.371" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M331.947 83.8787L311.715 103.329" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M288.599 116.617L283.222 121.787" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M311.696 94.4045L292.308 113.049" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M212.795 53.0223L195.86 69.298" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M237.981 28.8055L217.75 48.2652" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M194.633 61.5441L189.256 66.7133" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M217.731 39.3407L198.342 57.9762" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M302.714 172.074L285.779 188.35" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M327.901 147.858L307.669 167.317" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M284.552 180.596L279.176 185.765" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M307.65 158.393L288.252 177.028" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M226.442 191.038H294.809L278.885 249.679H210.519L226.442 191.038Z" fill="#7D7D7D" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M228.39 193.22H291.661L276.928 247.497H213.656L228.39 193.22Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M278.885 246.57H183.046V249.688H278.885V246.57Z" fill="#7D7D7D" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M278.885 246.57H234.844V249.688H278.885V246.57Z" fill="#595959" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M157.541 254.651H152.389V418.167H157.541V254.651Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M147.978 418.167H152.38L152.38 254.651H147.978L147.978 418.167Z" fill="#A8A8A8" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M233.289 344.655H283.55V256.234H233.289V344.655Z" fill="#A8A8A8" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M349.791 256.243H283.559V344.664H349.791V256.243Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M283.559 256.243H233.298V260.551H283.559H349.79V256.243H283.559Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M280.44 261.31H237.588V281.949H280.44V261.31Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M264.657 269.682H253.361V273.577H264.657V269.682Z" fill="#263238"/>
                <path d="M280.44 289.74H237.588V310.38H280.44V289.74Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M264.657 297.729H253.361V301.624H264.657V297.729Z" fill="#263238"/>
                <path d="M356.619 252.357H140.878V257.498H356.619V252.357Z" fill="#A8A8A8" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M356.619 252.357H291.474V257.498H356.619V252.357Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M159.171 304.687C159.171 304.687 165.596 309.135 175.094 336.629C178.26 345.769 196.066 395.055 197.003 398.435C197.939 401.816 199.813 405.927 202.623 405.927C205.433 405.927 215.736 392.817 214.481 391.262C213.226 389.708 207.306 383.452 207.306 383.452C207.306 383.452 203.765 343.559 203.138 332.321C202.51 321.084 205.067 307.552 199.138 298.497C193.209 289.441 166.973 298.806 166.973 298.806L159.171 304.687Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M204.665 352.268C203.99 344.055 203.4 336.348 203.175 332.368C202.548 321.131 205.105 307.599 199.176 298.543C193.247 289.488 167.011 298.852 167.011 298.852L159.171 304.686C159.171 304.686 165.596 309.135 175.094 336.629C176.302 340.122 179.656 349.496 183.393 359.919L204.665 352.268Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M189.378 144.571C190.188 146.74 190.573 149.045 190.511 151.36C190.23 154.478 187.392 155.892 187.111 155.05C186.869 153.646 186.774 152.221 186.83 150.798V149.665C186.131 150.818 185.273 151.868 184.283 152.783C182.297 154.759 181.164 154.197 180.311 154.197C179.459 154.197 179.178 151.388 180.602 149.384C182.025 147.38 186.549 142.866 189.378 144.571Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M195.897 151.931C193.087 150.227 188.816 156.183 182.297 154.741C181.463 154.563 181.164 153.327 182.578 150.499C183.992 147.67 188.526 145.395 190.793 143.7C193.059 142.005 194.764 140.581 194.764 138.596C194.764 136.611 189.659 139.729 188.207 140.862C186.492 141.931 184.709 142.885 182.868 143.719L181.641 143.784C179.122 143.943 175.235 144.393 173.764 145.657C171.835 147.333 170.729 153.093 170.27 155.312C169.015 157.129 167.891 158.805 167.245 159.816C165.259 162.925 125.32 192.105 125.32 192.105L128.13 217.025L173.455 165.519C174.71 164.493 176.155 163.723 177.707 163.253C180.826 162.12 185.07 161.268 188.76 159.283C191.49 157.824 194.139 156.217 196.694 154.469C198.67 153.242 197.433 152.849 195.897 151.931Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M148.259 175.118C137.131 183.546 125.357 192.152 125.357 192.152L128.167 217.071L156.033 185.419L148.259 175.118Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M134.321 269.391C134.321 269.391 154.3 268.455 183.337 273.137C212.373 277.819 223.238 282.811 222.367 288.429C218.827 311.223 165.596 386.261 165.596 386.261L166.533 396.871C166.533 396.871 164.66 399.372 158.103 399.681C151.546 399.99 143.426 390.635 143.426 390.635C143.426 390.635 150.76 383.049 153.57 374.003C156.38 364.957 178.972 306.531 178.972 306.531C178.972 306.531 149.308 313.086 116.525 307.159C83.7417 301.231 134.321 269.391 134.321 269.391Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M183.337 273.137C154.3 268.455 134.321 269.391 134.321 269.391C134.321 269.391 83.7417 301.231 116.525 307.159C149.308 313.086 178.972 306.531 178.972 306.531C178.972 306.531 169.605 330.879 162.112 350.545L184.133 358.973C199.887 335.149 220.222 302.224 222.367 288.429C223.238 282.811 212.373 277.819 183.337 273.137Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M71.2466 196.657C71.2466 196.657 67.8466 223.57 68.1369 228.665C68.4273 233.759 72.099 277.66 72.9513 287.296C73.8037 296.933 77.7658 305.417 84.8469 307.121C91.9281 308.826 110.343 311.363 118.276 309.388C126.21 307.412 134.705 289.563 137.534 282.764C140.363 275.965 142.358 271.714 141.505 270.3C140.653 268.886 136.972 265.767 136.972 265.767C136.972 265.767 129.891 214.262 128.186 200.908C126.481 187.554 127.343 186.468 121.105 181.364C114.867 176.26 82.5802 172.037 71.2466 196.657Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M116.581 290.593C115.56 291.37 114.492 292.11 113.406 292.803" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M135.258 267.827C135.258 267.827 129.245 279.627 119.288 288.373" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M130.884 268.455C130.884 268.455 121.208 277.501 111.532 283.12" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M138.667 111.991C140.225 115.263 141.461 118.678 142.358 122.189C143.201 126.441 143.491 130.402 145.467 131.254C147.444 132.106 151.087 132.387 150.572 134.654C150.057 136.92 146.32 140.6 146.32 140.6C146.32 140.6 147.172 154.197 146.882 156.745C146.591 159.292 131.586 159.292 130.743 159.292C129.9 159.292 121.377 179.407 121.377 179.407L97.5854 167.505L102.119 152.783C102.119 152.783 94.7567 138.615 94.1853 132.387C93.6139 126.16 108.095 114.829 116.29 112.282C124.486 109.734 138.667 111.991 138.667 111.991Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M130.743 159.292L123.184 157.138L126.013 168.198L130.743 159.292Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M141.477 128.014C141.852 129.615 141.674 131.029 141.065 131.17C140.456 131.31 139.651 130.14 139.267 128.548C138.883 126.956 139.07 125.532 139.679 125.392C140.288 125.251 141.093 126.422 141.477 128.014Z" fill="#263238"/>
                <path d="M136.691 143.147C136.691 143.147 140.091 145.704 143.201 144.571" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M137.253 106.616C137.253 106.616 141.225 113.696 138.386 118.228C135.548 122.76 128.758 124.736 125.077 128.988C121.395 133.239 119.457 140.6 117.995 141.162C116.534 141.724 112.375 133.23 109.772 133.792C107.168 134.354 102.981 140.347 106.662 146.537C107.676 148.31 109.173 149.758 110.979 150.711C112.786 151.665 114.826 152.085 116.862 151.922C116.862 151.922 106.662 157.306 104.685 157.306C102.709 157.306 96.7519 153.907 93.9232 150.751C91.0945 147.595 80.9318 138.334 80.3136 130.692C79.6954 123.051 89.9424 113.415 89.9424 113.415C89.9424 113.415 77.7659 112.562 80.8849 108.03C84.004 103.498 95.0472 100.669 95.0472 100.669C95.0472 100.669 93.0615 93.5898 95.8996 92.1664C98.7377 90.7429 116.01 96.4554 116.01 96.4554C116.01 96.4554 111.195 90.2279 117.995 89.9001C124.796 89.5724 136.401 98.1129 137.253 106.616Z" fill="#263238" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M114.717 137.369L150.853 122.76" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M141.43 120.71C142.934 120.058 144.631 120.01 146.17 120.575C147.708 121.141 148.97 122.277 149.693 123.748C150.416 125.219 150.544 126.912 150.052 128.475C149.56 130.038 148.485 131.352 147.05 132.144" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M128.186 187.292C128.186 187.292 127.905 179.079 125.639 176.813C123.372 174.547 106.662 165.201 99.0093 163.225C91.3568 161.249 83.4326 164.068 78.3278 165.772C73.223 167.476 69.8323 172.309 67.8185 182.806C65.8047 193.304 65.5518 199.794 69.8042 200.365C74.0567 200.936 80.0045 188.191 93.3144 185.634C106.624 183.078 118.81 185.353 121.648 186.768C124.486 188.182 128.186 187.292 128.186 187.292Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M85.1654 178.639C82.6084 179.575 80.8849 180.25 80.8849 180.25" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M128.186 187.292C128.186 187.292 110.39 177.384 103.833 175.399C100.433 174.369 94.3258 175.727 89.2679 177.272" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M212.308 233.366C212.308 233.366 211.371 239.256 210.294 239.921C209.217 240.586 209.282 234.452 209.282 234.452C209.282 234.452 207.933 237.982 207.1 238.825C206.266 239.668 206.763 235.211 206.763 235.211L207.699 231.006L212.308 233.366Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M200.318 227.56L209.011 227.7C209.011 227.7 211.305 228.028 211.633 229.339C212.098 230.789 212.646 232.212 213.272 233.6L199.822 229.667L200.318 227.56Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M203.428 230.304C203.428 230.304 215.605 228.665 215.895 229.648C216.139 230.388 217.075 237.055 217.281 239.209C217.285 239.283 217.266 239.356 217.226 239.418C217.186 239.479 217.127 239.527 217.058 239.553C216.989 239.579 216.914 239.583 216.843 239.563C216.772 239.543 216.709 239.501 216.663 239.443L213.272 233.581L204.084 234.237C204.084 234.237 201.461 231.942 203.428 230.304Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M102.765 193.585C102.765 193.585 95.2157 199.157 97.8383 212.932C100.461 226.708 118.829 264.438 120.468 267.678C122.107 270.918 129.319 271.283 132.598 270.3C135.876 269.317 194.267 246.045 194.267 246.045C194.267 246.045 200.496 244.397 201.48 243.086C202.463 241.775 208.701 234.892 208.701 234.892C208.701 234.892 218.536 236.203 220.831 235.548C223.126 234.892 218.536 234.237 216.241 233.581C214.471 233.076 204.711 229.264 200.318 227.541C199.685 227.286 198.991 227.225 198.323 227.367C197.656 227.509 197.046 227.847 196.572 228.337C193.499 231.484 186.952 238.16 185.706 239.153C184.067 240.464 137.834 248.34 137.834 248.34C137.834 248.34 127.006 197.518 122.744 193.257C118.482 188.996 110.942 189.98 110.942 189.98" fill="#FECC00"/>
                <path d="M102.765 193.585C102.765 193.585 95.2157 199.157 97.8383 212.932C100.461 226.708 118.829 264.438 120.468 267.678C122.107 270.918 129.319 271.283 132.598 270.3C135.876 269.317 194.267 246.045 194.267 246.045C194.267 246.045 200.496 244.397 201.48 243.086C202.463 241.775 208.701 234.892 208.701 234.892C208.701 234.892 218.536 236.203 220.831 235.548C223.126 234.892 218.536 234.237 216.241 233.581C214.471 233.076 204.711 229.264 200.318 227.541C199.685 227.286 198.991 227.225 198.323 227.367C197.656 227.509 197.046 227.847 196.572 228.337C193.499 231.484 186.952 238.16 185.706 239.153C184.067 240.464 137.834 248.34 137.834 248.34C137.834 248.34 127.006 197.518 122.744 193.257C118.482 188.996 110.942 189.98 110.942 189.98" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M163.751 258.163C179.065 252.095 194.267 246.045 194.267 246.045C194.267 246.045 200.496 244.397 201.48 243.086C202.463 241.775 208.692 234.892 208.692 234.892C208.692 234.892 218.536 236.203 220.869 235.548C223.201 234.892 218.574 234.237 216.279 233.581C214.509 233.075 204.749 229.264 200.356 227.541C199.723 227.286 199.028 227.225 198.361 227.367C197.693 227.509 197.083 227.846 196.609 228.337C193.537 231.483 186.99 238.16 185.744 239.153C184.891 239.837 171.975 242.29 159.77 244.491L163.751 258.163Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M123.156 305.005C123.156 305.005 106.755 299.536 99.1029 279.87C91.4503 260.205 78.8897 226.324 70.1413 221.941C61.3929 217.558 44.4581 225.762 43.3622 257.452C42.2663 289.141 44.8328 312.703 51.8952 320.494C58.1427 327.358 68.7551 336.105 105.594 336.723C138.499 337.285 151.93 324.746 156.492 320.494C161.054 316.242 168.509 307.159 162.505 307.159C156.501 307.159 130.809 307.159 123.156 305.005Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M198.885 110.502C198.885 110.502 192.488 103.011 183.599 101.615C174.71 100.22 174.111 113.181 185.472 118.865C188.659 120.331 192.191 120.882 195.673 120.457C195.673 120.457 186.596 131.179 192.469 132.893C205.582 136.751 202.772 116.542 202.772 116.542L198.885 110.502Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M201.039 109.987C201.039 109.987 197.293 100.894 200.103 92.335C202.913 83.7757 214.05 89.5912 213.937 102.299C213.894 105.587 213.014 108.81 211.38 111.664C211.38 111.664 225.674 108.62 223.913 114.473C219.913 127.78 204.655 116.205 204.655 116.205L201.039 109.987Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M206.004 121.346C205.841 121.346 205.682 121.304 205.54 121.224C205.399 121.143 205.281 121.027 205.198 120.888L196.309 105.96C196.182 105.748 196.144 105.494 196.204 105.253C196.263 105.013 196.416 104.806 196.628 104.677C196.734 104.613 196.852 104.571 196.975 104.553C197.097 104.534 197.222 104.541 197.343 104.571C197.463 104.602 197.576 104.656 197.675 104.73C197.774 104.805 197.858 104.898 197.92 105.005L206.8 119.932C206.927 120.145 206.966 120.399 206.906 120.64C206.846 120.88 206.694 121.087 206.482 121.215C206.336 121.299 206.172 121.344 206.004 121.346Z" fill="#263238"/>
                <path d="M197.827 107.262C197.827 107.262 194.623 100.51 188.938 99.7705" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M197.921 105.005C197.921 105.005 196.047 99.4427 197.471 95.8842" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M192.254 111.317C191.916 112.965 189.444 113.864 186.765 113.321C184.086 112.778 182.147 110.989 182.484 109.341C182.822 107.693 185.294 106.785 187.973 107.337C190.652 107.89 192.591 109.659 192.254 111.317Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M198.951 122.929C199.887 123.557 199.887 125.317 198.829 126.881C197.771 128.445 196.141 129.232 195.204 128.557C194.267 127.883 194.267 126.169 195.326 124.605C196.384 123.042 197.949 122.302 198.951 122.929Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M218.424 115.091C218.424 116.233 216.944 117.179 215.08 117.207C213.216 117.235 211.699 116.327 211.68 115.185C211.661 114.042 213.16 113.096 215.024 113.068C216.888 113.04 218.405 113.958 218.424 115.091Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M206.884 105.314C205.208 105.502 203.597 103.441 203.297 100.698C202.997 97.9537 204.112 95.6032 205.788 95.4159C207.465 95.2286 209.067 97.2888 209.367 100.033C209.666 102.776 208.561 105.127 206.884 105.314Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip1">
                    <rect width="442.881" height="419" fill="white" transform="translate(-44)"/>
                </clipPath>
            </defs>
        </svg>
    )
}