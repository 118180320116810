export  const Question8Img = (props) => {
    return <svg {...props} viewBox="0 0 487 371" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
            <path d="M287.396 420.754C287.396 420.754 238.193 430.725 193.423 420.754C166.041 414.658 108.37 400.203 94.7631 367.439C81.1563 334.674 146.684 306.722 114.478 273.484C100.598 259.154 33.9327 240.235 37.6819 147.227C41.4312 54.2199 139.101 -2.47531 274.832 0.08308C410.563 2.64147 488.897 125.96 436.397 186.719C383.897 247.479 361.307 247.658 385.108 297.141C408.909 346.624 375.861 406.594 287.396 420.754Z" fill="#F7F7F7"/>
            <path opacity="0.7" d="M287.396 420.754C287.396 420.754 238.193 430.725 193.423 420.754C166.041 414.658 108.37 400.203 94.7631 367.439C81.1563 334.674 146.684 306.722 114.478 273.484C100.598 259.154 33.9327 240.235 37.6819 147.227C41.4312 54.2199 139.101 -2.47531 274.832 0.08308C410.563 2.64147 488.897 125.96 436.397 186.719C383.897 247.479 361.307 247.658 385.108 297.141C408.909 346.624 375.861 406.594 287.396 420.754Z" fill="white"/>
        </g>
        <path opacity="0.5" d="M334.788 153.892C334.788 153.892 349.132 137.457 370.058 137.457C370.058 137.457 370.753 124.686 385.034 121.928C399.315 119.169 402.085 131.919 402.085 131.919C402.085 131.919 412.616 126.328 426.813 131.919C441.009 137.51 438.103 153.85 438.103 153.85C438.103 153.85 478.986 152.513 486 159.998H302.277C302.277 159.998 305.984 150.839 334.788 153.892Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M334.788 153.892C334.788 153.892 349.132 137.457 370.058 137.457C370.058 137.457 370.753 124.686 385.034 121.928C399.315 119.169 402.085 131.919 402.085 131.919C402.085 131.919 412.616 126.328 426.813 131.919C441.009 137.51 438.103 153.85 438.103 153.85C438.103 153.85 478.986 152.513 486 159.998H302.277C302.277 159.998 305.984 150.839 334.788 153.892Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.5" d="M50.2031 55.0933C50.2031 55.0933 64.5472 38.6586 85.4629 38.6586C85.4629 38.6586 86.1579 25.8877 100.439 23.1397C114.72 20.3918 117.5 33.1206 117.5 33.1206C117.5 33.1206 128.031 27.5301 142.218 33.1206C156.404 38.7112 153.518 55.0512 153.518 55.0512C153.518 55.0512 194.391 53.7141 201.405 61.2103H17.6816C17.6816 61.2103 21.3888 52.0401 50.2031 55.0933Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50.2031 55.0933C50.2031 55.0933 64.5472 38.6586 85.4629 38.6586C85.4629 38.6586 86.1579 25.8877 100.439 23.1397C114.72 20.3918 117.5 33.1206 117.5 33.1206C117.5 33.1206 128.031 27.5301 142.218 33.1206C156.404 38.7112 153.518 55.0512 153.518 55.0512C153.518 55.0512 194.391 53.7141 201.405 61.2103H17.6816C17.6816 61.2103 21.3888 52.0401 50.2031 55.0933Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.5" d="M379.8 266.124C379.8 266.124 368.352 253.017 351.67 253.017C351.67 253.017 351.112 242.836 339.727 240.635C328.343 238.435 326.121 248.605 326.121 248.605C326.121 248.605 317.695 244.141 306.405 248.605C295.116 253.069 297.39 266.093 297.39 266.093C297.39 266.093 264.743 265.04 259.192 271.009H405.697C405.697 271.009 402.769 263.692 379.8 266.124Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M379.8 266.124C379.8 266.124 368.352 253.017 351.67 253.017C351.67 253.017 351.112 242.836 339.727 240.635C328.343 238.435 326.121 248.605 326.121 248.605C326.121 248.605 317.695 244.141 306.405 248.605C295.116 253.069 297.39 266.093 297.39 266.093C297.39 266.093 264.743 265.04 259.192 271.009H405.697C405.697 271.009 402.769 263.692 379.8 266.124Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.5" d="M121.629 232.644C121.629 232.644 110.181 219.536 93.4988 219.536C93.4988 219.536 92.9407 209.345 81.556 207.155C70.1714 204.965 67.9492 215.114 67.9492 215.114C67.9492 215.114 59.524 210.661 48.2341 215.114C36.9443 219.568 39.198 232.612 39.198 232.612C39.198 232.612 6.55014 231.559 1 237.519H147.557C147.557 237.519 144.598 230.212 121.629 232.644Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M121.629 232.644C121.629 232.644 110.181 219.536 93.4988 219.536C93.4988 219.536 92.9407 209.345 81.556 207.155C70.1714 204.965 67.9492 215.114 67.9492 215.114C67.9492 215.114 59.524 210.661 48.2341 215.114C36.9443 219.568 39.198 232.612 39.198 232.612C39.198 232.612 6.55014 231.559 1 237.519H147.557C147.557 237.519 144.598 230.212 121.629 232.644Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.5" d="M157.836 179.739C157.836 179.739 164.64 171.948 174.55 171.948C174.733 170.103 175.532 168.373 176.817 167.036C178.103 165.699 179.801 164.834 181.638 164.578C188.41 163.273 189.726 169.306 189.726 169.306C191.575 168.506 193.567 168.093 195.582 168.093C197.596 168.093 199.589 168.506 201.437 169.306C208.146 171.948 206.787 179.697 206.787 179.697C206.787 179.697 226.155 179.055 229.472 182.613H142.408C142.408 182.613 144.198 178.286 157.836 179.739Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M157.836 179.739C157.836 179.739 164.64 171.948 174.55 171.948C174.733 170.103 175.532 168.373 176.817 167.036C178.103 165.699 179.801 164.834 181.638 164.578C188.41 163.273 189.726 169.306 189.726 169.306C191.575 168.506 193.567 168.093 195.582 168.093C197.596 168.093 199.589 168.506 201.437 169.306C208.146 171.948 206.787 179.697 206.787 179.697C206.787 179.697 226.155 179.055 229.472 182.613H142.408C142.408 182.613 144.198 178.286 157.836 179.739Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M243.511 36.0376C205.829 39.8383 163.713 48.3031 163.713 48.3031L158.1 46.8502C184.871 36.3219 243.511 20.8557 243.511 20.8557C243.511 20.8557 302.151 36.3324 328.922 46.8502L323.308 48.3031C323.308 48.3031 281.193 39.8383 243.511 36.0376Z" fill="#FECC00"/>
        <path d="M243.511 36.0376C205.829 39.8383 163.713 48.3031 163.713 48.3031L158.1 46.8502C184.871 36.3219 243.511 20.8557 243.511 20.8557C243.511 20.8557 302.151 36.3324 328.922 46.8502L323.308 48.3031C323.308 48.3031 281.193 39.8383 243.511 36.0376Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M312.882 42.8071C316.642 44.0179 320.16 45.2286 323.309 46.3973" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M251.314 26.0356C264.1 29.131 290.081 35.6165 310.291 41.9756" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M186.798 39.2063C206.324 33.5316 234.19 25.6563 243.511 24.1824" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M172.496 43.3966L182.175 40.554" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M166.514 45.176L168.494 44.5864" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M275.632 70.9287C275.632 70.9287 373.966 86.4686 471.678 106.957C470.781 107.928 469.698 108.709 468.493 109.252C467.288 109.794 465.985 110.088 464.664 110.115C459.84 110.4 373.039 103.398 288.081 99.45L275.632 70.9287Z" fill="#FECC00"/>
        <path d="M275.632 70.9287C275.632 70.9287 373.966 86.4686 471.678 106.957C470.781 107.928 469.698 108.709 468.493 109.252C467.288 109.794 465.985 110.088 464.664 110.115C459.84 110.4 373.039 103.398 288.081 99.45L275.632 70.9287Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M302.835 100.102C302.835 100.102 308.238 95.9542 309.839 94.8909C311.439 93.8275 310.713 107.167 307.795 108.167C304.878 109.167 302.835 100.102 302.835 100.102Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M365.056 103.63C365.056 103.63 368.847 99.6081 369.869 99.7239C370.89 99.8397 369.152 110.452 367.467 110.694C365.782 110.937 365.056 103.63 365.056 103.63Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M211.39 70.9287C211.39 70.9287 113.056 86.4686 15.3442 106.957C16.2404 107.928 17.3236 108.709 18.5288 109.252C19.7341 109.794 21.0366 110.088 22.3583 110.115C27.1817 110.4 113.983 103.398 198.941 99.45L211.39 70.9287Z" fill="#FECC00"/>
        <path d="M211.39 70.9287C211.39 70.9287 113.056 86.4686 15.3442 106.957C16.2404 107.928 17.3236 108.709 18.5288 109.252C19.7341 109.794 21.0366 110.088 22.3583 110.115C27.1817 110.4 113.983 103.398 198.941 99.45L211.39 70.9287Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M184.186 100.102C184.186 100.102 178.784 95.9542 177.183 94.8909C175.582 93.8275 176.309 107.167 179.226 108.167C182.143 109.167 184.186 100.102 184.186 100.102Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M121.966 103.63C121.966 103.63 118.174 99.6081 117.153 99.7239C116.131 99.8397 117.869 110.452 119.554 110.694C121.239 110.937 121.966 103.63 121.966 103.63Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M294.726 82.878L286.132 81.9094" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M306.194 84.3099L299.012 83.436" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M441.757 104.556C398.577 97.5127 354.345 91.1957 311.229 84.8892" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M451.867 106.135L446.77 105.367" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M461.409 109.81C461.841 109.188 462.283 108.578 462.725 107.957L457.396 107.062" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M347.489 120.778C356.642 117.241 361.194 106.957 357.656 97.8077C354.119 88.6579 343.832 84.1071 334.68 87.6432C325.527 91.1793 320.975 101.463 324.512 110.613C328.049 119.763 338.336 124.314 347.489 120.778Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M337.137 90.2901C339.564 89.3011 342.231 89.0538 344.799 89.5793C347.367 90.1048 349.722 91.3795 351.566 93.2424C353.409 95.1054 354.659 97.4729 355.158 100.046C355.656 102.619 355.38 105.281 354.365 107.698C353.35 110.114 351.641 112.175 349.455 113.621C347.268 115.067 344.702 115.832 342.081 115.82C339.459 115.809 336.9 115.02 334.727 113.555C332.553 112.09 330.863 110.013 329.87 107.588C329.209 105.976 328.873 104.249 328.881 102.506C328.889 100.763 329.24 99.0395 329.915 97.4328C330.59 95.8261 331.575 94.3683 332.814 93.1427C334.054 91.917 335.522 90.9477 337.137 90.2901Z" fill="#263238"/>
        <g opacity="0.5">
            <path opacity="0.5" d="M341.56 101.619C341.56 101.619 342.719 97.2498 346.731 97.1655" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M339.906 106.241C339.906 106.241 338.748 110.6 334.735 110.695" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M343.035 104.756C343.035 104.756 347.405 105.914 347.5 109.926" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M338.422 103.104C338.422 103.104 334.051 101.945 333.967 97.9341" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M342.95 102.883C342.95 102.883 346.857 100.609 349.754 103.388" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M338.516 104.978C338.516 104.978 334.609 107.241 331.702 104.472" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M341.781 106.146C341.781 106.146 344.045 110.052 341.275 112.948" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M339.686 101.714C339.686 101.714 337.411 97.8082 340.181 94.9023" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M336.188 92.4279C336.188 92.4279 342.655 88.5956 348.5 92.8596C355.63 98.0606 353.492 106.768 351.659 109.547" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path d="M401.169 127.856C410.039 127.856 417.229 120.667 417.229 111.8C417.229 102.933 410.039 95.7441 401.169 95.7441C392.299 95.7441 385.108 102.933 385.108 111.8C385.108 120.667 392.299 127.856 401.169 127.856Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M405.57 121.825C411.927 119.961 415.568 113.297 413.703 106.942C411.838 100.587 405.173 96.9469 398.816 98.8113C392.459 100.676 388.817 107.339 390.682 113.694C392.547 120.049 399.213 123.689 405.57 121.825Z" fill="#263238"/>
        <g opacity="0.5">
            <path opacity="0.5" d="M401.611 109.462C401.611 109.462 402.664 105.514 406.287 105.43" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M400.115 113.643C400.115 113.643 399.062 117.591 395.439 117.675" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M402.949 112.294C402.949 112.294 406.898 113.347 406.982 116.98" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M398.778 110.81C398.778 110.81 394.818 109.757 394.745 106.135" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M402.864 110.631C402.864 110.631 406.403 108.589 409.025 111.094" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M398.863 112.505C398.863 112.505 395.324 114.548 392.702 112.042" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M401.811 113.559C401.811 113.559 403.865 117.096 401.358 119.718" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M399.916 109.579C399.916 109.579 397.862 106.052 400.369 103.42" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M396.757 101.155C396.757 101.155 402.602 97.681 407.889 101.545C414.334 106.251 412.396 114.126 410.722 116.632" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path d="M192.295 82.878L200.889 81.9094" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M180.827 84.3099L188.01 83.436" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M45.2642 104.556C88.4436 97.5127 132.676 91.1957 175.793 84.8892" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M35.1538 106.135L40.2511 105.367" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.6123 109.81C25.1806 109.188 24.7382 108.578 24.2959 107.957L29.6249 107.062" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M160.863 113.884C166.202 105.654 163.858 94.6556 155.626 89.3174C147.394 83.9791 136.391 86.323 131.051 94.5526C125.712 102.782 128.056 113.781 136.288 119.119C144.52 124.458 155.523 122.114 160.863 113.884Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M150.314 114.652C156.985 111.632 159.944 103.778 156.924 97.1092C153.903 90.4403 146.047 87.4819 139.376 90.5014C132.705 93.5209 129.745 101.375 132.766 108.044C135.786 114.713 143.643 117.671 150.314 114.652Z" fill="#263238"/>
        <g opacity="0.5">
            <path opacity="0.5" d="M145.462 101.619C145.462 101.619 144.304 97.2498 140.291 97.1655" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M147.116 106.241C147.116 106.241 148.274 110.6 152.287 110.694" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M143.988 104.756C143.988 104.756 139.617 105.914 139.522 109.926" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M148.601 103.104C148.601 103.104 152.971 101.945 153.055 97.9341" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M144.072 102.882C144.072 102.882 140.165 100.608 137.269 103.388" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M148.506 104.977C148.506 104.977 152.413 107.241 155.32 104.472" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M145.241 106.146C145.241 106.146 142.977 110.052 145.747 112.947" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M147.336 101.714C147.336 101.714 149.611 97.8082 146.841 94.9023" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M150.833 92.4274C150.833 92.4274 144.367 88.5951 138.522 92.8591C131.392 98.0601 133.53 106.767 135.362 109.547" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path d="M85.8531 127.856C94.7232 127.856 101.914 120.667 101.914 111.8C101.914 102.933 94.7232 95.7441 85.8531 95.7441C76.9831 95.7441 69.7925 102.933 69.7925 111.8C69.7925 120.667 76.9831 127.856 85.8531 127.856Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M81.9878 98.6706C84.2916 98.1041 86.7118 98.2333 88.9422 99.0418C91.1726 99.8503 93.113 101.302 94.5182 103.213C95.9234 105.124 96.7301 107.409 96.8363 109.778C96.9426 112.148 96.3437 114.495 95.1152 116.524C93.8868 118.553 92.084 120.173 89.9349 121.178C87.7859 122.183 85.387 122.528 83.0417 122.17C80.6964 121.811 78.51 120.766 76.7589 119.166C75.0079 117.565 73.7709 115.482 73.2044 113.179C72.4448 110.09 72.9435 106.827 74.5906 104.106C76.2378 101.385 78.8986 99.4302 81.9878 98.6706Z" fill="#263238"/>
        <g opacity="0.5">
            <path opacity="0.5" d="M85.4109 109.462C85.4109 109.462 84.3577 105.514 80.7349 105.43" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M86.9067 113.643C86.9067 113.643 87.9599 117.591 91.5828 117.675" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M84.0731 112.294C84.0731 112.294 80.1238 113.347 80.0396 116.98" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M88.2441 110.81C88.2441 110.81 92.204 109.757 92.2777 106.135" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M84.1576 110.631C84.1576 110.631 80.6189 108.589 77.9966 111.094" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M88.1602 112.505C88.1602 112.505 91.6988 114.548 94.3211 112.042" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M85.2111 113.559C85.2111 113.559 83.1048 117.096 85.664 119.718" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M87.1062 109.579C87.1062 109.579 89.2125 106.052 86.6533 103.42" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.5" d="M90.2663 101.155C90.2663 101.155 84.4213 97.681 79.1345 101.545C72.6891 106.251 74.6269 114.126 76.3015 116.632" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path d="M243.364 23.3191H243.648C244.014 23.3191 244.377 23.3913 244.716 23.5315C245.055 23.6717 245.362 23.8772 245.621 24.1363C245.881 24.3953 246.086 24.7029 246.226 25.0414C246.367 25.3799 246.439 25.7427 246.439 26.1091V57.7784H240.573V26.1091C240.573 25.7427 240.645 25.3799 240.785 25.0414C240.925 24.7029 241.131 24.3953 241.39 24.1363C241.649 23.8772 241.957 23.6717 242.296 23.5315C242.634 23.3913 242.997 23.3191 243.364 23.3191Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M286.848 107.957L243.511 112.094L200.174 107.957C200.174 107.957 187.694 126.424 187.989 131.435C188.283 136.447 192.959 142.1 243.511 142.1C294.063 142.1 298.781 136.436 299.034 131.435C299.286 126.434 286.848 107.957 286.848 107.957Z" fill="#FECC00"/>
        <path opacity="0.2" d="M294.726 136.952C288.87 139.837 275.19 142.058 243.511 142.058C214.496 142.058 200.584 140.195 193.949 137.658C187.399 134.667 201.774 129.235 201.774 129.235C201.774 129.235 199.036 125.129 203.27 119.696C207.503 114.264 286.985 120.749 286.985 120.749C286.985 120.749 287.691 124.234 285.3 129.256C285.279 129.256 296.221 131.267 294.726 136.952Z" fill="black" fillOpacity="0.7"/>
        <path d="M286.848 107.957L243.511 112.094L200.174 107.957C200.174 107.957 187.694 126.424 187.989 131.435C188.283 136.447 192.959 142.1 243.511 142.1C294.063 142.1 298.781 136.436 299.034 131.435C299.286 126.434 286.848 107.957 286.848 107.957Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M243.511 49.2827C236.689 49.2762 229.967 50.9174 223.916 54.0667C217.865 57.2159 212.664 61.78 208.757 67.3705C188.842 95.9866 187.694 142.122 243.511 142.122C299.329 142.122 298.202 96.0076 278.265 67.3705C274.358 61.78 269.158 57.2159 263.107 54.0667C257.056 50.9174 250.333 49.2762 243.511 49.2827Z" fill="#FECC00"/>
        <path d="M243.511 49.2827C236.689 49.2762 229.967 50.9174 223.916 54.0667C217.865 57.2159 212.664 61.78 208.757 67.3705C188.842 95.9866 187.694 142.122 243.511 142.122C299.329 142.122 298.202 96.0076 278.265 67.3705C274.358 61.78 269.158 57.2159 263.107 54.0667C257.056 50.9174 250.333 49.2762 243.511 49.2827V49.2827Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M220.088 67.0326C217.98 70.5443 216.297 74.2941 215.075 78.2032C218.561 76.4028 222.553 74.6551 225.607 74.1497C226.065 71.3129 226.67 68.5016 227.418 65.7271C224.838 66.0745 222.363 66.5061 220.088 67.0326Z" fill="#263238"/>
        <path d="M266.933 67.0326C264.658 66.5061 262.183 66.0745 259.561 65.7271C260.309 68.4328 260.92 71.4545 261.372 74.1497C265.058 74.9939 268.604 76.3588 271.904 78.2032C270.695 74.2963 269.026 70.5466 266.933 67.0326Z" fill="#263238"/>
        <path d="M258.107 65.549C253.639 65.0315 249.146 64.7608 244.648 64.7383V75.0666C248.587 74.8666 254.074 73.7611 259.961 74.0138C259.308 70.6236 258.697 67.7599 258.107 65.549Z" fill="#263238"/>
        <path d="M273.062 78.7719L277.738 79.7405C276.174 75.6793 274.093 71.8364 271.546 68.3067C270.493 67.9383 269.271 67.6119 268.028 67.2539C270.127 70.8954 271.815 74.7584 273.062 78.7719Z" fill="#263238"/>
        <path d="M218.993 67.2959C217.803 67.5828 216.628 67.9343 215.476 68.3487C215.476 68.3487 211.99 72.1179 209.283 79.7825L213.959 78.8139C215.206 74.8004 216.895 70.9374 218.993 67.2959Z" fill="#263238"/>
        <path d="M242.847 64.7271C238.191 64.7479 233.54 65.022 228.914 65.5483C228.324 67.7592 227.671 70.6124 227.061 73.9709C233.179 73.7183 238.877 74.9185 242.858 75.0238L242.847 64.7271Z" fill="#263238"/>
        <path d="M243.511 130.54C241.299 130.382 235.254 129.824 232.61 128.551L234.095 134.868C237.086 135.974 240.259 136.509 243.447 136.447L243.511 136.005L243.574 136.447C246.762 136.509 249.935 135.974 252.926 134.868L254.411 128.551C251.767 129.824 245.722 130.382 243.511 130.54Z" fill="#263238"/>
        <path d="M244.406 131.267H242.616V145.28H244.406V131.267Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M243.511 149.312C245.122 149.312 246.428 148.006 246.428 146.396C246.428 144.785 245.122 143.479 243.511 143.479C241.899 143.479 240.593 144.785 240.593 146.396C240.593 148.006 241.899 149.312 243.511 149.312Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M240.067 138.226H235.696C234.853 138.226 234.169 138.909 234.169 139.752V153.618C234.169 154.461 234.853 155.145 235.696 155.145H240.067C240.91 155.145 241.594 154.461 241.594 153.618V139.752C241.594 138.909 240.91 138.226 240.067 138.226Z" fill="#263238"/>
        <path d="M251.336 138.226H246.965C246.122 138.226 245.438 138.909 245.438 139.752V153.618C245.438 154.461 246.122 155.145 246.965 155.145H251.336C252.179 155.145 252.863 154.461 252.863 153.618V139.752C252.863 138.909 252.179 138.226 251.336 138.226Z" fill="#263238"/>
        <path d="M270.429 138.415C270.429 138.415 283.889 136.899 288.607 133.941L287.554 137.099C281.153 138.917 274.56 139.977 267.912 140.258L270.429 138.415Z" fill="#263238"/>
        <path d="M216.592 138.415C216.592 138.415 203.133 136.899 198.415 133.941L199.468 137.099C205.869 138.917 212.461 139.977 219.109 140.258L216.592 138.415Z" fill="#263238"/>
        <path d="M285.279 147.228H283.773V137.174L285.279 136.826V147.228Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M276.338 147.228H274.832V138.458L276.338 138.268V147.228Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M286.964 148.164C286.966 148.647 286.825 149.12 286.558 149.523C286.291 149.926 285.911 150.241 285.465 150.427C285.02 150.614 284.529 150.665 284.054 150.572C283.58 150.48 283.143 150.249 282.8 149.909C282.457 149.569 282.222 149.135 282.126 148.662C282.03 148.188 282.076 147.697 282.259 147.25C282.442 146.803 282.754 146.42 283.154 146.15C283.555 145.88 284.027 145.734 284.51 145.732C284.831 145.731 285.149 145.793 285.446 145.914C285.743 146.036 286.013 146.214 286.241 146.44C286.469 146.666 286.65 146.935 286.774 147.23C286.898 147.526 286.962 147.843 286.964 148.164Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M278.023 148.164C278.025 148.647 277.884 149.12 277.617 149.523C277.35 149.926 276.97 150.241 276.524 150.427C276.079 150.614 275.588 150.665 275.113 150.572C274.639 150.48 274.203 150.249 273.859 149.909C273.516 149.569 273.282 149.135 273.185 148.662C273.089 148.188 273.135 147.697 273.318 147.25C273.501 146.803 273.813 146.42 274.213 146.15C274.614 145.88 275.086 145.734 275.569 145.732C276.216 145.732 276.837 145.988 277.297 146.443C277.756 146.899 278.017 147.517 278.023 148.164Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M281.972 141.343H278.128C277.477 141.343 276.949 141.87 276.949 142.522V154.303C276.949 154.954 277.477 155.482 278.128 155.482H281.972C282.624 155.482 283.152 154.954 283.152 154.303V142.522C283.152 141.87 282.624 141.343 281.972 141.343Z" fill="#263238"/>
        <path d="M291.156 141.343H287.312C286.66 141.343 286.132 141.87 286.132 142.522V154.303C286.132 154.954 286.66 155.482 287.312 155.482H291.156C291.807 155.482 292.335 154.954 292.335 154.303V142.522C292.335 141.87 291.807 141.343 291.156 141.343Z" fill="#263238"/>
        <path d="M272.799 141.343H268.955C268.303 141.343 267.775 141.87 267.775 142.522V154.303C267.775 154.954 268.303 155.482 268.955 155.482H272.799C273.45 155.482 273.978 154.954 273.978 154.303V142.522C273.978 141.87 273.45 141.343 272.799 141.343Z" fill="#263238"/>
        <path d="M201.742 147.228H203.248V137.174L201.742 136.826V147.228Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M210.684 147.228H212.19V138.458L210.684 138.268V147.228Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M200.058 148.164C200.056 148.647 200.197 149.12 200.464 149.523C200.73 149.926 201.111 150.241 201.556 150.427C202.002 150.614 202.493 150.665 202.967 150.572C203.442 150.48 203.878 150.249 204.221 149.909C204.564 149.569 204.799 149.135 204.895 148.662C204.992 148.188 204.945 147.697 204.762 147.25C204.579 146.803 204.268 146.42 203.867 146.15C203.467 145.88 202.995 145.734 202.512 145.732C202.191 145.731 201.873 145.793 201.576 145.914C201.279 146.036 201.009 146.214 200.781 146.44C200.553 146.666 200.372 146.935 200.248 147.23C200.124 147.526 200.059 147.843 200.058 148.164Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M208.999 148.164C208.996 148.647 209.138 149.12 209.404 149.523C209.671 149.926 210.051 150.241 210.497 150.427C210.943 150.614 211.434 150.665 211.908 150.572C212.383 150.48 212.819 150.249 213.162 149.909C213.505 149.569 213.74 149.135 213.836 148.662C213.933 148.188 213.886 147.697 213.703 147.25C213.52 146.803 213.209 146.42 212.808 146.15C212.407 145.88 211.936 145.734 211.452 145.732C210.805 145.732 210.184 145.988 209.725 146.443C209.265 146.899 209.004 147.517 208.999 148.164Z" fill="#757575" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M208.882 141.343H205.038C204.387 141.343 203.859 141.87 203.859 142.522V154.303C203.859 154.954 204.387 155.482 205.038 155.482H208.882C209.534 155.482 210.062 154.954 210.062 154.303V142.522C210.062 141.87 209.534 141.343 208.882 141.343Z" fill="#263238"/>
        <path d="M199.71 141.343H195.866C195.215 141.343 194.687 141.87 194.687 142.522V154.303C194.687 154.954 195.215 155.482 195.866 155.482H199.71C200.362 155.482 200.89 154.954 200.89 154.303V142.522C200.89 141.87 200.362 141.343 199.71 141.343Z" fill="#263238"/>
        <path d="M218.067 141.343H214.223C213.571 141.343 213.043 141.87 213.043 142.522V154.303C213.043 154.954 213.571 155.482 214.223 155.482H218.067C218.718 155.482 219.246 154.954 219.246 154.303V142.522C219.246 141.87 218.718 141.343 218.067 141.343Z" fill="#263238"/>
        <path d="M244.648 35.3113V49.2824" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.648 26.4041V30.3943" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M314.862 253.27L311.597 254.438C311.329 254.535 311.044 254.579 310.759 254.566C310.474 254.552 310.194 254.483 309.936 254.362C309.678 254.241 309.446 254.07 309.254 253.859C309.062 253.648 308.913 253.401 308.817 253.133L281.771 177.518C281.771 177.518 282.361 176.191 285.089 175.212C286.363 174.668 287.78 174.557 289.123 174.896L316.178 250.501C316.279 250.758 316.327 251.034 316.321 251.31C316.315 251.587 316.255 251.86 316.143 252.113C316.032 252.366 315.871 252.595 315.671 252.786C315.471 252.977 315.236 253.127 314.978 253.227L314.862 253.27Z" fill="#263238"/>
        <path d="M289.123 174.854L306.858 224.421C305.514 224.085 304.097 224.2 302.824 224.748C300.097 225.727 299.496 227.053 299.496 227.053L281.761 177.486C281.761 177.486 282.362 176.159 285.089 175.18C286.362 174.632 287.779 174.518 289.123 174.854Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M300.561 213.693L301.666 216.662" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M286.837 176.886C290.917 187.835 294.999 198.781 299.086 209.724" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M310.386 257.481C310.386 257.481 307.774 249.058 305.584 247.731C303.046 246.236 301.234 243.425 301.761 241.541C302.719 238.172 300.581 236.603 302.582 235.676C302.582 235.676 306.353 240.004 306.858 239.024C310.386 232.076 308.185 228.191 308.185 228.191C312.735 225.969 312.672 228.949 315.168 230.202C317.664 231.454 316.905 231.096 318.496 233.539C319.549 235.129 319.338 236.455 320.602 237.487C321.866 238.519 321.297 242.593 321.086 244.857C320.876 247.121 320.391 250.374 320.391 250.374L322.066 254.533L310.386 257.481Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M278.434 315.471C278.434 315.471 300.381 303.5 304.762 301.868L309.207 300.247C309.207 300.247 311.155 295.53 313.103 294.983C313.103 294.983 309.765 280.243 309.765 275.821V259.849L308.796 255.638C308.796 255.638 315.326 249.395 321.297 251.89L324.214 257.586C325.879 258.133 327.395 259.056 328.646 260.283C329.897 261.51 330.849 263.008 331.428 264.661C333.651 270.494 340.907 292.035 342.265 301.668C343.624 311.302 278.381 357.405 278.381 357.405L278.434 315.471Z" fill="white"/>
        <path opacity="0.2" d="M318.285 326.662L317.463 327.947C299.56 342.56 278.497 357.426 278.497 357.426V315.471L279.55 314.913C280.966 314.988 282.375 315.174 283.762 315.471C292.999 317.913 294.294 338.844 294.294 338.844C302.74 336.106 317.8 326.947 318.285 326.662Z" fill="black" fillOpacity="0.7"/>
        <path d="M278.434 315.471C278.434 315.471 300.381 303.5 304.762 301.868L309.207 300.247C309.207 300.247 311.155 295.53 313.103 294.983C313.103 294.983 309.765 280.243 309.765 275.821V259.849L308.796 255.638C308.796 255.638 315.326 249.395 321.297 251.89L324.214 257.586C325.879 258.133 327.395 259.056 328.646 260.283C329.897 261.51 330.849 263.008 331.428 264.661C333.651 270.494 340.907 292.035 342.265 301.668C343.624 311.302 278.381 357.405 278.381 357.405L278.434 315.471Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M292.462 323.083C292.462 323.083 294.705 336.012 284.837 350.794" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M309.259 300.247C309.259 300.247 314.336 299.404 318.295 301.868" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M313.156 294.972C313.156 294.972 318.496 292.867 319.128 288.518" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M313.778 260.818C313.778 260.818 319.823 256.933 324.309 257.66" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M172.159 253.27L175.424 254.438C175.692 254.535 175.977 254.579 176.262 254.566C176.547 254.552 176.827 254.483 177.085 254.362C177.343 254.241 177.575 254.07 177.767 253.859C177.959 253.648 178.108 253.401 178.204 253.133L205.249 177.518C205.249 177.518 204.66 176.191 201.932 175.212C200.658 174.668 199.241 174.557 197.898 174.896L170.843 250.501C170.742 250.758 170.694 251.034 170.7 251.31C170.706 251.587 170.766 251.86 170.878 252.113C170.989 252.366 171.15 252.595 171.35 252.786C171.55 252.977 171.785 253.127 172.043 253.227L172.159 253.27Z" fill="#263238"/>
        <path d="M197.899 174.854L180.164 224.421C181.508 224.085 182.925 224.2 184.198 224.748C186.925 225.727 187.526 227.053 187.526 227.053L205.261 177.486C205.261 177.486 204.66 176.159 201.933 175.18C200.66 174.632 199.243 174.518 197.899 174.854Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M186.461 213.693L185.355 216.662" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M200.184 176.886C196.105 187.835 192.022 198.781 187.936 209.724" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M176.635 257.481C176.635 257.481 179.247 249.058 181.437 247.731C183.976 246.236 185.787 243.425 185.26 241.541C184.302 238.172 186.44 236.603 184.439 235.676C184.439 235.676 180.669 240.004 180.163 239.024C176.635 232.076 178.836 228.191 178.836 228.191C174.287 225.969 174.35 228.949 171.854 230.202C169.358 231.454 170.116 231.096 168.526 233.539C167.473 235.129 167.683 236.455 166.419 237.487C165.156 238.519 165.724 242.593 165.935 244.857C166.146 247.121 166.63 250.374 166.63 250.374L164.956 254.533L176.635 257.481Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M208.588 315.471C208.588 315.471 186.64 303.5 182.206 301.868L177.762 300.247C177.762 300.247 175.814 295.53 173.865 294.983C173.865 294.983 177.204 280.243 177.204 275.821V259.849L178.173 255.638C178.173 255.638 171.643 249.395 165.672 251.89L162.755 257.586C161.09 258.133 159.574 259.056 158.323 260.283C157.072 261.51 156.12 263.008 155.541 264.661C153.318 270.494 146.062 292.035 144.704 301.668C143.345 311.302 208.588 357.405 208.588 357.405V315.471Z" fill="white"/>
        <path opacity="0.2" d="M208.135 315.229L208.588 315.471V357.447C208.588 357.447 180.321 337.465 161.364 321.061C192.338 340.497 191.221 334.538 191.221 334.538C194.002 320.514 199.962 315.471 199.962 315.471C199.962 315.471 203.08 315.366 208.135 315.229Z" fill="black" fillOpacity="0.7"/>
        <path d="M208.588 315.471C208.588 315.471 186.64 303.5 182.206 301.868L177.762 300.247C177.762 300.247 175.814 295.53 173.865 294.983C173.865 294.983 177.204 280.243 177.204 275.821V259.849L178.173 255.638C178.173 255.638 171.643 249.395 165.672 251.89L162.755 257.586C161.09 258.133 159.574 259.056 158.323 260.283C157.072 261.51 156.12 263.008 155.541 264.661C153.318 270.494 146.062 292.035 144.704 301.668C143.345 311.302 208.588 357.405 208.588 357.405V315.471Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M167.051 257.544C167.051 257.544 174.645 257.418 177.204 259.85" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M173.866 294.972C173.866 294.972 165.799 301.215 164.135 298.72C162.471 296.225 169 294.972 169 294.972" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M177.763 300.247C177.763 300.247 178.3 307.259 181.849 310.775" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M187.989 334.537C187.989 334.537 196.751 348.951 202.67 353.194" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M230.662 303.3C231.323 305.931 231.365 308.679 230.786 311.33C230.207 313.98 229.022 316.461 227.323 318.576C227.323 318.576 241.604 325.788 255.442 318.576C255.442 318.576 251.23 309.406 253.989 304.69C254.042 304.69 245.385 299.415 230.662 303.3Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M264.437 281.327C264.437 281.327 260.382 262.376 243.711 260.923C227.039 259.47 221.215 273.557 219.025 285.138L242.879 287.507L264.437 281.327Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M262.899 279.927C265.395 278.948 266.417 282.812 266.417 282.812C266.417 282.812 269.239 284.665 269.239 291.329C269.239 297.351 264.963 299.457 264.963 299.457C264.963 299.457 263.089 303.426 260.751 300.836C258.413 298.246 259.803 281.127 262.899 279.927Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M266.417 282.812C266.417 282.812 267.754 290.95 265.479 296.215L266.417 282.812Z" fill="#FECC00"/>
        <path d="M266.417 282.812C266.417 282.812 267.754 290.95 265.479 296.215" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M220.678 279.927C218.182 278.948 217.161 282.812 217.161 282.812C217.161 282.812 214.349 284.665 214.349 291.329C214.349 297.351 218.614 299.457 218.614 299.457C218.614 299.457 220.499 303.426 222.827 300.836C225.154 298.246 223.785 281.127 220.678 279.927Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M217.182 282.812C217.182 282.812 215.845 290.95 218.119 296.215L217.182 282.812Z" fill="#FECC00"/>
        <path d="M217.182 282.812C217.182 282.812 215.845 290.95 218.119 296.215" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M249.83 265.503C249.83 265.503 243.806 261.292 239.109 261.966C234.412 262.64 238.477 263.703 238.477 263.703C238.477 263.703 230.557 264.145 229.831 265.503C229.104 266.862 229.831 266.851 229.831 266.851C229.831 266.851 225.028 268.957 223.922 271.062C222.817 273.168 224.976 272.705 224.976 272.705C224.976 272.705 220.605 278.127 220.289 281.454C219.973 284.781 221.342 283.328 221.342 283.328C220.838 288.349 221.194 293.419 222.395 298.32C224.375 305.406 225.829 305.69 225.723 307.585C225.618 309.48 228.125 308.533 228.125 308.533C228.125 308.533 229.241 310.638 230.873 310.638C232.506 310.638 235.012 308.975 235.012 308.975C235.012 308.975 238.477 312.828 241.078 312.302C243.68 311.776 244.417 308.764 244.417 308.764C244.417 308.764 246.702 312.976 248.366 312.355C250.03 311.733 249.83 308.87 249.83 308.87C249.83 308.87 251.705 311.165 253.263 310.975C253.688 310.925 254.077 310.713 254.349 310.384C254.622 310.055 254.757 309.633 254.727 309.207C254.727 309.207 256.497 310.038 257.013 309.617C257.529 309.196 256.707 308.164 257.845 306.396C258.982 304.627 262.436 298.794 263.268 294.52C263.268 294.52 263.669 296.183 264.406 293.172C265.143 290.161 263.574 282.78 263.574 282.78C263.574 282.78 263.953 284.318 264.627 283.896C265.301 283.475 263.089 277.337 258.508 274.737C258.508 274.737 260.172 275.158 260.277 274.116C260.383 273.073 257.466 264.777 249.83 265.503Z" fill="#263238"/>
        <path d="M220.236 319.682C220.236 319.682 227.092 316.071 242.489 315.471C257.886 314.871 265.479 319.682 265.479 319.682C258.992 323.714 251.616 326.096 243.995 326.621C232.052 327.179 220.236 319.682 220.236 319.682Z" fill="#D5D5D5" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M198.615 307.669L221.553 318.04C221.553 318.04 226.555 320.451 240.51 321.062C254.464 321.672 266.839 315.471 266.839 315.471L286.849 309.028C286.849 309.028 293.041 339.676 283.921 372.998L287.386 420.755C287.386 420.755 238.351 429.914 193.413 420.755L198.12 374.661C198.12 374.661 192.644 331.232 198.615 307.669Z" fill="white"/>
        <path d="M221.552 318.04C221.552 318.04 238.087 327.305 247.881 326.652C257.676 325.999 263.12 321.061 263.12 321.061" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M283.699 355.573C283.699 355.573 284.879 366.891 283.931 372.998" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M197.961 373.746C197.961 373.746 198.646 356.195 199.962 350.794" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M193.422 420.755L198.13 374.661C198.13 374.661 192.643 331.232 198.614 307.669L221.552 318.04C221.552 318.04 226.555 320.451 240.509 321.062C254.463 321.672 266.838 315.471 266.838 315.471L286.848 309.028C286.848 309.028 293.041 339.676 283.92 372.998L287.385 420.755" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
        <g opacity="0.4">
            <path opacity="0.4" d="M251.209 275.252C249.894 273.219 248.114 271.529 246.014 270.323C243.915 269.116 241.558 268.427 239.14 268.314" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.4" d="M248.85 277.063C248.85 277.063 241.078 277.2 238.318 281.222" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.4" d="M250.24 279.137C250.24 279.137 247.081 284.559 247.881 288.518" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.4" d="M253.578 279.137C255.707 280.226 257.52 281.843 258.844 283.833" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.4" d="M254.126 275.252C254.126 275.252 254.126 271.136 257.464 271.789" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>

}