export const NegotiatorResult = (props) => {
    return (<svg {...props} viewBox="0 0 280 263" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M132.143 1.0472C209.601 -6.02532 209.757 38.7652 244.663 80.4963C279.569 122.227 287.059 141.154 273.941 189.24C260.823 237.327 216.404 275.051 161.54 253.687C106.676 232.322 75.2488 252.842 40.6426 233.845C6.03634 214.849 -6.83168 178.219 18.5171 135.016C43.8658 91.8123 36.6323 9.76465 132.143 1.0472Z" fill="#FDFDFD" fillOpacity="0.5"/>
            <path d="M108.406 235.241C107.721 232.512 106.089 230.133 103.815 228.549C101.542 226.965 98.7823 226.285 96.0543 226.636C93.3263 226.987 90.8167 228.345 88.9956 230.455C87.1745 232.566 86.167 235.284 86.1616 238.102C86.1703 239.081 86.2983 240.055 86.5427 241.001C87.1674 243.486 88.5787 245.688 90.5557 247.264C92.5327 248.839 94.9637 249.698 97.468 249.706C98.4223 249.705 99.3729 249.582 100.298 249.341C102.132 248.861 103.819 247.914 105.201 246.589C105.087 246.41 104.985 246.224 104.895 246.032C103.249 247.652 101.14 248.688 98.8765 248.989C96.6132 249.289 94.3155 248.839 92.3208 247.702L92.0584 247.555C90.2431 246.454 88.7869 244.827 87.8738 242.879C86.9608 240.93 86.6319 238.748 86.9287 236.609C87.2255 234.469 88.1347 232.467 89.5414 230.857C90.9481 229.246 92.7892 228.099 94.8319 227.56C96.5384 227.111 98.3268 227.102 100.037 227.534C101.748 227.966 103.328 228.824 104.636 230.033C105.944 231.243 106.94 232.765 107.535 234.464C108.129 236.163 108.304 237.987 108.044 239.772C108.25 239.734 108.459 239.711 108.668 239.702C108.866 238.211 108.777 236.696 108.406 235.241Z" fill="#263238"/>
            <path d="M104.889 246.051C103.759 247.153 102.464 248.063 101.053 248.745C100.782 248.867 100.502 248.968 100.216 249.046C99.927 249.121 99.6325 249.172 99.3355 249.2C96.2122 249.533 92.0332 247.92 92.0332 247.92L92.308 247.709C93.0836 247.085 93.9211 246.546 94.8067 246.102C95.4313 245.929 96.5495 245.129 96.056 243.401C94.226 241.879 92.9605 239.76 92.4704 237.397C92.0832 235.644 91.2211 232.245 94.7005 230.831C94.9788 230.724 95.2623 230.632 95.55 230.555C95.8499 230.478 96.1434 230.421 96.4183 230.376C100.166 229.89 100.972 233.301 101.459 235.023C102.14 237.339 102.025 239.824 101.134 242.063C101.522 243.817 102.859 243.984 103.502 243.811C103.814 243.763 104.131 243.753 104.445 243.779C104.406 244.275 104.449 244.775 104.57 245.257C104.638 245.526 104.73 245.787 104.845 246.038L104.889 246.051Z" fill="#263238"/>
            <path d="M108.806 248.912C107.935 248.912 107.082 248.661 106.344 248.189C105.605 247.716 105.011 247.041 104.628 246.24C104.245 245.438 104.09 244.543 104.179 243.656C104.268 242.768 104.598 241.924 105.132 241.219C105.666 240.515 106.382 239.978 107.199 239.669C108.016 239.361 108.901 239.293 109.753 239.475C110.606 239.656 111.392 240.079 112.021 240.695C112.651 241.311 113.1 242.095 113.316 242.96C113.49 243.664 113.504 244.399 113.359 245.11C113.214 245.821 112.912 246.489 112.478 247.063C112.043 247.637 111.486 248.101 110.85 248.422C110.214 248.742 109.515 248.91 108.806 248.912ZM108.806 240.015C108.466 240.015 108.128 240.061 107.8 240.15C106.825 240.413 105.985 241.047 105.45 241.922C104.915 242.798 104.725 243.849 104.919 244.862C105.114 245.876 105.677 246.776 106.495 247.379C107.314 247.982 108.325 248.243 109.325 248.11C110.324 247.976 111.237 247.458 111.877 246.66C112.517 245.862 112.836 244.844 112.77 243.813C112.704 242.783 112.257 241.816 111.521 241.111C110.785 240.405 109.814 240.014 108.806 240.015Z" fill="#263238"/>
            <path d="M108.537 243.952L107.781 242.121L108.799 241.852L108.98 243.836L110.448 242.524L111.011 243.427L109.243 244.24L110.904 245.245L110.242 246.077L108.943 244.592L108.499 246.512L107.538 246.115L108.518 244.412L106.582 244.572L106.651 243.504L108.537 243.952Z" fill="#263238"/>
            <path d="M100.947 230.632C97.7428 227.035 95.3691 229.704 95.3691 229.704C95.3691 229.704 92.0396 228.533 90.9152 233.269C89.7908 238.005 93.0641 241.724 90.2906 243.292C90.2906 243.292 93.8137 244.828 98.8235 243.51C103.833 242.191 106.201 239.113 106.201 239.113C103.04 239.081 104.158 234.223 100.947 230.632Z" fill="#263238"/>
            <path d="M278.776 125.524C279.185 123.643 278.913 121.675 278.011 119.984C277.109 118.294 275.638 116.998 273.872 116.336C272.106 115.674 270.166 115.693 268.412 116.388C266.659 117.084 265.211 118.408 264.34 120.115C264.044 120.71 263.819 121.34 263.672 121.991C263.373 123.332 263.419 124.73 263.807 126.048C264.194 127.365 264.909 128.557 265.882 129.506C266.855 130.455 268.051 131.128 269.355 131.46C270.658 131.792 272.023 131.771 273.317 131.399C273.307 131.254 273.307 131.109 273.317 130.964C272.098 131.335 270.807 131.377 269.569 131.086C267.742 130.666 266.146 129.537 265.115 127.937C265.072 127.879 265.032 127.82 264.996 127.757C264.484 126.926 264.138 126 263.978 125.031C263.821 124.057 263.848 123.062 264.059 122.099C264.494 120.167 265.658 118.489 267.297 117.433C268.936 116.378 270.916 116.03 272.804 116.467C273.407 116.605 273.99 116.82 274.541 117.107C275.476 117.602 276.296 118.299 276.944 119.148C277.593 119.998 278.055 120.98 278.299 122.03C278.544 123.079 278.564 124.17 278.36 125.228C278.155 126.286 277.731 127.286 277.114 128.161C277.258 128.202 277.398 128.256 277.533 128.321C278.122 127.481 278.544 126.53 278.776 125.524Z" fill="#263238"/>
            <path d="M273.316 130.964C272.29 131.271 271.224 131.409 270.156 131.374C269.946 131.359 269.737 131.329 269.531 131.284C269.333 131.237 269.139 131.175 268.95 131.099C266.951 130.299 264.89 127.962 264.89 127.962L265.127 127.924C265.796 127.792 266.477 127.736 267.157 127.758C267.343 127.793 267.534 127.788 267.718 127.743C267.902 127.698 268.075 127.614 268.225 127.497C268.375 127.379 268.499 127.23 268.59 127.06C268.68 126.89 268.735 126.703 268.75 126.509C268.1 124.993 267.977 123.294 268.4 121.696C268.706 120.506 269.206 118.15 271.773 118.413C271.983 118.436 272.192 118.47 272.398 118.515C272.606 118.566 272.8 118.624 272.979 118.688C275.403 119.59 274.853 121.939 274.628 123.168C274.333 124.797 273.505 126.274 272.279 127.354C272.21 127.534 272.178 127.727 272.187 127.921C272.195 128.114 272.244 128.304 272.33 128.476C272.416 128.649 272.536 128.801 272.684 128.922C272.831 129.043 273.002 129.13 273.185 129.178C273.389 129.249 273.584 129.344 273.766 129.46C273.593 129.751 273.466 130.069 273.391 130.401C273.35 130.588 273.327 130.779 273.323 130.971C273.323 130.971 273.316 130.958 273.316 130.964Z" fill="#263238"/>
            <path d="M274.828 133.966C274.318 133.698 273.887 133.296 273.579 132.801C273.298 132.346 273.132 131.827 273.094 131.289C273.056 130.752 273.149 130.214 273.363 129.722C273.577 129.231 273.906 128.801 274.322 128.47C274.737 128.14 275.225 127.921 275.743 127.83C276.261 127.74 276.793 127.782 277.292 127.953C277.79 128.124 278.239 128.419 278.6 128.811C278.961 129.202 279.221 129.679 279.359 130.199C279.496 130.719 279.506 131.265 279.388 131.79C279.296 132.209 279.124 132.604 278.882 132.955C278.64 133.305 278.333 133.603 277.979 133.832C277.624 134.06 277.229 134.214 276.816 134.286C276.403 134.357 275.98 134.344 275.572 134.248C275.313 134.186 275.063 134.092 274.828 133.966ZM277.564 128.551C277.356 128.442 277.136 128.358 276.908 128.302C276.466 128.199 276.006 128.21 275.569 128.334C275.131 128.457 274.731 128.688 274.402 129.008C274.072 129.328 273.825 129.726 273.682 130.166C273.538 130.607 273.503 131.077 273.579 131.535C273.655 131.993 273.84 132.425 274.117 132.793C274.395 133.16 274.757 133.452 275.17 133.642C275.584 133.832 276.037 133.915 276.489 133.884C276.942 133.852 277.38 133.707 277.764 133.46C278.072 133.263 278.338 133.005 278.548 132.701C278.758 132.398 278.908 132.055 278.988 131.693C279.069 131.33 279.079 130.955 279.018 130.588C278.957 130.221 278.826 129.871 278.632 129.556C278.371 129.129 278.002 128.781 277.564 128.551Z" fill="#263238"/>
            <path d="M276.184 130.855L276.277 129.492L276.977 129.652L276.477 130.932L277.777 130.606L277.845 131.335L276.515 131.258L277.221 132.411L276.565 132.699L276.227 131.374L275.359 132.417L274.897 131.866L276.015 131.143L274.766 130.618L275.141 129.978L276.184 130.855Z" fill="#263238"/>
            <path d="M275.64 120.294C274.791 117.094 272.517 117.933 272.517 117.933C272.517 117.933 270.849 116.153 268.713 118.675C266.576 121.197 267.432 124.519 265.258 124.589C266.679 126.014 268.458 127.008 270.396 127.46C272.335 127.911 274.357 127.804 276.24 127.149C274.322 126.132 276.496 123.52 275.64 120.294Z" fill="#263238"/>
            <path d="M194.066 16.5428C194.416 14.4092 194.176 12.2178 193.372 10.2172C192.568 8.21654 191.233 6.48686 189.518 5.22446C187.804 3.96206 185.778 3.21751 183.671 3.07532C181.564 2.93312 179.46 3.39898 177.599 4.42001C175.737 5.44105 174.192 6.97636 173.139 8.85168C172.086 10.727 171.567 12.8672 171.642 15.0294C171.716 17.1916 172.38 19.2891 173.559 21.084C174.738 22.8788 176.385 24.299 178.312 25.1834C179.184 25.5799 180.101 25.8637 181.042 26.0283C182.908 26.3543 184.824 26.1936 186.614 25.561C186.58 25.3491 186.56 25.1354 186.551 24.921C184.404 25.7152 182.065 25.7826 179.878 25.1132C177.691 24.4438 175.771 23.0729 174.401 21.2023C174.339 21.1255 174.277 21.0423 174.22 20.9591C173.407 19.7889 172.826 18.4668 172.509 17.0676C172.202 15.6654 172.164 14.2156 172.396 12.7985C172.63 11.3816 173.134 10.0258 173.879 8.80856C174.625 7.59131 175.597 6.53648 176.74 5.70434C177.884 4.87219 179.176 4.27904 180.543 3.95878C181.91 3.63852 183.326 3.59741 184.708 3.83783C185.596 3.98736 186.461 4.25413 187.282 4.63149C188.683 5.27411 189.932 6.21842 190.944 7.40013C191.956 8.58183 192.708 9.97318 193.149 11.4795C193.589 12.9857 193.708 14.5716 193.496 16.129C193.285 17.6864 192.748 19.1788 191.923 20.5047C192.136 20.5582 192.344 20.6266 192.548 20.7095C193.312 19.4341 193.827 18.0191 194.066 16.5428Z" fill="#263238"/>
            <path d="M186.551 24.921C185.082 25.4575 183.538 25.7492 181.979 25.785C181.683 25.7832 181.389 25.7575 181.098 25.7082C180.803 25.6578 180.513 25.5828 180.229 25.4842C177.256 24.4921 174.07 21.2599 174.07 21.2599L174.408 21.1831C175.372 20.9406 176.358 20.8012 177.35 20.7671C178.006 20.8823 179.33 20.6071 179.567 18.8469C178.501 16.6918 178.188 14.2271 178.68 11.864C179.024 10.1039 179.567 6.62842 183.322 6.79483C183.619 6.8121 183.915 6.84628 184.209 6.89724C184.499 6.94771 184.787 7.01394 185.071 7.09565C188.669 8.20294 188.069 11.6656 187.832 13.4385C187.525 15.8404 186.422 18.0615 184.708 19.7302C184.365 21.4967 185.52 22.2008 186.176 22.2904C186.481 22.3759 186.774 22.4984 187.051 22.6552C186.815 23.0911 186.655 23.5652 186.576 24.0569C186.533 24.3298 186.514 24.6062 186.52 24.8826C186.52 24.8826 186.557 24.9146 186.551 24.921Z" fill="#263238"/>
            <path d="M188.987 29.1454C188.239 28.8008 187.593 28.26 187.114 27.5775C186.635 26.8951 186.341 26.0953 186.26 25.2589C186.18 24.4225 186.316 23.5791 186.656 22.814C186.995 22.0489 187.526 21.3892 188.194 20.9015C188.945 20.3552 189.836 20.0492 190.757 20.0222C191.677 19.9953 192.584 20.2486 193.364 20.7501C194.144 21.2517 194.761 21.9789 195.137 22.8398C195.513 23.7008 195.632 24.6567 195.478 25.5867C195.27 26.8327 194.588 27.9434 193.582 28.6753C192.576 29.4072 191.328 29.7005 190.112 29.491C189.725 29.4226 189.347 29.3065 188.987 29.1454ZM192.535 21.0232C192.225 20.8799 191.897 20.7788 191.561 20.7223C190.555 20.5503 189.524 20.7772 188.676 21.3568C187.827 21.9364 187.227 22.8252 186.995 23.8425C186.764 24.8598 186.919 25.9293 187.43 26.8334C187.94 27.7375 188.768 28.4084 189.744 28.7098C190.72 29.0111 191.771 28.9202 192.685 28.4556C193.598 27.991 194.304 27.1875 194.659 26.2085C195.015 25.2295 194.993 24.1485 194.599 23.1853C194.204 22.2221 193.466 21.449 192.535 21.0232Z" fill="#263238"/>
            <path d="M190.717 24.5243V22.5273L191.748 22.7066L191.123 24.6267L192.997 24.0379L193.153 25.1003H191.211L192.329 26.7133L191.398 27.1933L190.773 25.2731L189.599 26.8605L188.875 26.0924L190.449 24.9467L188.618 24.3067L189.106 23.3658L190.717 24.5243Z" fill="#263238"/>
            <path d="M107.308 2.76297L89.6712 5.75796C89.0245 5.86777 88.5872 6.49392 88.6943 7.15651L90.4653 18.1056C90.5724 18.7682 91.1835 19.2163 91.8302 19.1065L109.467 16.1115C110.114 16.0017 110.551 15.3755 110.444 14.713L108.673 3.76387C108.566 3.10128 107.955 2.65316 107.308 2.76297Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M88.7538 7.55664L98.4111 11.8642C98.8357 12.0537 99.305 12.1129 99.7618 12.0345C100.219 11.9561 100.643 11.7435 100.985 11.4225L108.73 4.18359" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M92.0959 16.3061L96.3436 12.8179" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M108.324 13.5539L103.202 11.6338" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M182.379 230.335L167.004 221.176C166.44 220.84 165.718 221.036 165.39 221.614L159.974 231.158C159.646 231.736 159.838 232.476 160.401 232.812L175.776 241.971C176.34 242.307 177.063 242.111 177.39 241.533L182.806 231.989C183.134 231.411 182.943 230.671 182.379 230.335Z" stroke="#263238" strokeWidth="0.994786" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M165.138 223.63L169.879 233.231C170.083 233.657 170.403 234.014 170.802 234.257C171.2 234.5 171.658 234.619 172.121 234.601L182.628 234.076" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M162.264 232.559L167.711 232.642" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M176.494 241.039L173.733 236.226" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.7797 151.944L2.04461 160.64C1.46769 160.959 1.25224 161.696 1.5634 162.287L6.70522 172.056C7.01637 172.647 7.7363 172.867 8.31322 172.549L24.0483 163.853C24.6252 163.534 24.8407 162.797 24.5295 162.205L19.3877 152.437C19.0765 151.846 18.3566 151.625 17.7797 151.944Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.73221 162.646L12.2078 163.555C12.6696 163.591 13.1316 163.489 13.5383 163.262C13.945 163.035 14.279 162.692 14.5003 162.275L19.5476 152.822" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.66675 169.821L10.5777 165.104" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.1462 161.821L16.6992 161.725" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M31.2926 38.0226V218.548C31.2926 219.034 31.4807 219.5 31.8155 219.845C32.1504 220.189 32.6048 220.383 33.0792 220.385H140.209C140.684 220.385 141.14 220.191 141.476 219.847C141.813 219.502 142.001 219.035 142.001 218.548V38.0546C141.998 36.9454 141.567 35.8827 140.801 35.0984C140.036 34.3141 138.999 33.872 137.916 33.8687H112.399C111.904 33.8696 111.423 34.0339 111.027 34.3372C110.631 34.6404 110.341 35.0664 110.2 35.552C110.061 36.0384 109.772 36.4654 109.375 36.7681C108.978 37.0706 108.496 37.2344 108.001 37.2353H65.3055C64.8108 37.2354 64.3293 37.0715 63.933 36.7681C63.5367 36.4647 63.2468 36.0381 63.1066 35.552C62.9682 35.0638 62.6761 34.6362 62.2758 34.3359C61.8784 34.0333 61.3967 33.8696 60.9016 33.8687H35.3779C34.3008 33.872 33.2683 34.3097 32.5037 35.087C31.7392 35.8644 31.3041 36.919 31.2926 38.0226Z" fill="#263238" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M31.291 71.0361L142 71.0361V48.4425L31.291 48.4425V71.0361Z" fill="#FECC00" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M31.291 197.094H142L142 71.0362L31.291 71.0362L31.291 197.094Z" fill="white" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M86.3801 211.059H89.8345C90.973 211.059 91.8959 210.114 91.8959 208.947V205.408C91.8959 204.241 90.973 203.296 89.8345 203.296H86.3801C85.2417 203.296 84.3188 204.241 84.3188 205.408V208.947C84.3188 210.114 85.2417 211.059 86.3801 211.059Z" fill="white" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M43.0596 56.5327H37.6251" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M43.0596 59.5923H37.6251" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M43.0596 62.6516H37.6251" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M76.7477 83.8563H58.4326C58.1013 83.8563 57.7835 83.7215 57.5492 83.4814C57.3149 83.2413 57.1833 82.9157 57.1833 82.5762C57.1833 82.2367 57.3149 81.9111 57.5492 81.6711C57.7835 81.431 58.1013 81.2961 58.4326 81.2961H76.7227C77.0541 81.2961 77.3718 81.431 77.6061 81.6711C77.8404 81.9111 77.9721 82.2367 77.9721 82.5762C77.9721 82.9114 77.8439 83.2331 77.615 83.4725C77.3861 83.7118 77.0747 83.8496 76.7477 83.8563Z" fill="#263238"/>
            <path d="M121.392 86.999H57.883C57.7958 86.9999 57.7094 86.9829 57.6287 86.9491C57.5479 86.9153 57.4746 86.8654 57.413 86.8022C57.3513 86.7391 57.3026 86.6639 57.2696 86.5812C57.2366 86.4985 57.2201 86.4099 57.2209 86.3206C57.2209 86.2318 57.2381 86.1439 57.2714 86.0619C57.3048 85.98 57.3536 85.9056 57.4152 85.8431C57.4768 85.7806 57.5498 85.7313 57.6301 85.6979C57.7104 85.6645 57.7964 85.6477 57.883 85.6485H121.392C121.48 85.644 121.567 85.6582 121.649 85.6903C121.73 85.7225 121.804 85.7718 121.866 85.8352C121.928 85.8985 121.976 85.9745 122.007 86.0581C122.039 86.1417 122.053 86.2311 122.048 86.3206C122.049 86.4094 122.033 86.4975 122 86.5797C121.968 86.662 121.919 86.7369 121.858 86.7999C121.797 86.863 121.725 86.9131 121.645 86.9473C121.565 86.9815 121.479 86.999 121.392 86.999Z" fill="#263238"/>
            <path d="M51.1101 89.4998C53.8722 86.8956 54.0509 82.4902 51.5092 79.6602C48.9676 76.8301 44.6681 76.647 41.9061 79.2512C39.144 81.8554 38.9653 86.2608 41.5069 89.0909C44.0486 91.921 48.348 92.1041 51.1101 89.4998Z" fill="white" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M47.0576 91.2808C48.9691 90.9928 51.2428 89.3607 51.2428 89.3607C50.6122 89.0848 49.9576 88.8705 49.2876 88.7206C49.1171 88.7154 48.9496 88.6731 48.7961 88.5966C48.6427 88.52 48.5069 88.411 48.3977 88.2767C48.2884 88.1424 48.2083 87.9859 48.1625 87.8174C48.1167 87.649 48.1063 87.4726 48.132 87.2997C49.0011 86.1005 49.4421 84.6319 49.3813 83.1394C49.3501 82.0258 49.3813 79.856 47.0576 79.5552C46.8827 79.5552 46.7015 79.5552 46.5079 79.5552C46.3143 79.5552 46.1331 79.5552 45.9582 79.5552C43.6532 79.856 43.6719 82.0258 43.6345 83.1394C43.5736 84.6319 44.0147 86.1005 44.8838 87.2997C44.9095 87.4726 44.8991 87.649 44.8533 87.8174C44.8075 87.9859 44.7273 88.1424 44.6181 88.2767C44.5089 88.411 44.3731 88.52 44.2196 88.5966C44.0662 88.6731 43.8987 88.7154 43.7282 88.7206C43.0602 88.8704 42.4076 89.0847 41.7792 89.3607C41.7792 89.3607 44.0467 90.9928 45.9582 91.2808C46.1398 91.3132 46.3236 91.3303 46.5079 91.332C46.6922 91.3306 46.8761 91.3134 47.0576 91.2808Z" fill="#263238"/>
            <path d="M52.3986 92.5034C53.8821 92.5034 55.0846 91.2712 55.0846 89.7512C55.0846 88.2312 53.8821 86.999 52.3986 86.999C50.9151 86.999 49.7126 88.2312 49.7126 89.7512C49.7126 91.2712 50.9151 92.5034 52.3986 92.5034Z" fill="#FECC00" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M52.2611 89.5911L52.0737 88.3687H52.6984L52.511 89.5911L53.5854 89.0343L53.7853 89.6744L52.6047 89.8856L53.448 90.7496L52.9295 91.1401L52.3736 90.0456L51.8176 91.1401L51.2992 90.7496L52.1425 89.8856L50.9556 89.6744L51.1617 89.0343L52.2611 89.5911Z" fill="#263238"/>
            <path d="M49.6875 80.4128C48.307 77.7566 46.483 78.9791 46.483 78.9791C46.483 78.9791 44.6902 77.7886 43.3035 80.4128C41.9167 83.037 43.3035 85.8212 41.4295 86.3204C42.9554 87.2871 44.714 87.7991 46.508 87.7991C48.302 87.7991 50.0605 87.2871 51.5865 86.3204C49.6875 85.8212 51.093 83.069 49.6875 80.4128Z" fill="#263238"/>
            <path d="M47.7261 45.9912H48.963V43.4054H47.7261V45.9912Z" fill="white"/>
            <path d="M45.4585 45.9849H46.6953V42.3494H45.4585V45.9849Z" fill="white"/>
            <path d="M43.1847 45.9912H44.4215V41.3125H43.1847V45.9912Z" fill="white"/>
            <path d="M40.9171 45.9912H42.1539V40.2692H40.9171V45.9912Z" fill="white"/>
            <path d="M127.146 45.4663H133.805C134.215 45.4663 134.548 45.1253 134.548 44.7047V41.5492C134.548 41.1286 134.215 40.7876 133.805 40.7876H127.146C126.735 40.7876 126.402 41.1286 126.402 41.5492V44.7047C126.402 45.1253 126.735 45.4663 127.146 45.4663Z" fill="white"/>
            <path d="M125.446 44.4871H124.822C124.656 44.4871 124.497 44.4196 124.38 44.2996C124.263 44.1796 124.197 44.0168 124.197 43.847V42.3877C124.197 42.218 124.263 42.0552 124.38 41.9351C124.497 41.8151 124.656 41.7477 124.822 41.7477H125.446V44.4679V44.4871Z" fill="white"/>
            <path d="M134.442 56.539C134.825 56.9321 135.086 57.4327 135.192 57.9777C135.297 58.5227 135.243 59.0876 135.035 59.601C134.828 60.1143 134.476 60.5531 134.025 60.8617C133.574 61.1704 133.044 61.3352 132.502 61.3352C131.96 61.3352 131.43 61.1704 130.979 60.8617C130.528 60.5531 130.176 60.1143 129.969 59.601C129.761 59.0876 129.707 58.5227 129.812 57.9777C129.918 57.4327 130.179 56.9321 130.562 56.539C130.817 56.2772 131.119 56.0694 131.452 55.9277C131.785 55.7859 132.142 55.7129 132.502 55.7129C132.862 55.7129 133.219 55.7859 133.552 55.9277C133.885 56.0694 134.187 56.2772 134.442 56.539V56.539Z" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M134.448 60.5139L137.321 63.4645" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M93.9823 54.5103H81.158C80.5025 54.5103 79.9711 55.0547 79.9711 55.7263V63.4517C79.9711 64.1233 80.5025 64.6678 81.158 64.6678H93.9823C94.6377 64.6678 95.1691 64.1233 95.1691 63.4517V55.7263C95.1691 55.0547 94.6377 54.5103 93.9823 54.5103Z" fill="#263238" stroke="#FECC00" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M79.9647 55.7263L86.5861 60.1362C86.8781 60.3271 87.2173 60.4285 87.5637 60.4285C87.9101 60.4285 88.2492 60.3271 88.5413 60.1362L95.1627 55.7263" fill="#263238"/>
            <path d="M79.9647 55.7263L86.5861 60.1362C86.8781 60.3271 87.2173 60.4285 87.5637 60.4285C87.9101 60.4285 88.2492 60.3271 88.5413 60.1362L95.1627 55.7263" stroke="#FECC00" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M81.3889 62.6324L84.9557 60.5779L81.3889 62.6324Z" fill="#263238"/>
            <path d="M81.3889 62.6324L84.9557 60.5779" stroke="#FECC00" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M93.7386 62.6324L90.178 60.5779L93.7386 62.6324Z" fill="#263238"/>
            <path d="M93.7386 62.6324L90.178 60.5779" stroke="#FECC00" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M70.5011 106.168H58.4326C58.1013 106.168 57.7835 106.033 57.5492 105.793C57.3149 105.553 57.1833 105.228 57.1833 104.888C57.1833 104.549 57.3149 104.223 57.5492 103.983C57.7835 103.743 58.1013 103.608 58.4326 103.608H70.4761C70.8075 103.608 71.1252 103.743 71.3595 103.983C71.5938 104.223 71.7254 104.549 71.7254 104.888C71.7255 105.223 71.5973 105.545 71.3684 105.784C71.1395 106.024 70.8281 106.162 70.5011 106.168Z" fill="#263238"/>
            <path d="M96.4059 109.317H57.883C57.7074 109.317 57.539 109.246 57.4148 109.119C57.2906 108.991 57.2209 108.819 57.2209 108.639C57.2289 108.465 57.3024 108.301 57.4259 108.182C57.5494 108.063 57.7132 107.997 57.883 107.999H96.4059C96.4905 107.995 96.5751 108.008 96.6545 108.038C96.7339 108.068 96.8066 108.115 96.8679 108.175C96.9293 108.234 96.9781 108.307 97.0114 108.386C97.0447 108.466 97.0619 108.552 97.0618 108.639C97.0618 108.818 96.9929 108.989 96.87 109.116C96.7472 109.243 96.5804 109.316 96.4059 109.317Z" fill="#263238"/>
            <path d="M51.2553 111.671C53.9457 108.989 54.0051 104.581 51.3878 101.824C48.7705 99.0673 44.4677 99.0065 41.7773 101.688C39.0868 104.37 39.0275 108.779 41.6447 111.535C44.262 114.292 48.5648 114.353 51.2553 111.671Z" fill="white" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M47.0576 113.631C48.9691 113.343 51.2428 111.711 51.2428 111.711C50.6122 111.435 49.9576 111.221 49.2876 111.071C49.1171 111.066 48.9496 111.024 48.7961 110.947C48.6427 110.871 48.5069 110.762 48.3977 110.627C48.2884 110.493 48.2083 110.336 48.1625 110.168C48.1167 110 48.1063 109.823 48.132 109.65C48.9993 108.45 49.4401 106.982 49.3813 105.49C49.3501 104.376 49.3813 102.2 47.0576 101.906C46.8745 101.896 46.691 101.896 46.5079 101.906C46.3248 101.896 46.1413 101.896 45.9582 101.906C43.6532 102.2 43.6719 104.376 43.6345 105.49C43.5757 106.982 44.0165 108.45 44.8838 109.65C44.9095 109.823 44.8991 110 44.8533 110.168C44.8075 110.336 44.7273 110.493 44.6181 110.627C44.5089 110.762 44.3731 110.871 44.2196 110.947C44.0662 111.024 43.8987 111.066 43.7282 111.071C43.0602 111.221 42.4076 111.435 41.7792 111.711C41.7792 111.711 44.0655 113.311 45.9395 113.631C46.121 113.664 46.3049 113.681 46.4892 113.683C46.6735 113.681 46.8573 113.664 47.0389 113.631H47.0576Z" fill="#263238"/>
            <path d="M52.3986 114.822C53.8821 114.822 55.0846 113.59 55.0846 112.07C55.0846 110.55 53.8821 109.317 52.3986 109.317C50.9151 109.317 49.7126 110.55 49.7126 112.07C49.7126 113.59 50.9151 114.822 52.3986 114.822Z" fill="#FECC00" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M52.2611 111.91L52.0737 110.687H52.6984L52.511 111.91L53.5854 111.353L53.7853 111.993L52.6047 112.198L53.448 113.068L52.9295 113.459L52.3736 112.364L51.8176 113.459L51.2992 113.068L52.1425 112.198L50.9556 111.993L51.1617 111.353L52.2611 111.91Z" fill="#263238"/>
            <path d="M82.9943 128.487H58.4326C58.1013 128.487 57.7835 128.352 57.5492 128.112C57.3149 127.872 57.1833 127.546 57.1833 127.207C57.1833 126.867 57.3149 126.542 57.5492 126.302C57.7835 126.062 58.1013 125.927 58.4326 125.927H82.9694C83.3007 125.927 83.6185 126.062 83.8528 126.302C84.087 126.542 84.2187 126.867 84.2187 127.207C84.2187 127.542 84.0905 127.864 83.8616 128.103C83.6327 128.342 83.3213 128.48 82.9943 128.487Z" fill="#263238"/>
            <path d="M108.899 131.636H57.883C57.7074 131.636 57.539 131.564 57.4148 131.437C57.2906 131.31 57.2209 131.137 57.2209 130.957C57.2209 130.869 57.238 130.781 57.2714 130.699C57.3047 130.617 57.3536 130.542 57.4152 130.48C57.4767 130.417 57.5498 130.368 57.6301 130.335C57.7104 130.301 57.7963 130.284 57.883 130.285H108.899C108.986 130.281 109.074 130.295 109.155 130.327C109.237 130.359 109.311 130.409 109.373 130.472C109.435 130.535 109.483 130.611 109.514 130.695C109.546 130.778 109.559 130.868 109.555 130.957C109.555 131.136 109.486 131.308 109.363 131.435C109.24 131.562 109.074 131.634 108.899 131.636Z" fill="#263238"/>
            <path d="M48.1429 135.763C51.7883 134.847 54.0188 131.076 53.1247 127.341C52.2307 123.606 48.5506 121.32 44.9052 122.236C41.2597 123.152 39.0293 126.923 39.9233 130.658C40.8174 134.394 44.4974 136.679 48.1429 135.763Z" fill="white" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M47.0576 135.918C48.9691 135.63 51.2428 133.998 51.2428 133.998C50.6122 133.722 49.9576 133.507 49.2876 133.358C49.1171 133.352 48.9496 133.31 48.7961 133.233C48.6427 133.157 48.5069 133.048 48.3977 132.914C48.2884 132.779 48.2083 132.623 48.1625 132.454C48.1167 132.286 48.1063 132.109 48.132 131.937C48.9993 130.736 49.4401 129.269 49.3813 127.776C49.3501 126.663 49.3813 124.486 47.0576 124.192C46.8745 124.182 46.691 124.182 46.5079 124.192C46.3248 124.182 46.1413 124.182 45.9582 124.192C43.6532 124.486 43.6719 126.663 43.6345 127.776C43.5757 129.269 44.0165 130.736 44.8838 131.937C44.9095 132.109 44.8991 132.286 44.8533 132.454C44.8075 132.623 44.7273 132.779 44.6181 132.914C44.5089 133.048 44.3731 133.157 44.2196 133.233C44.0662 133.31 43.8987 133.352 43.7282 133.358C43.0602 133.507 42.4076 133.722 41.7792 133.998C41.7792 133.998 44.0467 135.63 45.9582 135.918C46.1398 135.95 46.3236 135.967 46.5079 135.969C46.6922 135.967 46.8761 135.95 47.0576 135.918Z" fill="#263238"/>
            <path d="M46.508 123.75C44.0656 123.75 42.354 125.485 42.0354 131.738C44.9375 132.737 48.0785 132.737 50.9806 131.738C50.662 125.485 48.9504 123.75 46.508 123.75Z" fill="#263238"/>
            <path d="M76.7477 150.805H58.4326C58.1013 150.805 57.7835 150.67 57.5492 150.43C57.3149 150.19 57.1833 149.865 57.1833 149.525C57.1833 149.186 57.3149 148.86 57.5492 148.62C57.7835 148.38 58.1013 148.245 58.4326 148.245H76.7227C77.0541 148.245 77.3718 148.38 77.6061 148.62C77.8404 148.86 77.9721 149.186 77.9721 149.525C77.9721 149.86 77.8439 150.182 77.615 150.421C77.3861 150.661 77.0747 150.799 76.7477 150.805Z" fill="#263238"/>
            <path d="M121.392 153.954H57.883C57.7963 153.955 57.7104 153.938 57.6301 153.905C57.5498 153.872 57.4767 153.822 57.4152 153.76C57.3536 153.697 57.3047 153.623 57.2714 153.541C57.238 153.459 57.2209 153.371 57.2209 153.282C57.2209 153.102 57.2906 152.93 57.4148 152.802C57.539 152.675 57.7074 152.604 57.883 152.604H121.392C121.567 152.605 121.734 152.678 121.857 152.805C121.979 152.932 122.048 153.103 122.048 153.282C122.053 153.372 122.039 153.461 122.007 153.545C121.976 153.628 121.928 153.704 121.866 153.768C121.804 153.831 121.73 153.88 121.649 153.912C121.567 153.945 121.48 153.959 121.392 153.954Z" fill="#263238"/>
            <path d="M51.2609 156.301C53.9514 153.619 54.0107 149.211 51.3934 146.454C48.7761 143.697 44.4734 143.636 41.7829 146.318C39.0924 149 39.0331 153.409 41.6504 156.165C44.2677 158.922 48.5704 158.983 51.2609 156.301Z" fill="white" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M47.0576 158.236C48.9691 157.948 51.2428 156.316 51.2428 156.316C50.6122 156.04 49.9576 155.826 49.2876 155.676C49.1171 155.671 48.9496 155.628 48.7961 155.552C48.6427 155.475 48.5069 155.366 48.3977 155.232C48.2884 155.098 48.2083 154.941 48.1625 154.773C48.1167 154.604 48.1063 154.428 48.132 154.255C49.0002 153.053 49.4411 151.583 49.3813 150.088C49.3501 148.981 49.3813 146.805 47.0576 146.511C46.8751 146.49 46.6916 146.479 46.5079 146.479C46.3242 146.479 46.1407 146.49 45.9582 146.511C43.6532 146.805 43.6719 148.981 43.6345 150.088C43.5747 151.583 44.0156 153.053 44.8838 154.255C44.9095 154.428 44.8991 154.604 44.8533 154.773C44.8075 154.941 44.7273 155.098 44.6181 155.232C44.5089 155.366 44.3731 155.475 44.2196 155.552C44.0662 155.628 43.8987 155.671 43.7282 155.676C43.0602 155.826 42.4076 156.04 41.7792 156.316C41.7792 156.316 44.0467 157.948 45.9582 158.236C46.1402 158.265 46.3238 158.282 46.5079 158.287C46.692 158.283 46.8757 158.266 47.0576 158.236Z" fill="#263238"/>
            <path d="M52.3986 159.452C53.8821 159.452 55.0846 158.22 55.0846 156.7C55.0846 155.18 53.8821 153.948 52.3986 153.948C50.9151 153.948 49.7126 155.18 49.7126 156.7C49.7126 158.22 50.9151 159.452 52.3986 159.452Z" fill="#FECC00" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M52.2611 156.514L52.0737 155.298H52.6984L52.511 156.514L53.5854 155.964L53.7853 156.604L52.6047 156.809L53.448 157.673L52.9295 158.07L52.3736 156.975L51.8176 158.07L51.2992 157.673L52.1425 156.809L50.9556 156.604L51.1617 155.964L52.2611 156.514Z" fill="#263238"/>
            <path d="M64.2545 173.156H58.4326C58.1013 173.156 57.7835 173.021 57.5492 172.781C57.3149 172.541 57.1833 172.215 57.1833 171.876C57.1833 171.536 57.3149 171.21 57.5492 170.97C57.7835 170.73 58.1013 170.595 58.4326 170.595H64.2295C64.5608 170.595 64.8786 170.73 65.1129 170.97C65.3472 171.21 65.4788 171.536 65.4788 171.876C65.4789 172.211 65.3507 172.532 65.1218 172.772C64.8929 173.011 64.5815 173.149 64.2545 173.156Z" fill="#263238"/>
            <path d="M115.146 176.266H57.883C57.7963 176.267 57.7104 176.25 57.6301 176.217C57.5498 176.184 57.4767 176.134 57.4152 176.072C57.3536 176.009 57.3047 175.935 57.2714 175.853C57.238 175.771 57.2209 175.683 57.2209 175.594C57.2209 175.414 57.2906 175.242 57.4148 175.114C57.539 174.987 57.7074 174.916 57.883 174.916H115.146C115.32 174.917 115.487 174.99 115.61 175.117C115.733 175.244 115.802 175.415 115.802 175.594C115.806 175.684 115.792 175.773 115.761 175.857C115.729 175.94 115.681 176.016 115.619 176.08C115.558 176.143 115.484 176.192 115.402 176.224C115.32 176.257 115.233 176.271 115.146 176.266Z" fill="#263238"/>
            <path d="M51.2574 178.622C53.9479 175.94 54.0072 171.532 51.3899 168.775C48.7726 166.018 44.4699 165.957 41.7794 168.639C39.0889 171.321 39.0296 175.73 41.6469 178.486C44.2642 181.243 48.5669 181.304 51.2574 178.622Z" fill="white" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M47.0576 180.555C48.9691 180.26 51.2428 178.634 51.2428 178.634C50.6122 178.359 49.9576 178.144 49.2876 177.994C49.1174 177.988 48.9504 177.945 48.7975 177.868C48.6445 177.791 48.5091 177.682 48.4001 177.548C48.291 177.414 48.2108 177.258 48.1646 177.09C48.1184 176.922 48.1073 176.746 48.132 176.573C49.0002 175.371 49.4411 173.901 49.3813 172.407C49.3501 171.299 49.3813 169.123 47.0576 168.829C46.8751 168.808 46.6916 168.797 46.5079 168.797C46.3242 168.797 46.1407 168.808 45.9582 168.829C43.6532 169.123 43.6719 171.299 43.6345 172.407C43.5747 173.901 44.0156 175.371 44.8838 176.573C44.9085 176.746 44.8974 176.922 44.8512 177.09C44.805 177.258 44.7247 177.414 44.6157 177.548C44.5067 177.682 44.3713 177.791 44.2183 177.868C44.0654 177.945 43.8984 177.988 43.7282 177.994C43.0602 178.144 42.4076 178.358 41.7792 178.634C41.7792 178.634 44.0467 180.26 45.9582 180.555C46.1403 180.582 46.3239 180.597 46.5079 180.599C46.6919 180.597 46.8755 180.582 47.0576 180.555Z" fill="#263238"/>
            <path d="M76.7477 195.442H58.4326C58.1013 195.442 57.7835 195.307 57.5492 195.067C57.3149 194.827 57.1833 194.502 57.1833 194.162C57.1833 193.823 57.3149 193.497 57.5492 193.257C57.7835 193.017 58.1013 192.882 58.4326 192.882H76.7227C77.0541 192.882 77.3718 193.017 77.6061 193.257C77.8404 193.497 77.9721 193.823 77.9721 194.162C77.9721 194.497 77.8439 194.819 77.615 195.058C77.3861 195.298 77.0747 195.436 76.7477 195.442Z" fill="#263238"/>
            <path d="M53.3042 195.954C53.3048 196.336 53.2734 196.717 53.2105 197.094H39.8052C39.7423 196.717 39.7109 196.336 39.7115 195.954C39.7115 194.107 40.4276 192.336 41.7021 191.03C42.9767 189.724 44.7053 188.99 46.5078 188.99C48.3103 188.99 50.039 189.724 51.3136 191.03C52.5881 192.336 53.3042 194.107 53.3042 195.954Z" fill="white" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M49.3814 194.719C49.4109 195.525 49.2944 196.331 49.0379 197.094H43.9781C43.7216 196.331 43.6051 195.525 43.6346 194.719C43.672 193.612 43.6346 191.436 45.9583 191.141C46.3236 191.098 46.6924 191.098 47.0577 191.141C49.3689 191.436 49.3502 193.637 49.3814 194.719Z" fill="#263238"/>
            <path d="M123.741 193.688C128.699 193.688 132.717 189.571 132.717 184.491C132.717 179.411 128.699 175.293 123.741 175.293C118.783 175.293 114.765 179.411 114.765 184.491C114.765 189.571 118.783 193.688 123.741 193.688Z" fill="#FECC00" stroke="#263238" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M127.67 181.713L126.464 180.477L121.04 186.035L122.246 187.27L127.67 181.713Z" fill="#263238"/>
            <path d="M120.487 186.59L119.825 188.504L121.692 187.826L120.487 186.59Z" fill="#263238"/>
            <path d="M158.429 50.3884H189.531C190.607 50.3884 191.64 50.8267 192.402 51.607C193.163 52.3872 193.591 53.4454 193.591 54.5487V73.5325C193.592 74.0794 193.487 74.6211 193.284 75.1266C193.08 75.6321 192.781 76.0915 192.404 76.4785C192.027 76.8655 191.579 77.1725 191.086 77.3819C190.593 77.5914 190.064 77.6992 189.531 77.6992H185.651L189.237 84.663L180.954 77.6992H158.429C157.895 77.6992 157.367 77.5914 156.873 77.3819C156.38 77.1725 155.933 76.8655 155.555 76.4785C155.178 76.0915 154.879 75.6321 154.676 75.1266C154.472 74.6211 154.368 74.0794 154.368 73.5325V54.5487C154.368 53.4454 154.796 52.3872 155.558 51.607C156.319 50.8267 157.352 50.3884 158.429 50.3884V50.3884Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <g opacity="0.5">
                <path d="M160.097 58.0625H187.869C188.152 58.0625 188.381 57.8275 188.381 57.5377V57.3072C188.381 57.0174 188.152 56.7824 187.869 56.7824H160.097C159.814 56.7824 159.584 57.0174 159.584 57.3072V57.5377C159.584 57.8275 159.814 58.0625 160.097 58.0625Z" fill="black"/>
                <path d="M160.097 62.3765H187.869C188.152 62.3765 188.381 62.1415 188.381 61.8516V61.6212C188.381 61.3313 188.152 61.0964 187.869 61.0964H160.097C159.814 61.0964 159.584 61.3313 159.584 61.6212V61.8516C159.584 62.1415 159.814 62.3765 160.097 62.3765Z" fill="black"/>
                <path d="M160.097 66.6968H187.869C188.152 66.6968 188.381 66.4618 188.381 66.1719V65.9415C188.381 65.6517 188.152 65.4167 187.869 65.4167H160.097C159.814 65.4167 159.584 65.6517 159.584 65.9415V66.1719C159.584 66.4618 159.814 66.6968 160.097 66.6968Z" fill="black"/>
                <path d="M173.221 71.0107H187.869C188.152 71.0107 188.381 70.7758 188.381 70.4859V70.2555C188.381 69.9656 188.152 69.7306 187.869 69.7306H173.221C172.938 69.7306 172.708 69.9656 172.708 70.2555V70.4859C172.708 70.7758 172.938 71.0107 173.221 71.0107Z" fill="black"/>
            </g>
            <path d="M197.245 173.982C197.243 173.994 197.243 174.007 197.245 174.02C196.945 174.66 192.622 184.126 187.356 193.221C185.976 195.609 184.533 197.964 183.077 200.134C179.91 204.857 176.7 208.679 174.032 209.869C165.287 213.767 170.284 183.224 170.284 183.224C173.474 179.334 176.218 175.082 178.461 170.551C179.504 168.439 180.716 165.987 181.984 163.51C182.959 161.622 183.958 159.734 184.939 157.987C187.156 154.019 189.224 150.818 190.48 150.191C191.854 149.5 193.753 156.636 195.19 163.274C196.358 168.804 197.245 173.982 197.245 173.982Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M197.245 173.982C197.244 173.994 197.244 174.007 197.245 174.02C196.945 174.66 192.622 184.126 187.356 193.221C188.943 178.686 187.456 168.964 187.456 168.964C184.077 163.728 181.959 163.53 181.959 163.53C182.934 161.641 183.933 159.753 184.914 158.006L189.387 153.724L195.165 163.293C196.358 168.804 197.245 173.982 197.245 173.982Z" fill="#263238"/>
            <path d="M237.548 76.2337C245.443 82.0902 252.352 102.776 249.716 116.217C247.08 129.658 260.641 133.499 260.591 154.716C260.591 160.63 242.714 184.671 221.956 171.844C201.199 159.017 195.433 152.707 197.407 139.842C199.381 126.977 199.468 118.515 195.72 107.718C191.972 96.92 223.224 65.6281 237.548 76.2337Z" fill="#263238"/>
            <path d="M267.919 166.96L263.509 170.026C262.36 177.443 260.357 184.694 257.543 191.628C251.99 204.986 247.074 212.948 245.356 215.578C244.931 216.218 244.7 216.538 244.7 216.538L249.31 234.249C249.31 234.249 218.546 267.025 184.614 262.295L186.881 243.734L189.936 218.772L190.929 210.663C190.929 210.663 190.511 204.845 189.842 197.683C189.386 192.748 188.812 187.173 188.162 182.424C188.135 182.212 188.125 181.998 188.131 181.784C188.181 178.788 190.473 172.49 190.473 172.49C188.537 169.348 186.725 166.455 185.232 164.17C183.258 161.104 181.953 159.133 181.953 159.133C181.953 159.133 188.824 150.479 189.211 149.493C191.385 143.663 198.981 144.751 198.981 144.751C199.549 144.188 200.258 143.798 201.03 143.624C204.641 142.696 214.904 150.069 220.507 150.127C226.11 150.185 253.614 142.594 253.614 142.594L256.413 143.624C256.413 143.624 260.204 143.163 263.165 148.783C264.571 151.503 267.919 166.96 267.919 166.96Z" fill="#FECC00"/>
            <path opacity="0.3" d="M218.533 239.951C218.533 239.951 199.418 246.563 186.85 243.74L189.905 218.779C192.628 221.55 208.42 237.161 218.533 239.951Z" fill="black" fillOpacity="0.7"/>
            <path opacity="0.3" d="M190.442 172.516C188.506 169.373 186.694 166.48 185.201 164.195C186.5 160.995 188.431 158.032 191.104 158.204C191.104 158.204 189.236 166.704 190.442 172.516Z" fill="black" fillOpacity="0.7"/>
            <path opacity="0.3" d="M252.433 166.96C249.004 168.125 241.233 171.652 241.233 171.652L239.796 169.943L245.325 161.827C245.325 161.827 241.433 163.44 239.796 165.469C238.16 167.498 237.729 172.023 237.666 172.004C237.604 171.985 236.236 167.242 236.236 167.242C235.771 169.216 235.466 171.226 235.324 173.252C235.143 175.396 237.441 178.884 239.434 181.477L235.961 200.416L222.718 192.165L216.472 188.287C215.925 187.992 215.398 187.663 214.891 187.301C214.167 186.699 213.692 185.919 212.849 185.381C211.885 184.827 210.844 184.428 209.763 184.197C205.809 183.254 201.7 183.221 197.732 184.101C196.058 184.472 194.34 185.003 192.678 184.6C191.017 184.197 189.592 182.833 188.131 181.809C188.124 182.023 188.135 182.237 188.162 182.449C188.787 187.199 189.411 192.773 189.842 197.708H190.554C191.015 197.682 191.478 197.721 191.929 197.823C192.884 198.092 194.783 202.867 195.439 203.622C200.23 209.101 208.245 218.343 214.067 222.664C212.905 219.463 206.827 208.263 206.939 204.742C208.245 205.19 209.263 206.221 210.312 207.136C213.473 209.914 217.246 211.847 220.969 213.754L229.171 217.959C231.67 219.239 234.962 221.243 237.835 220.455C240.19 219.815 243.157 217.39 245.037 215.86L245.35 215.604C247.067 212.973 251.984 205.011 257.537 191.653C260.351 184.72 262.354 177.468 263.502 170.052L267.912 166.986C267.912 166.986 255.863 165.795 252.433 166.96Z" fill="black" fillOpacity="0.7"/>
            <path d="M184.614 262.295L190.929 210.695C190.929 210.695 189.742 194.105 188.162 182.456C187.806 179.845 190.473 172.516 190.473 172.516C185.932 165.155 181.953 159.158 181.953 159.158C181.953 159.158 188.824 150.505 189.211 149.519C191.385 143.688 198.981 144.776 198.981 144.776C199.549 144.213 200.258 143.823 201.03 143.65C204.641 142.722 214.904 150.095 220.507 150.153C226.11 150.21 253.614 142.619 253.614 142.619L256.413 143.65C256.413 143.65 260.204 143.189 263.165 148.809C264.589 151.503 267.944 166.96 267.944 166.96L263.534 170.026C262.385 177.443 260.382 184.694 257.568 191.628C250.647 208.269 244.719 216.551 244.719 216.551L249.329 234.261" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M246.049 160.848C246.049 160.848 238.222 171.236 238.416 173.156C238.61 175.076 242.233 177.054 242.233 177.054" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M235.961 165.015C236.339 167.713 236.558 170.431 236.617 173.156C236.617 176.715 246.049 182.622 246.049 182.622" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M190.442 172.516C190.442 172.516 189.787 158.819 191.867 156.457" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M188.131 161.52C188.131 161.52 189.205 168.612 190.342 169.962" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M223.274 166.96C223.274 166.96 209.763 175.87 197.244 177.054" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M197.244 205.92C197.244 205.92 212.399 224.654 222.287 227.189" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M244.694 216.551C244.694 216.551 241.252 213.991 242.233 212.493" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M235.961 231.784C235.961 231.784 214.46 244.067 203.479 243.056" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M178.267 192.409C175.463 181.848 170.871 178.404 167.842 167.095C166.942 163.747 164.275 153.539 164.275 153.539C164.275 153.539 167.679 142.658 160.877 136.462C160.877 136.462 159.003 136.462 155.811 143.996C154.477 147.286 154.132 150.91 154.818 154.403C154.818 154.403 156.935 167.671 157.397 171.236C158.509 179.832 160.921 194.226 164.269 201.453C167.861 209.178 171.646 210.906 174.482 209.312" fill="white"/>
            <path d="M178.267 192.409C175.463 181.848 170.871 178.404 167.842 167.095C166.942 163.747 164.275 153.539 164.275 153.539C164.275 153.539 167.679 142.658 160.877 136.462C160.877 136.462 159.003 136.462 155.811 143.996C154.477 147.286 154.132 150.91 154.818 154.403C154.818 154.403 156.935 167.671 157.397 171.236C158.509 179.832 160.921 194.226 164.269 201.453C167.861 209.178 171.646 210.906 174.482 209.312" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M165.462 151.222L172.745 153.686C173.514 153.891 175.026 152.579 174.957 151.766L172.777 125.793C172.734 125.281 172.538 124.796 172.214 124.403C171.891 124.011 171.457 123.732 170.971 123.604L162.932 121.811C162.164 121.613 160.234 122.554 160.302 123.36L163.65 149.033C163.693 149.545 163.891 150.031 164.215 150.423C164.54 150.816 164.976 151.095 165.462 151.222Z" fill="#FECC00"/>
            <path opacity="0.3" d="M165.462 151.222L172.745 153.686C173.514 153.891 175.026 152.579 174.957 151.766L172.777 125.793C172.734 125.281 172.538 124.796 172.214 124.403C171.891 124.011 171.457 123.732 170.971 123.604L162.932 121.811C162.164 121.613 160.234 122.554 160.302 123.36L163.65 149.033C163.693 149.545 163.891 150.031 164.215 150.423C164.54 150.816 164.976 151.095 165.462 151.222Z" fill="black" fillOpacity="0.7"/>
            <path d="M165.462 151.222L172.745 153.686C173.514 153.891 175.026 152.579 174.957 151.766L172.777 125.793C172.734 125.281 172.538 124.796 172.214 124.403C171.891 124.011 171.457 123.732 170.971 123.604L162.932 121.811C162.164 121.613 160.234 122.554 160.302 123.36L163.65 149.033C163.693 149.545 163.891 150.031 164.215 150.423C164.54 150.816 164.976 151.095 165.462 151.222V151.222Z" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M164.263 151.862L172.302 153.647C172.479 153.695 172.665 153.699 172.844 153.658C173.023 153.616 173.19 153.532 173.33 153.41C173.471 153.289 173.58 153.135 173.65 152.962C173.72 152.788 173.748 152.599 173.732 152.412L171.584 126.433C171.541 125.92 171.343 125.434 171.019 125.042C170.694 124.65 170.258 124.371 169.772 124.244L161.739 122.451C161.561 122.405 161.375 122.402 161.196 122.444C161.017 122.487 160.851 122.572 160.711 122.694C160.571 122.815 160.461 122.969 160.391 123.143C160.321 123.317 160.293 123.506 160.308 123.693L162.457 149.673C162.5 150.184 162.696 150.67 163.02 151.062C163.343 151.454 163.777 151.733 164.263 151.862Z" fill="#FECC00" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.3" d="M168.723 131.239L170.016 131.527C170.044 131.534 170.074 131.535 170.103 131.528C170.131 131.521 170.158 131.507 170.18 131.488C170.203 131.469 170.221 131.444 170.232 131.417C170.244 131.389 170.249 131.359 170.247 131.329L169.904 127.137C169.896 127.054 169.863 126.976 169.811 126.913C169.758 126.85 169.688 126.805 169.61 126.785L168.311 126.497C168.282 126.49 168.252 126.489 168.224 126.496C168.195 126.503 168.169 126.517 168.146 126.536C168.124 126.555 168.106 126.58 168.094 126.607C168.083 126.635 168.078 126.665 168.08 126.695L168.429 130.887C168.437 130.97 168.469 131.048 168.522 131.111C168.574 131.174 168.645 131.219 168.723 131.239Z" fill="black" fillOpacity="0.7"/>
            <path d="M157.416 144.258C157.104 140.616 160.34 135.15 162.495 135.15C164.65 135.15 171.196 140.321 171.196 140.321C173.732 142.203 172.477 143.995 172.477 143.995C173.97 145.007 174.351 146.376 173.695 147.01C173.039 147.644 170.846 147.01 170.846 147.01C170.846 147.01 173.02 148.636 172.477 149.436C171.196 151.324 166.986 148.834 166.986 148.834C166.986 148.834 169.428 150.485 169.291 150.799C168.367 152.86 164.375 150.46 164.375 150.46L161.57 151.932C161.573 152.271 161.507 152.607 161.377 152.918C161.247 153.23 161.055 153.51 160.814 153.742C160.573 153.974 160.287 154.153 159.976 154.266C159.664 154.38 159.333 154.426 159.003 154.403" fill="white"/>
            <path d="M157.416 144.258C157.104 140.616 160.34 135.15 162.495 135.15C164.65 135.15 171.196 140.321 171.196 140.321C173.732 142.203 172.477 143.995 172.477 143.995C173.97 145.007 174.351 146.376 173.695 147.01C173.039 147.644 170.846 147.01 170.846 147.01C170.846 147.01 173.02 148.636 172.477 149.436C171.196 151.324 166.986 148.834 166.986 148.834C166.986 148.834 169.428 150.485 169.291 150.799C168.367 152.86 164.375 150.46 164.375 150.46L161.57 151.932C161.573 152.271 161.507 152.607 161.377 152.918C161.247 153.23 161.055 153.51 160.814 153.742C160.573 153.974 160.287 154.153 159.976 154.266C159.664 154.38 159.333 154.426 159.003 154.403" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M172.477 143.995C172.477 143.995 166.343 139.937 165.568 139.829C164.794 139.72 161.951 139.086 161.951 139.086" fill="white"/>
            <path d="M172.477 143.995C172.477 143.995 166.343 139.937 165.568 139.829C164.794 139.72 161.951 139.086 161.951 139.086" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M170.871 147.01C170.871 147.01 165.874 144.373 164.974 144.373C163.595 144.218 162.227 143.977 160.877 143.65" fill="white"/>
            <path d="M170.871 147.01C170.871 147.01 165.874 144.373 164.974 144.373C163.595 144.218 162.227 143.977 160.877 143.65" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M166.986 148.834C166.404 148.41 165.788 148.037 165.143 147.721C163.954 147.633 162.76 147.633 161.57 147.721" fill="white"/>
            <path d="M166.986 148.834C166.404 148.41 165.788 148.037 165.143 147.721C163.954 147.633 162.76 147.633 161.57 147.721" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M229.452 189.714C229.452 189.714 233.325 187.48 236.786 179.147C240.246 170.813 241.233 171.652 241.233 171.652C241.233 171.652 248.811 167.844 252.434 166.96C256.057 166.077 262.647 166.96 262.647 166.96C262.647 166.96 257.025 183.672 251.284 194.54C245.544 205.408 240.921 211.821 237.854 212.461C234.787 213.101 229.452 189.714 229.452 189.714Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M251.94 143.714C251.94 143.714 224.368 158.761 216.397 158.678C208.426 158.595 203.46 144.597 203.46 144.597C203.46 144.597 218.202 144.258 216.491 137.038C216.491 136.917 216.434 136.782 216.397 136.654C215.691 134.132 212.986 129.716 211.981 128.129C211.743 127.751 211.599 127.533 211.599 127.533L233.419 110.841C232.17 127.719 234.581 135.118 236.061 137.896C238.604 142.651 251.94 143.714 251.94 143.714Z" fill="white" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M227.229 120.301C227.229 120.301 222.681 133.473 216.472 137.038C216.472 136.916 216.416 136.782 216.378 136.654C215.672 134.132 212.968 129.716 211.962 128.129L227.229 120.301Z" fill="#263238"/>
            <path d="M217.777 154.665C217.777 154.665 219.32 151.612 221.625 151.254C223.93 150.895 230.277 150.831 242.014 148.834" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M214.235 154.665C214.235 154.665 213.248 152.29 212.986 151.253C212.724 150.217 206.589 148.911 204.553 147.196" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M197.551 83.0566C193.509 87.569 192.304 102.296 194.322 108.659C194.322 108.659 204.447 110.143 206.815 108.275C209.182 106.406 197.551 83.0566 197.551 83.0566Z" fill="#263238"/>
            <path d="M235.63 112.8C236.605 108.39 241.771 87.0121 229.608 77.5522C217.446 68.0923 202.611 80.7076 200.53 83.6838C192.141 95.6911 195.858 105.234 197.407 110.988C198.956 116.742 198.194 123.712 206.04 133.294C209.338 137.32 215.272 135.176 221.119 131.009C224.624 128.513 229.558 124.122 229.683 119.891" fill="white"/>
            <path d="M235.63 112.8C236.605 108.39 241.771 87.0121 229.608 77.5522C217.446 68.0923 202.611 80.7076 200.53 83.6838C192.141 95.6911 195.858 105.234 197.407 110.988C198.956 116.742 198.194 123.712 206.04 133.294C209.338 137.32 215.272 135.176 221.119 131.009C224.624 128.513 229.558 124.122 229.683 119.891" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M207.827 103.429C207.827 103.429 214.417 102.226 218.146 104.62" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M214.798 109.958C214.96 110.995 214.604 111.916 213.998 112.019C213.392 112.121 212.749 111.379 212.599 110.329C212.449 109.279 212.793 108.37 213.398 108.268C214.004 108.166 214.629 108.921 214.798 109.958Z" fill="#263238"/>
            <path d="M201.63 109.958C201.792 110.995 201.436 111.916 200.83 112.019C200.224 112.121 199.581 111.379 199.431 110.329C199.281 109.279 199.625 108.37 200.231 108.268C200.837 108.166 201.48 108.921 201.63 109.958Z" fill="#263238"/>
            <path d="M200.53 103.493C200.53 103.493 196.895 102.162 195.721 103.871" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M202.823 109.446C203.198 110.905 200.325 117.485 200.531 118.054C200.737 118.624 202.149 119.635 204.678 119.891" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M205.927 124.416C205.927 124.416 211.2 124.416 214.235 122.221" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M199.056 79.8819C199.056 79.8819 193.496 85.1751 197.082 95.5823C197.874 97.7045 199.043 99.6577 200.53 101.343C204.247 99.1985 206.102 102.546 208.326 101.451C210.55 100.357 214.091 102.68 216.396 102.22C217.53 101.961 218.706 101.972 219.835 102.253C220.963 102.534 222.014 103.076 222.905 103.839C224.636 105.426 224.636 108.902 227.228 110.579C227.228 110.579 228.74 105.145 231.926 105.932C235.111 106.719 232.294 116.173 232.294 116.173C236.286 111.186 238.776 105.117 239.459 98.7057C240.708 87.8249 236.511 78.3202 229.464 76.304C229.464 76.304 227.116 71.6189 219.083 72.3741C211.049 73.1294 203.766 72.6686 199.056 79.8819Z" fill="#263238"/>
            <path d="M229.452 107.359C229.452 107.359 232.419 99.909 236.742 103C241.065 106.092 235.88 115.584 233.775 117.99C231.67 120.397 231.058 117.741 230.814 116.979" fill="white"/>
            <path d="M229.452 107.359C229.452 107.359 232.419 99.909 236.742 103C241.065 106.092 235.88 115.584 233.775 117.99C231.67 120.397 231.058 117.741 230.814 116.979" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <g opacity="0.2">
                <path opacity="0.2" d="M201.005 85.2968C201.13 83.432 201.675 81.6222 202.598 80.01" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path opacity="0.2" d="M205.927 98.117C203.254 95.0576 201.217 90.9421 200.999 86.8074" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path opacity="0.2" d="M211.599 92.9709C213.108 95.5002 215.238 97.5793 217.777 99.0002" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path opacity="0.2" d="M210.081 80.01C208.032 83.3831 209.213 88.2218 210.812 91.5309" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path opacity="0.2" d="M230.901 99.1921C232.449 100.015 234.075 100.673 235.755 101.157" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path opacity="0.2" d="M216.396 84.4456C219.519 90.2202 224.069 95.052 229.589 98.4562" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <path d="M185.32 139.963C185.32 139.963 183.446 139.963 183.277 141.365C183.109 142.767 185.901 144.597 185.901 144.597" fill="white"/>
            <path d="M185.32 139.963C185.32 139.963 183.446 139.963 183.277 141.365C183.109 142.767 185.901 144.597 185.901 144.597" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M237.96 190.879C233.737 184.28 227.778 180.152 222.45 172.83C218.039 166.757 213.97 160.431 210.263 153.884L204.609 143.003C204.609 143.003 197.688 134.452 194.89 133.403C192.091 132.353 176.774 131.854 176.774 131.854C176.774 131.854 176.15 133.556 178.517 134.414C180.885 135.272 188.137 135.969 188.137 135.969C188.137 135.969 186.001 136.91 185.507 137.685C185.014 138.459 185.507 144.821 186.494 146.114C187.481 147.407 191.273 148.585 191.273 148.585C191.273 148.585 191.773 153.065 195.477 155.286C199.181 157.507 200.993 157.532 200.993 157.532C200.993 157.532 204.478 163.6 205.934 167.933C209.057 177.169 216.934 198.169 222.625 205.741C229.358 214.701 238.085 215.047 241.24 209.344" fill="white"/>
            <path d="M237.96 190.879C233.737 184.28 227.778 180.152 222.45 172.83C218.039 166.757 213.97 160.431 210.263 153.884L204.609 143.003C204.609 143.003 197.688 134.452 194.89 133.403C192.091 132.353 176.774 131.854 176.774 131.854C176.774 131.854 176.15 133.556 178.517 134.414C180.885 135.272 188.137 135.969 188.137 135.969C188.137 135.969 186.001 136.91 185.507 137.685C185.014 138.459 185.507 144.821 186.494 146.114C187.481 147.407 191.273 148.585 191.273 148.585C191.273 148.585 191.773 153.065 195.477 155.286C199.181 157.507 200.993 157.532 200.993 157.532C200.993 157.532 204.478 163.6 205.934 167.933C209.057 177.169 216.934 198.169 222.625 205.741C229.358 214.701 238.085 215.047 241.24 209.344" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M194.609 139.349C194.609 139.349 189.237 139.989 188.556 140.488C187.875 140.987 188.2 144.923 188.556 146.3C188.616 146.658 188.753 146.999 188.956 147.297C189.159 147.596 189.423 147.845 189.73 148.028" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M192.391 136.097C192.391 136.097 187.107 137.448 186.769 138.145C186.278 140.05 186.195 142.042 186.526 143.983C186.957 146.12 187.082 146.792 187.887 147.183" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M188.131 135.976C188.883 135.654 189.655 135.384 190.442 135.169" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M191.354 143.714C191.806 144.243 192.361 144.667 192.985 144.96C193.608 145.252 194.285 145.405 194.971 145.41C194.971 145.41 193.815 149.967 195.877 152.924" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M192.391 144.597C192.391 144.597 191.198 145.589 191.354 146.12" stroke="#263238" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M241.233 171.652C241.233 171.652 251.853 166.89 253.389 166.57C254.926 166.25 266.438 166.832 266.438 166.832C265.014 168.179 263.372 169.262 261.585 170.033C261.585 170.033 261.329 167.863 258.462 167.613C255.594 167.364 241.233 171.652 241.233 171.652Z" fill="#263238"/>
        </svg>
    )
}